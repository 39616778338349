import React, { FC } from 'react';
import { Typography } from '@mui/material';

interface Props {
	error?: string;
}

export const ErrorMessage: FC<Props> = ({ error }) => {
	return <Typography sx={messageSX}>{error}</Typography>;
};

const messageSX = {
	textAlign: 'center',
	fontSize: 'small',
	color: 'red'
};
