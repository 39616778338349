import axios from 'axios';
import { AuthorizationInterceptor } from './request.interceptor';
import {
	attachRetryInterceptor,
	AuthorizationRefreshInterceptor,
	GatewayErrorInterceptor,
	RefreshedTokenInterceptor
} from './response.interceptor';
import { ContentType, HttpHeader } from '../constants/http';

const API_URL = process.env.REACT_APP_API_URL as string;

const headers = {
	[HttpHeader['Content-Type']]: ContentType['application/x-www-form-urlencoded'],
	[HttpHeader['Check-Origin']]: 'popup'
};

/**
 * A base service responsible for sending HTTP requests without any specific authorization attached.
 */
export const BaseService = axios.create({
	baseURL: API_URL,
	headers
});
attachRetryInterceptor(BaseService);
BaseService.interceptors.response.use(null, GatewayErrorInterceptor.onRejected);

/**
 * An API service designed for sending authorized HTTP requests using short-term tokens. This service
 * supports automatic refreshment of short-term tokens if they are expired.
 */
export const ApiService = axios.create({
	baseURL: API_URL,
	headers
});

ApiService.interceptors.request.use(AuthorizationInterceptor.onFulfilled);
ApiService.interceptors.response.use(RefreshedTokenInterceptor.onFulfilled);
ApiService.interceptors.response.use(null, AuthorizationRefreshInterceptor.onRejected);
attachRetryInterceptor(ApiService);
ApiService.interceptors.response.use(null, GatewayErrorInterceptor.onRejected);
