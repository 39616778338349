import React from 'react';
import { useObserveAnalyticsResults } from './useObserveAnalyticsResults';
import { AnalyticsLoading } from './AnalyticsLoading';
import { Box } from '@mui/material';

const WaitingAnalyticsResults = () => {
	useObserveAnalyticsResults();

	return (
		<Box id="waiting-check-results-step" sx={{ pt: 5, pb: 5 }}>
			<AnalyticsLoading />
		</Box>
	);
};

export default WaitingAnalyticsResults;
