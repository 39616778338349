import { CheckAccordionRow } from './CheckAccordionRow';
import React from 'react';
import { ICheckItem } from '../../types/Reports';
import { useTranslation } from 'react-i18next';
import { FINAL_PAGE_PREFIX, RESULTS_PAGE_PREFIX } from '../../utils/translationPagePrefix';

interface ScoreOnlyComponentProps {
	title: string;
	onInfoClick(): void;
}

export const ScoreOnlyComponent = React.memo(({ title, onInfoClick }: ScoreOnlyComponentProps) => {
	return (
		<CheckAccordionRow
			openable={false}
			title={title}
			displayComment={false}
			displayDetails={false}
			displayInfo={true}
			onInfoClick={onInfoClick}
			style={{ fontWeight: 'normal', borderBottom: 'none' }}
			indicatorColor={'black'}
		/>
	);
});

interface ScoreOnlyWrapper {
	score: ICheckItem;
	onInfoClick(): void;
}

export const ScoreOnlyWrapper = React.memo(({ score, onInfoClick }: ScoreOnlyWrapper) => {
	const { t } = useTranslation();

	const scoreTitle = getScoreTitle(score, t);

	return <ScoreOnlyComponent title={scoreTitle} onInfoClick={onInfoClick} />;
});

const LIGHTS_PREFIX = `${RESULTS_PAGE_PREFIX}.lights`;
const translationKeyScore = `${LIGHTS_PREFIX}.score`;
const translationErrorKey = `${FINAL_PAGE_PREFIX}.not available`;

export function getScoreTitle(score: ICheckItem, t: Function) {
	const scoreTranslation = t(translationKeyScore);
	const scoreValue = getScoreValue(score, t);

	return `${scoreTranslation}: ${scoreValue}`;
}

function getScoreValue(score: ICheckItem, t: Function) {
	let value: string;

	if (score.error) value = t(translationErrorKey);
	else value = (score.result as any).score.toString();

	return value;
}
