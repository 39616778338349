import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

type AppStatus = 'NOT_INITIALIZED' | 'INITIALIZING' | 'INITIALIZED' | 'FAILED';

type AppState = {
	status: AppStatus;
};

const initialState: AppState = {
	status: 'NOT_INITIALIZED'
};

export const APP_INIT_STARTED = '@@APP_INIT_STARTED';
export const APP_INIT_SUCCEEDED = '@@APP_INIT_SUCCEEDED';
export const APP_INIT_FAILED = '@@APP_INIT_FAILED';

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		restart: (state) => {
			state.status = 'NOT_INITIALIZED';
		}
	},
	extraReducers: {
		[APP_INIT_STARTED]: (state) => {
			state.status = 'INITIALIZING';
		},
		[APP_INIT_SUCCEEDED]: (state) => {
			state.status = 'INITIALIZED';
		},
		[APP_INIT_FAILED]: (state) => {
			state.status = 'FAILED';
		}
	}
});

export const selectAppStatus = (state: RootState) => state.app.status;

export default appSlice;
