import React from 'react';
import { useTheme } from '@mui/material';

export interface SizeSVG {
	x1: string;
	y1: string;
	x2: string;
	y2: string;
}

export type WithChildrenAssets<T = {}> = T & {
	children: React.ReactNode;
	width?: string;
	height?: string;
	viewBox: string;
	size?: SizeSVG;
};

export type AssetsProps = WithChildrenAssets;

export const SVGIcon = ({
	children,
	width = '339.977',
	height = '375.654',
	viewBox = '0 0 339.977 375.654',
	size = {
		x1: '1.62',
		y1: '-0.011',
		x2: '-0.528',
		y2: '0.864'
	}
}: AssetsProps) => {
	const theme = useTheme();
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			// Add default size
			width={width}
			height={height}
			viewBox={viewBox}
		>
			<defs>
				{/* Use gradient color as an ID */}
				<linearGradient
					id="linear-gradient"
					x1={size.x1}
					y1={size.y1}
					x2={size.x2}
					y2={size.y2}
					gradientUnits="objectBoundingBox"
				>
					<stop offset="0" stopColor={`${theme.palette.primary.lighter}`} />
					<stop offset="1" stopColor={`${theme.palette.primary.main}`} />
				</linearGradient>
			</defs>
			{children}
		</svg>
	);
};
