import useCustomization from '../../hooks/useCustomization';
import React from 'react';
import { EmailReplacementMarkdown, ReturnToEmailInputMarkdown } from '../custom/BlankCheckMarkdown';
import { CustomizationKey } from '../../app/slices/customizations.slice';

interface BlankCheckEmailTitleCustomizationProps {
	email: string;
}

interface BlankCheckEmailSentSubsectionCustomizationProps {
	onClick: () => void;
}

const BLANK_CHECK_EMAIL_SENT_PAGE_SUBSECTION_KEY: CustomizationKey = 'BLANK_CHECK_EMAIL_SENT_PAGE_SUBSECTION';

const BLANK_CHECK_EMAIL_SENT_PAGE_SUBTITLE_KEY: CustomizationKey = 'BLANK_CHECK_EMAIL_SENT_PAGE_SUBTITLE';

export const BlankCheckEmailSentTitleCustomization = ({ email }: BlankCheckEmailTitleCustomizationProps) => {
	const customization = useCustomization(BLANK_CHECK_EMAIL_SENT_PAGE_SUBTITLE_KEY);
	if (!customization) return null;
	return <EmailReplacementMarkdown content={customization} email={email} />;
};

export const BlankCheckEmailSentSubsectionCustomization = ({
	onClick
}: BlankCheckEmailSentSubsectionCustomizationProps) => {
	const customization = useCustomization(BLANK_CHECK_EMAIL_SENT_PAGE_SUBSECTION_KEY);
	if (!customization) return null;
	return <ReturnToEmailInputMarkdown content={customization} onClick={onClick} />;
};
