import React from 'react';
import { Paper, useTheme } from '@mui/material';

export type WithChildren<T = {}> = T & {
	children: React.ReactNode;
};

type SpinnerProps = Partial<WithChildren>;

export const Spinner = ({ children }: SpinnerProps) => {
	const theme = useTheme();
	const paperSX = {
		backgroundColor: 'transparent',
		'&:before': {
			background: `${theme.palette.primary.light}`
		},
		'&:after': {
			background: `${theme.palette.primary.light}`
		}
	};
	return (
		<div className="spinner" style={{ borderRight: `10px solid ${theme.palette.primary.light}` }}>
			<Paper elevation={0} className="spinner-inner" sx={paperSX}>
				<div className="spinner-content">{children}</div>
			</Paper>
		</div>
	);
};

export default Spinner;
