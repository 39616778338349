import { CODE_COUNTRY, Country, COUNTRY_PREFIXES, CountryCode } from '../constants/country-phone-number';

export interface DecomposedPhoneNumber {
	country: Country;
	prefix: string;
	rest: string;
}

export const decomposePhoneNumber = (phone: string): Partial<DecomposedPhoneNumber> => {
	const countryCode = phone.substring(0, 3);

	if (!isSupportedCountryCode(countryCode)) {
		return {};
	}

	const country = CODE_COUNTRY[countryCode as CountryCode];
	const prefix = getSupportedCountryPhonePrefix(phone, country);

	if (!prefix) {
		return { country };
	}

	const rest = phone.substring(3 + prefix.length); // country code (3 digits) + prefix length
	return {
		country,
		prefix,
		rest
	};
};

const isSupportedCountryCode = (code: string): code is CountryCode => {
	return CODE_COUNTRY.hasOwnProperty(code);
};

const getSupportedCountryPhonePrefix = (phone: string, country: Country): string | undefined => {
	const remaining = phone.substring(3); // without country code (ex: +43)
	const prefixes = COUNTRY_PREFIXES[country]; // supported prefixes

	const matchingPrefix = prefixes
		.filter((prefix) => remaining.startsWith(prefix))
		.reduce((longest, current) => (current.length > longest.length ? current : longest), '');

	return matchingPrefix || undefined;
};
