import React from 'react';
import Markdown from 'react-markdown';

interface ModalHyperlinkMarkdownProps {
	content: string;
	handleMDLinkClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const hyperlinkStyle = {
	fontWeight: 'bold',
	cursor: 'pointer',
	textDecoration: 'none',
	color: 'inherit'
};

const ModalHyperlinkMarkdown: React.FC<ModalHyperlinkMarkdownProps> = ({ content, handleMDLinkClick }) => {
	const components = {
		a: ({ children, ...props }: React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
			const href = props.href;

			return (
				<a {...props} onClick={handleMDLinkClick} data-href={href} style={hyperlinkStyle}>
					{children}
				</a>
			);
		}
	};

	return (
		<Markdown className="custom-markdown" components={components}>
			{content}
		</Markdown>
	);
};

export default ModalHyperlinkMarkdown;
