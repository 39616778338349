import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

type FlagsState = {
	flags: Record<string, boolean>;
};

const initialState: FlagsState = {
	flags: {}
};

export enum FlagsEnum {
	SHOW_RESULT = 'SHOW_RESULTS',
	ASSESSMENT_USER_INPUT = 'ASSESSMENT_USER_INPUT',
	EDIT_CHECK_DATA = 'EDIT_CHECK_DATA'
}

const flagsSlice = createSlice({
	name: 'flagsPackage',
	initialState,
	reducers: {
		setFlags(state, action: PayloadAction<Array<string>>) {
			const flags = action.payload;
			for (const flag of flags) {
				state.flags[flag] = true;
			}
		}
	}
});

export const selectFlag = (state: RootState, flag: FlagsEnum): boolean => {
	const isPresent = state.flagsSlice.flags[flag];
	// if isPresent is undefined, there is no specified flag, so return false (edge case)
	if (isPresent === undefined) return false;
	return isPresent;
};

export const selectShowResults = (state: RootState) => selectFlag(state, FlagsEnum.SHOW_RESULT);

export const flagsActions = flagsSlice.actions;

export const { setFlags } = flagsActions;

export default flagsSlice;
