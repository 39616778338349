import { EnumErrorType } from '../../types/ErrorsObject';
import { CloudLightning } from '../svg/CloudLightning';
import React from 'react';
import { XCircle } from '../svg/XCircle';
import { ActivityIcon } from '../svg/ActivityIcon';

interface ErrorImageProps {
	type: EnumErrorType;
}

export const ErrorImage = ({ type }: ErrorImageProps) => {
	const Image = typeImageRecord[type];
	return <div style={{ padding: '5%' }}>{Image}</div>;
};

export default ErrorImage;

const typeImageRecord: Record<EnumErrorType, JSX.Element> = {
	[EnumErrorType.APP_ERROR]: <CloudLightning />,
	[EnumErrorType.REACT_ERROR]: <CloudLightning />,
	[EnumErrorType.KLARNA_ERROR]: <CloudLightning />,
	[EnumErrorType.CHECK_EXPIRED]: <XCircle />,
	[EnumErrorType.VALIDATION_TOKEN_NO_LONGER_VALID_ERROR]: <CloudLightning />,
	[EnumErrorType.LOAD_ISSUES_ERROR]: <ActivityIcon />
};
