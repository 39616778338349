import { SVGIcon } from './SVGIcon';
import React from 'react';

export function ActivityIcon() {
	return (
		<SVGIcon
			width="296.331"
			height="296.153"
			viewBox="0 0 296.331 296.153"
			size={{ x1: '1', y1: '0.47', x2: '0', y2: '0.475' }}
		>
			<defs>
				<linearGradient
					id="linear-gradient"
					x1="1"
					y1="0.5"
					x2="0.047"
					y2="0.5"
					gradientUnits="objectBoundingBox"
				>
					<stop offset="0" stopColor="#c2e1f8" />
					<stop offset="1" stopColor="#0384e1" />
				</linearGradient>
			</defs>
			<g id="activity" transform="translate(1 1)">
				<path
					id="Pfad_99"
					data-name="Pfad 99"
					d="M93.887,8.2A13.457,13.457,0,0,1,118.77,6.6l.632,1.6,68,203.932,27.614-82.816a13.457,13.457,0,0,1,11.21-9.111l1.548-.094H281.6a13.457,13.457,0,0,1,1.574,26.82l-1.574.094h-44.14l-37.29,111.911a13.457,13.457,0,0,1-24.9,1.6l-.632-1.6L106.658,54.993l-27.6,82.83a13.457,13.457,0,0,1-11.21,9.111l-1.561.094H12.457a13.456,13.456,0,0,1-1.576-26.82l1.576-.094H56.584Z"
					transform="translate(0 0)"
					fill="url(#linear-gradient)"
				/>
			</g>
		</SVGIcon>
	);
}
