import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { CloudLightning } from '../../svg/CloudLightning';
import { useAppDispatch } from '../../../app/hooks';
import { changeStep } from '../../../app/slices/step.slice';
import { useAppTranslation } from '../../../i18n';
import { Trans } from 'react-i18next';

export const PhoneNumberSmsDeliveryErrorStep: FC = () => {
	const dispatch = useAppDispatch();
	const { t } = useAppTranslation();

	const handleClick = () => {
		dispatch(changeStep('PHONE_NUMBER_INPUT_STEP'));
	};

	return (
		<Box id="phone-number-sms-delivery-step">
			<Box sx={{ ...textContainerSX }}>
				<Typography sx={{ ...textSX, fontSize: 'large' }}>
					{t('steps.phone-number-sms-delivery-error-step.header')}
				</Typography>
			</Box>
			<Box id="icon-container" sx={iconBoxSX}>
				<CloudLightning />
			</Box>
			<Box sx={textContainerSX}>
				<Typography sx={textSX}>{t('steps.phone-number-sms-delivery-error-step.subheader')}</Typography>
				<Typography sx={textSX}>
					<Trans
						t={t}
						i18nKey="steps.phone-number-sms-delivery-error-step.content"
						components={[<u style={{ cursor: 'pointer' }} onClick={handleClick} />]}
					/>
				</Typography>
			</Box>
		</Box>
	);
};

const iconBoxSX = {
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	'& svg': {
		width: '60%'
	}
};

const textContainerSX = {
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	gap: '20px',
	flexDirection: 'column'
};

const textSX = {
	fontSize: 'medium',
	textAlign: 'center'
};
