import { CustomizationKey, Language, selectCustomization } from '../app/slices/customizations.slice';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../app/hooks';

const useCustomization = (key: CustomizationKey) => {
	const {
		i18n: { language }
	} = useTranslation();

	return useAppSelector(selectCustomization(key, language as Language));
};

export default useCustomization;
