import React, { FC, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import TextCustomization from '../../customizations/TextCustomization';
import { useAppSelector } from '../../../app/hooks';
import { selectPersona } from '../../../app/slices/persona.slice';
import { selectBankAccount } from '../../../app/slices/bank.slice';
import { CustomizationKey } from '../../../app/slices/customizations.slice';
import { ReactComponent as LogoutIcon } from '../../../assets/images/icons/log-out.svg';
import { ReactComponent as UserIcon } from '../../../assets/images/icons/user.svg';
import { ReactComponent as FileTextIcon } from '../../../assets/images/icons/file-text.svg';
import DateConverter, { DateFormat } from '../../../utils/DateConverter';
import { Country } from '../../../constants/country-phone-number';
import { RESULTS_PAGE_PREFIX } from '../../../utils/translationPagePrefix';
import { useAppTranslation } from '../../../i18n';

const RESULTS_PAGE_TITLE_KEY: CustomizationKey = 'RESULTS_PAGE_TITLE';

export const Header: FC = () => {
	const theme = useTheme();
	const { t } = useAppTranslation();

	const persona = useAppSelector(selectPersona);
	const bankAccount = useAppSelector(selectBankAccount);

	const [isOpenBankAccount, setIsOpenBankAccount] = useState(false);

	const toggleOpen = () => {
		const toggle = !isOpenBankAccount;
		setIsOpenBankAccount(toggle);
	};

	return (
		<Box className="content-header">
			<div className="container-personal-info">
				<div className="user-icon-wrapper">
					<LogoutIcon className="user-icon" fill={theme.palette.primary.main} />
				</div>
				<div className="single-content-info">
					<div className="user-info-text" style={{ padding: 0 }}>
						<TextCustomization id={RESULTS_PAGE_TITLE_KEY} />
					</div>
				</div>
			</div>

			<div className="container-personal-info">
				<div className="user-icon-wrapper">
					<UserIcon className="user-icon" fill={theme.palette.primary.main} />
				</div>
				<div className="user-info-container">
					<div className="user-info-text">
						{persona.firstName} {persona.lastName}
					</div>
					{persona && persona.dateOfBirth && persona.residence.country && (
						<div className="user-info-text-complete">
							{DateConverter.convert(persona.dateOfBirth, DateFormat.VIEW_FORMAT)},{' '}
							{persona &&
								persona.residence &&
								persona.residence.country &&
								capitalizeFirstLetter(t(`countries.${persona.residence.country as Country}`))}
						</div>
					)}
				</div>
			</div>

			<div className="container-personal-info">
				<div className="user-icon-wrapper">
					<FileTextIcon className="user-icon" fill={theme.palette.primary.main} />
				</div>
				<div className="single-content-info">
					<div className="user-info-text" style={{ padding: 0 }}>
						{t(`${RESULTS_PAGE_PREFIX}.header.bank account details`)}
					</div>
					<div className="user-info-details">
						<button onClick={toggleOpen}>Details</button>
					</div>
				</div>
			</div>

			{isOpenBankAccount && (
				<div className="container-personal-info translated-personal-info">
					<div className="user-icon-wrapper">
						<FileTextIcon
							className="user-icon"
							fill={theme.palette.primary.main}
							style={{ opacity: '0' }}
						/>
					</div>
					<div className="bank-info-container">
						<div>{t(`${RESULTS_PAGE_PREFIX}.header.account owner`)}:</div>
						<div style={{ marginTop: '2px' }}>IBAN:</div>
					</div>
					<div className="bank-info-content">
						<div>{bankAccount.accountHolder || null}</div>
						<div>{bankAccount.iban || null}</div>
					</div>
				</div>
			)}
		</Box>
	);
};

const capitalizeFirstLetter = (str: string) => {
	if (!str) return '';
	return str.charAt(0).toUpperCase() + str.slice(1);
};
