import React from 'react';
import { EnumErrorType } from '../../types/ErrorsObject';
import klarnaLogo from '../../assets/images/klarna/klarna_logo1.png';

interface ErrorPreContentProps {
	type: EnumErrorType;
}

const ErrorPreContent = ({ type }: ErrorPreContentProps) => {
	const content = preContentRecord[type];
	return <>{content}</>;
};

export default ErrorPreContent;

const preContentRecord: Record<EnumErrorType, JSX.Element> = {
	[EnumErrorType.REACT_ERROR]: <></>,
	[EnumErrorType.APP_ERROR]: <></>,
	[EnumErrorType.CHECK_EXPIRED]: <></>,
	[EnumErrorType.VALIDATION_TOKEN_NO_LONGER_VALID_ERROR]: <></>,
	[EnumErrorType.LOAD_ISSUES_ERROR]: <></>,
	[EnumErrorType.KLARNA_ERROR]: (
		<>
			<div className="error-step-message-klarna">
				<img src={klarnaLogo} alt="Klarna Error" className="error-klarna-icon" />
			</div>
		</>
	)
};
