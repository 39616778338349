import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectLegacyToken, selectLongTermJWT, selectShortTermJWT } from '../app/slices/session.slice';
import { selectBankRedirectMethod } from '../app/slices/configuration.slice';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { Language } from '../app/slices/customizations.slice';
import { BankAppConfiguration } from '@fncrdbl/bank-sdk/dist/esm/types/sdk';
import { bankAppCompleted } from '../app/thunks/bank-process-completed';
import { AxiosInstance } from 'axios';
import { useCallback } from 'react';
import { attachRetryInterceptor } from '../api/response.interceptor';

const URL = process.env['REACT_APP_API_URL'] as string;

export const useBankAppConfiguration = (): BankAppConfiguration => {
	const shortTermToken = useAppSelector(selectShortTermJWT);
	const longTermToken = useAppSelector(selectLongTermJWT);
	const inviteToken = useAppSelector(selectLegacyToken);
	const bankRedirectMethod = useAppSelector(selectBankRedirectMethod);
	const language = useLanguage();

	const theme = useTheme();
	const dispatch = useAppDispatch();

	const setupApiClient = useCallback((axiosInstance: AxiosInstance) => {
		const id = attachRetryInterceptor(axiosInstance);
		return () => {
			axiosInstance.interceptors.response.eject(id);
		};
	}, []);

	const callbacks = {
		onFinished: () => {
			dispatch(bankAppCompleted());
		},
		onClose: () => {
			dispatch(bankAppCompleted());
		}
	};

	return {
		shortTermToken,
		longTermToken,
		inviteToken,
		url: URL,
		bankRedirectMethod,
		setupApiClient,
		callbacks,
		language: language as Language,
		primaryColor: theme.palette.primary.light,
		secondaryColor: theme.palette.primary.lighter,
		autoClose: true
	};
};

const useLanguage = () => {
	const {
		i18n: { language }
	} = useTranslation();

	return language as Language;
};
