import {
	ALIMONY,
	ENTERTAINMENT_COMMUNICATION,
	INCOME,
	INFLOW,
	KONTOCHECK,
	NAMECHECK,
	OUTFLOW,
	WELFARE
} from '../../check/codes';
import { parseResultForValue } from '../../utils/report-utils';
import { ICheckItem } from '../../types/Reports';
import { reportsActions } from '../slices/report.slice';

const groupNamesWhichDontShow = ['INCOME', 'WELFARE', 'ALIMONY', 'ENTERTAINMENT_COMMUNICATION'];

// TODO: Needs to be refactored ASAP
export const parseKontoData = (fincredibleReport: any) => {
	return (dispatch: any) => {
		const kontoCheckArray: any = [];
		const householdArray: any = [];

		if (fincredibleReport.report?.length > 0) {
			fincredibleReport.report.forEach((elm: ICheckItem) => {
				if (elm.hasOwnProperty('code')) {
					if (KONTOCHECK[elm.code as keyof typeof KONTOCHECK]) {
						kontoCheckArray.push(elm);
					}

					if (INFLOW[elm.code as keyof typeof INFLOW]) {
						if (!groupNamesWhichDontShow.includes(elm.code)) {
							addItemValueToHouseholdArray(elm, householdArray, false);
						}
					}

					if (OUTFLOW[elm.code as keyof typeof OUTFLOW]) {
						if (!groupNamesWhichDontShow.includes(elm.code)) {
							addItemValueToHouseholdArray(elm, householdArray, false);
						}
					}

					if (INCOME[elm.code as keyof typeof INCOME]) {
						addItemValueToHouseholdArray(elm, householdArray, false);
					}

					if (ALIMONY[elm.code as keyof typeof ALIMONY]) {
						addItemValueToHouseholdArray(elm, householdArray, false);
					}

					if (WELFARE[elm.code as keyof typeof WELFARE]) {
						addItemValueToHouseholdArray(elm, householdArray, false);
					}

					if (ENTERTAINMENT_COMMUNICATION[elm.code as keyof typeof ENTERTAINMENT_COMMUNICATION]) {
						householdArray.push(elm);
					}

					if (elm.code === NAMECHECK) {
						dispatch(reportsActions.setNamecheck(elm));
					}

					if (elm.code === 'SCORE') {
						dispatch(reportsActions.setScore(elm));
					}

					if (elm.code === 'LIGHTS') {
						dispatch(reportsActions.setLights(elm));
					}
				}
				if (elm.hasOwnProperty('components')) {
					// This is for JSON tree which will change
					elm.components.forEach((comp: ICheckItem) => {
						if (comp.hasOwnProperty('components')) {
							comp.components.forEach((res: ICheckItem) => {
								addItemValueToHouseholdArray(res, householdArray, true, comp.code);
							});
						} else {
							addItemValueToHouseholdArray(comp, householdArray, false);
						}
					});
				}
			});
		}
		dispatch(reportsActions.setKontoCheck(kontoCheckArray));
		dispatch(reportsActions.setHousehold(householdArray));
	};
};

export const addItemValueToHouseholdArray = (
	elm: ICheckItem,
	householdArray: any,
	ifParent: boolean,
	parentCode?: string
) => {
	let parsedValue = parseResultForValue(elm, false);
	if (Object.keys(parsedValue).length === 0) {
		parsedValue = ifParent ? { ...elm, parent: parentCode } : { ...elm };
	}
	householdArray.push(parsedValue);
};
