import useCustomization from '../../hooks/useCustomization';
import React from 'react';
import { AccordionCategory } from '../tabs/AccordionCategory';
import { CustomizationKey } from '../../app/slices/customizations.slice';

const FAQS_KEY: CustomizationKey = 'FAQs';

const FAQsCustomization = () => {
	const customization = useCustomization(FAQS_KEY);
	if (!customization) return null;
	const customizationsSections = extractSections(customization);

	return (
		<React.Fragment>
			{customizationsSections.map((faqSection, index) => (
				<AccordionCategory markdown={faqSection} key={index} />
			))}
		</React.Fragment>
	);
};

export default FAQsCustomization;

const extractSections = (markdown: string): string[] => {
	const sections: string[] = [];
	const lines = markdown.split('\n');
	let currentSection = '';

	for (const line of lines) {
		if (line.startsWith('#')) {
			if (currentSection.trim() !== '') {
				sections.push(currentSection.trim());
				currentSection = '';
			}
		}
		currentSection += line + '\n';
	}
	if (currentSection.trim() !== '') {
		sections.push(currentSection.trim());
	}
	return sections;
};
