import React, { useState } from 'react';
import Modal from '../custom/Modal';
import closeIcon from '../../assets/images/icons/close.svg';
import { useDispatch } from 'react-redux';
import CheckAccordionRow from '../steps/CheckAccordionRow';
import StepButton from '../steps/StepButton';
import { useAppSelector } from '../../app/hooks';
import { TextField, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getCriteriaTitleWithCodeOnly } from '../hooks/useCriteriaTitle';
import { ModalEnum, uiActions } from '../../app/slices/ui.slice';
import { reportsActions } from '../../app/slices/report.slice';
import { personaActions } from '../../app/slices/persona.slice';
import { RESULTS_PAGE_PREFIX } from '../../utils/translationPagePrefix';
import TextCustomization from '../customizations/TextCustomization';
import { CustomizationKey } from '../../app/slices/customizations.slice';

const TRANSLATION_PREFIX = `${RESULTS_PAGE_PREFIX}.modals`;

const commentTranslationKey = `${TRANSLATION_PREFIX}.comment`;
const saveTranslationKey = `${TRANSLATION_PREFIX}.save`;

const ENTER_COMMENT_TEXT_KEY: CustomizationKey = 'ENTER_COMMENT_TEXT';

const ReportCommentModal = () => {
	const reportCommentCheckIdShown = useAppSelector((state) => state.ui.reportCommentCheckIdShown);
	const initValueIDCheck = useAppSelector((state) => state.ui.reportCommentTextIDCheck);
	const initValueCheck = useAppSelector((state) => state.ui.reportCommentText);
	const theme = useTheme();

	const { t } = useTranslation();

	const [comment, setComment] = useState<string>(reportCommentCheckIdShown ? initValueIDCheck : initValueCheck);

	const dispatch = useDispatch();

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setComment(event.target.value);
	};

	const modalTitle = useAppSelector((state) => state.ui.modalTitle);
	const translatedTitle = getCriteriaTitleWithCodeOnly(modalTitle, t);
	const reportCommentCode = useAppSelector((state) => state.ui.reportCommentCode);

	const handleOnCloseModal = () => {
		dispatch(uiActions.setReportCommentCheckIDModalHide());
		dispatch(uiActions.toggleModal(ModalEnum.REPORT_COMMENT_MODAL));
	};

	const saveToStoreAssessment = () => {
		if (!reportCommentCheckIdShown) {
			dispatch(
				reportsActions.setCommentForKontoCheckReport({
					code: reportCommentCode,
					comment: comment
				})
			);
			dispatch(
				personaActions.setUserAssessmentArray({
					code: reportCommentCode,
					comment: comment
				})
			);
		} else {
			dispatch(uiActions.setReportCommentTextIDCheck(comment));
			dispatch(uiActions.setReportCommentCheckIDModalHide());
		}
		dispatch(uiActions.toggleModal(ModalEnum.REPORT_COMMENT_MODAL));
	};

	const handleSaveReportComment = () => {
		saveToStoreAssessment();
	};

	const styleTextArea = {
		border: '1px solid #969696',
		'& fieldset': {
			border: 'none'
		},
		'&:focus-within': {
			border: `1px solid ${theme.palette.primary.light} !important`
		}
	};

	return (
		<Modal onClose={handleOnCloseModal} style={{ fontFamily: 'Roboto' }}>
			<div className="modal-actions">
				<div className="modal-actions-close" onClick={handleOnCloseModal}>
					<img className="modal-close" src={closeIcon} alt="Close" />
				</div>
			</div>
			<CheckAccordionRow
				openable={false}
				title={translatedTitle}
				style={{ borderBottom: '0', marginBottom: '5px', zIndex: '30', fontFamily: 'Roboto' }}
				displayComment={false}
				displayInfo={false}
				displayDetails={false}
				soloTitle={true}
			/>
			<div className="modal-title" style={{ display: 'flex' }}>
				<TextCustomization id={ENTER_COMMENT_TEXT_KEY} />
				<span style={{ marginLeft: '5px', alignSelf: 'center' }}>:</span>
			</div>
			<TextField
				sx={styleTextArea}
				className="modal-textarea"
				rows={4}
				multiline
				placeholder={`${t(commentTranslationKey)} (optional)`}
				value={comment}
				onChange={handleChange}
			/>
			<StepButton
				style={{ width: '50%' }}
				disabled={false}
				title={t(saveTranslationKey)}
				onClick={handleSaveReportComment}
			/>
		</Modal>
	);
};

export default ReportCommentModal;
