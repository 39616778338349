import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { XS2App } from '../../declarations/XS2A';
import { RootState } from '../store';

export interface Configuration {
	autoClose: boolean;
	bankRedirectMethod: XS2App.PSD2RedirectMethod;
}

const AUTO_CLOSE_DEFAULT = false;
const BANK_REDIRECT_DEFAULT = 'sameWindow';

const initialState: Configuration = {
	autoClose: AUTO_CLOSE_DEFAULT,
	bankRedirectMethod: BANK_REDIRECT_DEFAULT
};

export const configurationSlice = createSlice({
	name: 'configuration',
	initialState,
	reducers: {
		saveConfigurationViaToken(state, action: PayloadAction<string>) {
			const jwt = action.payload;
			const decodedJWT = jwt_decode(jwt) as Partial<Configuration>;
			saveAutoCloseOptionViaToken(decodedJWT, state);
			saveBankRedirectMethod(decodedJWT, state);
		}
	}
});

export const configurationActions = configurationSlice.actions;

export const selectAutoCloseOption = (state: RootState) => state.configuration.autoClose;

export const selectBankRedirectMethod = (state: RootState) => state.configuration.bankRedirectMethod;

function saveAutoCloseOptionViaToken(decodedJWT: Partial<Configuration>, state: Configuration) {
	const autoClose = decodedJWT.autoClose;
	if (autoClose) state.autoClose = autoClose;
}

function saveBankRedirectMethod(decodedJWT: Partial<Configuration>, state: Configuration) {
	const bankRedirectMethod = decodedJWT.bankRedirectMethod;
	if (bankRedirectMethod) state.bankRedirectMethod = bankRedirectMethod;
}
