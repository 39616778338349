import React from 'react';
import useCustomization from '../../hooks/useCustomization';
import { CustomizationKey } from '../../app/slices/customizations.slice';

interface ImageCustomizationProps {
	customizationKey: CustomizationKey;
	imageStyle: React.CSSProperties;
}

const ImageCustomization = ({ customizationKey, imageStyle }: ImageCustomizationProps) => {
	const customization = useCustomization(customizationKey);
	if (!customization) return null;
	return <img style={imageStyle} src={customization} alt="" />;
};
export default ImageCustomization;
