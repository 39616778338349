import { PhoneNumberVerificationService } from '../../services/PhoneNumberVerificationService';
import { AppDispatch } from '../store';
import { changeStep } from '../slices/step.slice';

export const resendPhoneNumberVerificationCode = () => {
	return async (dispatch: AppDispatch) => {
		try {
			const service = new PhoneNumberVerificationService();
			await service.resendConfirmationCode();

			dispatch(changeStep('PHONE_NUMBER_SMS_DELIVERY_PENDING_STEP'));
		} catch (error) {
			throw error;
		}
	};
};
