import React from 'react';
import { Box, useTheme } from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import { selectCurrentStep, Step } from '../../app/slices/step.slice';

const HeaderProgress = () => {
	const theme = useTheme();

	const currentStep = useAppSelector(selectCurrentStep);
	const progress = getProgressPercentage(currentStep);

	return (
		<Box className="header-progress">
			<div className="header-progress-bar" style={{ backgroundColor: `${theme.palette.primary.lighter}` }}>
				<div
					style={{
						width: `${progress}%`,
						borderRadius: '5px 5px 5px 5px',
						backgroundColor: `${theme.palette.primary.light}`
					}}
					className="header-start-bar"
				/>
			</div>
		</Box>
	);
};

export default HeaderProgress;

const getProgressPercentage = (step: Step): number => {
	switch (step) {
		case 'PHONE_NUMBER_INPUT_STEP':
			return 33;
		case 'PHONE_NUMBER_SMS_DELIVERY_PENDING_STEP':
		case 'PHONE_NUMBER_VERIFICATION_CODE_INPUT_STEP':
			return 66;
		case 'PHONE_NUMBER_VERIFIED_STEP':
			return 100;
		case 'EMAIL_INPUT_STEP':
			return 50;
		case 'EMAIL_VERIFICATION_PENDING_STEP':
			return 100;
		case 'EMAIL_VERIFIED_STEP':
			return 100;
		case 'PERSONA_INFORMATION_INPUT_STEP':
			return 20;
		case 'PERSONA_CONSENT_INPUT_STEP':
			return 40;
		case 'BANK_STEP':
			return 60;
		case 'WAITING_CHECK_RESULTS_STEP':
		case 'CHECK_RESULTS_STEP':
		case 'CHECK_RESULTS_SUMMARY_STEP':
			return 80;
		case 'CHECK_FINISHED_STEP':
		case 'PHONE_NUMBER_SMS_DELIVERY_ERROR_STEP':
		case 'ERROR_STEP':
			return 100;
		case 'LOADING_STEP':
			return 0;
	}
};
