import { ApiService } from '../api/axios';
import { AxiosResponse } from 'axios';
import { axios_instance } from './axios';
import { selectShortTermJWT } from '../app/slices/session.slice';
import store from '../app/store';
import { CheckUpdateNotAcceptableError, InvalidEMailValueError } from './EmailService';
import { HttpHeader, HttpStatus } from '../constants/http';
import { Assessment } from '../types/Persona';
import { Check } from '../check/types';
import qs from 'qs';
import { CheckLanguage } from '../types/check';
import { Promise } from 'cypress/types/cy-bluebird';

interface ErrorResponse {
	error?: Array<{ msg: string }>;
}

interface ICheckService {
	changeLanguage(language: CheckLanguage): Promise<void>;
}

export class CheckService implements ICheckService {
	async fetchCheckReport(): Promise<Check> {
		const { data } = await ApiService.get('/check/report');
		return data;
	}

	async saveEmailAddress(email: string): Promise<void> {
		const formData = new URLSearchParams();
		formData.append('email', email);

		try {
			const data = formData.toString();
			const token = selectShortTermJWT(store.getState());
			const response = await axios_instance.put('/check/email', data, {
				headers: {
					[HttpHeader.Authorization]: token
				}
			});

			const responseData = response.data as ErrorResponse;

			if (this.isValidationErrorResponse(responseData)) {
				throw new InvalidEMailValueError();
			}
		} catch (error) {
			if (this.isNotAcceptableStatus(error)) throw new CheckUpdateNotAcceptableError();
			throw error;
		}
	}

	async savePersonaData(data: any): Promise<AxiosResponse> {
		return await ApiService.put('/check/report', data);
	}

	async updateCheck(data: any): Promise<AxiosResponse> {
		return await ApiService.put('/check/report', data);
	}

	async acceptCheck(assessments?: Array<Assessment>): Promise<void> {
		try {
			const data = qs.stringify({ status: 'accepted', userAssessment: JSON.stringify(assessments) });
			await ApiService.put('/check/report', data);
		} catch (error) {
			throw error;
		}
	}

	async discardCheck(): Promise<void> {
		try {
			const data = qs.stringify({ status: 'discarded' });
			await ApiService.put('/check/report', data);
		} catch (error) {
			throw error;
		}
	}

	private isNotAcceptableStatus(error: any): boolean {
		return error.response && error.response.status === HttpStatus.NOT_ACCEPTABLE;
	}

	private isValidationErrorResponse(data: ErrorResponse): boolean {
		const error = data.error;
		if (!error || error.length === 0) return false;

		const message = error[0].msg;
		return message === 'Invalid value';
	}

	async changeLanguage(language: CheckLanguage): Promise<void> {
		try {
			await ApiService.put('/check/language', { language });
		} catch (error) {
			throw error;
		}
	}
}

export const checkService = new CheckService();
