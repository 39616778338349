import React from 'react';
import Close from '../../assets/images/icons/close.svg';

interface ModalCloseProps {
	onClose(): void;
}

const ModalClose = ({ onClose }: ModalCloseProps) => {
	return (
		<div className="modal-actions">
			<div className="modal-actions-close" onClick={onClose}>
				<img className="modal-close" src={Close} alt="Close" />
			</div>
		</div>
	);
};

export default ModalClose;
