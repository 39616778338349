import React, { useCallback, useState } from 'react';
import TextField from '@mui/material/TextField';
import alert_triangle from '../../assets/images/icons/alert-triangle.svg';
import { InputAdornment, useTheme } from '@mui/material';
import {
	EmailValidator,
	IValidator,
	MinCharactersValidator,
	NumbersValidator,
	TextValidator
} from '../../utils/Validators';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

type StyledInputProps = Omit<Partial<InputProps>, 'onChange'> & {
	id?: string;
	placeholder?: string;
	className?: string;
	inputValue: string;
	disabled?: boolean;
	validators?: Array<IValidator<string>>;
	validation?: boolean;
	minCharacters?: number;
	maxCharacters?: number;
	validateEmail?: boolean;
	isTextOnly?: boolean;
	isNumbersOnly?: boolean;
	onChange?: (res: ValidationResult) => void;
	formatValue?: (input: string) => string;
};

type ValidResult = {
	isValid: true;
	value: string;
};
type InvalidResult = {
	isValid: false;
	value: string;
};
export type ValidationResult = ValidResult | InvalidResult;

const StyledInput = ({
	placeholder = '',
	id,
	className,
	inputValue,
	onChange,
	disabled = false,
	validators = [],
	validation = true,
	minCharacters,
	maxCharacters = 30,
	validateEmail,
	isTextOnly = true,
	isNumbersOnly = false,
	formatValue = (str) => str,
	...rest
}: StyledInputProps) => {
	const [text, setText] = useState<string>(inputValue);
	const [error, setError] = useState<string>('');
	const theme = useTheme();

	const getValidators = useCallback(() => {
		const arr = [...validators];
		// if input is email
		if (validateEmail) arr.push(EmailValidator);
		// if there is min char specified
		if (minCharacters) arr.push(MinCharactersValidator(minCharacters));
		// if input is text only
		if (isTextOnly) arr.push(TextValidator);
		return arr;
	}, [validators]);

	React.useEffect(() => {
		setText(inputValue);
	}, [inputValue]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const input = event.target.value as string;

		// ignore if input length is more that allowed
		if (input.length > maxCharacters) return;

		// if is numbers only, allow only number entries
		if (isNumbersOnly) {
			let lastCharacter = input.length > 0 ? input[0] : '';
			if (lastCharacter === '-' && input.length > 1) return;
			if (!NumbersValidator.validate(lastCharacter)) return;
		}

		if (validation) {
			// iterate through all validators
			const _validators = getValidators();
			for (var i = 0; i < _validators.length; i++) {
				const res = _validators[i].validate(input);
				if (res.passed) continue;
				setError(res.errorMessage);
				break;
			}
			// clear error if all validators passed
			if (i == _validators.length) {
				setError('');
				if (onChange) onChange({ isValid: true, value: input });
			} else {
				if (onChange) onChange({ isValid: false, value: input });
			}
			// set text
			setText(input);
		} else {
			setText(input);
			if (onChange) onChange({ isValid: true, value: input });
		}
	};

	const errorStyle = error
		? {
				border: '1px solid #FF0000 !important'
		  }
		: {};

	const style = {
		border: '1px solid #969696',
		borderRadius: '5px',
		background: 'background: #FFFFFF',
		width: '100%',
		'& .MuiInputLabel-root': {
			color: '#707070'
		},
		'& .Mui-focused': {
			color: '#707070'
		},
		'& .MuiInputBase-root': {
			'& .MuiInputBase-input:focus': {
				border: 'none'
			}
		},
		marginTop: '20px',
		'&:focus-within': {
			border: `1px solid ${theme.palette.primary.main} !important`
		}
	};

	return (
		<TextField
			id={id}
			variant="filled"
			label={placeholder}
			onChange={handleChange}
			inputMode="text"
			value={text || ''}
			disabled={disabled}
			sx={{
				...style,
				...errorStyle
			}}
			className={`controlled-input ${className || ''} txt-field`}
			inputProps={{
				...rest,
				underline: {
					'&&&:before': {
						borderBottom: 'none'
					},
					'&&:after': {
						borderBottom: 'none'
					}
				},
				style: { color: `${error ? '#FF0000' : 'black'}` }
			}}
			InputProps={{
				startAdornment: error && (
					<InputAdornment
						position="start"
						className="input-adornment"
						sx={{
							backgroundColor: '#ffffff',
							borderRadius: '5px'
						}}
					>
						<img src={alert_triangle} alt="Alert" className="input-adornment-image" />
					</InputAdornment>
				)
			}}
			InputLabelProps={{
				style: {
					color: `${error ? '#FF0000' : '#707070'}`,
					fontSize: '18px'
				}
			}}
		/>
	);
};

export default React.memo(StyledInput);
