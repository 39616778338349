import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Language } from '../../app/slices/customizations.slice';

interface Props {
	onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
	open: boolean;
	src: string;
	language: Language;
}

const LanguageSelectContainer: FC<Props> = ({ onClick, open, src, language }) => {
	const { t } = useTranslation();

	return (
		<div
			id="language-container"
			className="language-link-2"
			aria-controls={open ? 'basic-menu' : undefined}
			aria-haspopup="true"
			aria-expanded={open ? 'true' : undefined}
			onClick={onClick}
			style={{ display: 'flex' }}
		>
			<img id={language + '-flag'} className="language-flag" src={src} alt="Language" />
			<span style={{ paddingLeft: '5px' }}>{t('footer.language')}</span>
		</div>
	);
};

export default LanguageSelectContainer;
