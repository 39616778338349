import React, { FC, useEffect } from 'react';
import { WithChildren } from './SingleSpinner';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { TechErrorScreen } from '../steps/screens/TechErrorScreen';
import NewRelicAgent from '../../newrelic';
import { useAppSelector } from '../../app/hooks';
import { selectErrorAnalyticsData } from '../../app/selectors';

export const AppErrorBoundary: FC<WithChildren> = ({ children }) => {
	return <ErrorBoundary FallbackComponent={AppErrorFallback}>{children}</ErrorBoundary>;
};

export const AppErrorFallback: FC<FallbackProps> = ({ error }) => {
	const data = useAppSelector(selectErrorAnalyticsData);

	// report error to analytics service (New Relic)
	useEffect(() => {
		NewRelicAgent.noticeError(error, {
			state: data
		});
	}, [error]);

	return <TechErrorScreen error={error} />;
};
