/**
 * Replaces all characters in a string with '*'.
 *
 * @param {string} input - The original string to be masked.
 * @returns {string} - A new string with all characters replaced by '*'.
 *
 * @example
 * const maskedString = maskString('password123');
 * console.log(maskedString); // ***********
 */
export const maskString = (input: string): string => {
	return '*'.repeat(input.length);
};
