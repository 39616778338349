// Function to serialize the Axios request object
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

export const serializeAxiosRequest = (request: InternalAxiosRequestConfig) => {
	// omit data (sensitive)
	const serializedRequest = {
		url: request.url,
		baseURL: request.baseURL,
		method: request.method,
		headers: request.headers,
		params: request.params,
		timeout: request.timeout
	};

	return JSON.stringify(serializedRequest, null, 2);
};

export const serializeAxiosResponse = (response: AxiosResponse) => {
	const serializedRequest = {
		status: response.status,
		statusText: response.statusText,
		headers: response.headers,
		data: response.data
	};

	return JSON.stringify(serializedRequest, null, 2);
};
