import { IRequestInterceptor, JWTRequestInterceptor } from './RequestInterceptors';
import { checkReportAxiosInstance } from './axios';
import { AxiosResponse } from 'axios';
import { Check } from '../check/types';

export type ErrorResponse = {
	errors: string;
};

export type UpdateReportResponse = {
	status: string;
	errors?: Array<string>;
};

export class CheckReportService {
	private _jwtInterceptor: IRequestInterceptor;

	constructor(interceptor: IRequestInterceptor) {
		this._jwtInterceptor = interceptor;
	}

	private async sendRequest(request: () => Promise<any>) {
		return await this._jwtInterceptor.interceptRequest(request);
	}

	public async fetchReport(): Promise<AxiosResponse<Check> | AxiosResponse<ErrorResponse>> {
		return await this.sendRequest(this._fetchReport);
	}

	private async _fetchReport() {
		return await checkReportAxiosInstance.get('');
	}

	public async putPersonaData(data: any): Promise<AxiosResponse<UpdateReportResponse>> {
		return await this.sendRequest(async () => await this._putPersonaData(data));
	}

	private async _putPersonaData(data: any) {
		return await checkReportAxiosInstance.put('', data);
	}

	public async putReportData(data: any) {
		return await this.sendRequest(() => this._putReportData(data));
	}

	private async _putReportData(data: any) {
		return await checkReportAxiosInstance.put('', data);
	}
}

const jwtRequestInterceptor = new JWTRequestInterceptor();
export const checkReportAPI = new CheckReportService(jwtRequestInterceptor);
