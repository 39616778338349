import { FC } from 'react';
import { useDynamicCustomizationContent } from './TextCustomization';
import { CustomizationKey } from '../../app/slices/customizations.slice';
import Markdown, { Components } from 'react-markdown';

interface Props {
	id: CustomizationKey;
	components: Components;
	data?: Record<string, string>;
}

export const AdvancedCustomization: FC<Props> = ({ id, components, data }) => {
	const customization = useDynamicCustomizationContent(id, data || {});
	return <Markdown components={components}>{customization}</Markdown>;
};
