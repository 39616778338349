import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppDispatch } from '../../../app/hooks';
import { changeStep } from '../../../app/slices/step.slice';
import { RESEND_VERIFICATION_CODE_TIMER } from './ResendVerificationCodeSegment';
import { AdvancedCustomization } from '../../customizations/AdvancedCustomization';

export const ReEnterPhoneText: FC = () => {
	const dispatch = useAppDispatch();

	const handleClick = () => {
		dispatch(changeStep('PHONE_NUMBER_INPUT_STEP'));
		sessionStorage.removeItem(RESEND_VERIFICATION_CODE_TIMER);
	};

	return (
		<Box sx={boxSX}>
			<Typography sx={textSX}>
				<AdvancedCustomization
					id="PHONE_NUMBER_VERIFICATION_CODE_INPUT_STEP_CONTENT_2"
					components={{
						code: ({ children }) => {
							return (
								<u onClick={handleClick} style={{ cursor: 'pointer' }}>
									{children}
								</u>
							);
						}
					}}
				/>
			</Typography>
		</Box>
	);
};

const boxSX = { width: '100%', textAlign: 'center', mt: 5 };
const textSX = { fontSize: 'medium' };
