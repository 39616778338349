import { Box } from '@mui/material';
import React, { FC, useRef, useState } from 'react';
import { Header } from '../blank-check-input-step/Header';
import { VerificationCodeInput } from './VerificationCodeInput';
import { Heading } from './Heading';
import { z } from 'zod';
import { sendPhoneVerificationCode } from '../../../app/thunks/send-phone-verification-code';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ErrorMessage } from './ErrorMessage';
import { ReEnterPhoneText } from './ReEnterPhoneText';
import { SubmitButton } from '../shared/SubmitButton';
import { PhoneNumberVerificationCodeIncorrectError } from '../../../services/PhoneNumberVerificationService';
import { useAppTranslation } from '../../../i18n';
import { selectPersonaPhoneNumber } from '../../../app/slices/persona.slice';
import { ResendVerificationCodeSegment } from './ResendVerificationCodeSegment';

export const PhoneNumberVerificationStep: FC = () => {
	const dispatch = useAppDispatch();
	const { t } = useAppTranslation();

	const providedPhone = useAppSelector(selectPersonaPhoneNumber) as string;

	const [code, setCode] = useState<string>('');
	const [error, setError] = useState<string>('');
	const [submitting, setSubmitting] = useState<boolean>(false);
	const firstRef = useRef<boolean>(true);
	const incorrectCodeRef = useRef<string>('');

	const handleChange = (value: string): void => {
		setCode(value);

		if (incorrectCodeRef.current !== value) {
			setError('');
		}

		if (firstRef.current) {
			const { success } = schema.safeParse(value);
			if (success) {
				submit(value).catch(() => {});
				firstRef.current = false;
			}
		}
	};

	const handleSubmit = async (): Promise<void> => {
		await submit(code);
	};

	const submit = async (code: string): Promise<void> => {
		if (submitting) return;

		setSubmitting(true);
		try {
			await dispatch(sendPhoneVerificationCode(code));
		} catch (error) {
			incorrectCodeRef.current = code;

			if (error instanceof PhoneNumberVerificationCodeIncorrectError) {
				setError('The verification code you entered is incorrect. Please check the code and try again.');
				return;
			}

			setError(t('errors.technical-issue'));
		} finally {
			setSubmitting(false);
		}
	};

	const { success } = schema.safeParse(code);

	const isButtonDisabled = !success || incorrectCodeRef.current === code;
	return (
		<Box id="phone-number-verification-step" sx={{ overflowX: 'hidden', pt: 1, pb: 5 }}>
			<Header />
			<Heading phone={providedPhone} />
			<Box sx={{ width: '100%', mt: 3, mb: 2 }}>
				<VerificationCodeInput onChange={handleChange} />
				<Box sx={{ mt: 3 }}>
					<ErrorMessage error={error} />
				</Box>
			</Box>
			<SubmitButton disabled={isButtonDisabled} submitting={submitting} onSubmit={handleSubmit} />
			<ResendVerificationCodeSegment />
			<ReEnterPhoneText />
		</Box>
	);
};

const schema = z
	.string()
	.length(6)
	.regex(/^[0-9]{6}$/);
