import { MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import logo from '../../assets/images/logoBck.svg';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { ModalEnum, uiActions } from '../../app/slices/ui.slice';

const IMPRESSUM_TRANS_KEY = 'footer.impressum';
const YEAR = new Date().getFullYear();

const FooterPowerBy = () => {
	const dispatch = useDispatch();
	const theme = useTheme();

	const { t } = useTranslation();

	const handleOpenImprintModal = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		dispatch(uiActions.toggleModal(ModalEnum.IMPRESSIUM_MODAL));
	};

	return (
		<div className="footer-power-by" style={{ backgroundColor: `${theme.palette.primary.main}` }}>
			<div className="fpb-copy">
				<span>&copy;&nbsp;{YEAR}</span>
			</div>
			<div className="content">
				<div className="fpb-power">
					<span className="impressium-link">powered&nbsp;by</span>
				</div>
				<img src={logo} alt={'Fincredible Logo'} className="footer-logo" />
				<div className="fpb-power">
					<span className="impressium-link">FINcredible</span>
				</div>
			</div>
			<div className="fpb-impressium" onClick={handleOpenImprintModal}>
				<span>{t(IMPRESSUM_TRANS_KEY)}</span>
			</div>
		</div>
	);
};

export default FooterPowerBy;
