import FinalScreen, { FinalScreenProps } from './FinalScreen';
import { XCircle } from '../../svg/XCircle';
import React from 'react';

export const CanceledScreen = () => {
	const screenProps: FinalScreenProps = {
		titleCustomizationKey: 'CANCELLED_PAGE_TITLE',
		textCustomizationKeys: [
			'CANCELLED_PAGE_INFO',
			'CANCELLED_PAGE_ADDITIONAL_INFO',
			'CANCELLED_PAGE_ADDITIONAL_SECTION'
		],
		icon: <XCircle />
	};

	return <FinalScreen {...screenProps} />;
};
