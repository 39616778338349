import React, { FC, MouseEventHandler } from 'react';
import DiscardResultsTextCustomization from '../../customizations/DiscardResultsTextCustomization';

interface DiscardCheckSegmentProps {
	onDiscard(): void;
}

export const DiscardCheckSegment: FC<DiscardCheckSegmentProps> = ({ onDiscard }) => {
	const onDiscardClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
		event.preventDefault();
		event.stopPropagation();
		onDiscard();
	};

	return (
		<div className="details-under-button">
			<DiscardResultsTextCustomization onClick={onDiscardClick} />
		</div>
	);
};

export default DiscardCheckSegment;
