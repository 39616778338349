import { AppDispatch } from '../store';
import { checkService } from '../../services/CheckService';
import { handleError } from '../slices/errors.slice';
import { changeStep } from '../slices/step.slice';
import { uiActions } from '../slices/ui.slice';

export const discardCheck = () => {
	return async (dispatch: AppDispatch): Promise<void> => {
		try {
			await checkService.discardCheck();

			dispatch(uiActions.setFinalStepType('DISCARDED'));
			dispatch(changeStep('CHECK_FINISHED_STEP'));
		} catch (error) {
			dispatch(handleError(error));
		}
	};
};
