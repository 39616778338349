import React, { FC, ReactNode, useMemo } from 'react';
import austria from '../../../assets/images/flags/austria-flag.png';
import germany from '../../../assets/images/flags/germany-flag.png';
import { PhoneNumberCountryOption } from './PhoneNumberCountryOption';
import { Select } from '../../custom/Select';
import { useAppTranslation } from '../../../i18n';
import { CountryCode } from '../../../constants/country-phone-number';

interface Option {
	value: CountryCode;
	label: ReactNode;
}

interface Props {
	code: CountryCode;
	onChange(code: CountryCode): void;
}

export const PhoneNumberCountrySelect: FC<Props> = ({ code, onChange }) => {
	const { t } = useAppTranslation();

	const options = useMemo((): Array<Option> => {
		return [
			{
				value: '+43',
				label: (
					<PhoneNumberCountryOption
						text={`${t('countries.AT')} (+43)`}
						image={{ src: austria, alt: 'Austria flag' }}
					/>
				)
			},
			{
				value: '+49',
				label: (
					<PhoneNumberCountryOption
						text={`${t('countries.DE')} (+49)`}
						image={{ src: germany, alt: 'Germany flag' }}
					/>
				)
			}
		];
	}, [t]);

	const label = t('inputs.country');
	return (
		<Select
			id="country"
			name="country"
			label={label}
			options={options}
			value={code}
			disabled={false}
			onChange={onChange}
		/>
	);
};
