import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

type BankAccountState = {
	accountHolder: string;
	iban: string;
	bic: string;
	bankName: string;
	bankCountry: string;
};

const initialState: BankAccountState = {
	accountHolder: '',
	bankCountry: '',
	bic: '',
	bankName: '',
	iban: ''
};

const bankAccountSlice = createSlice({
	name: 'bankAccount',
	initialState,
	reducers: {
		setBankAccount(state, action: PayloadAction<BankAccountState>) {
			return { ...action.payload };
		}
	}
});

export const selectBankAccount = (state: RootState) => state.bankAccount;

export const bankAccountActions = bankAccountSlice.actions;

export const { setBankAccount } = bankAccountActions;

export default bankAccountSlice;
