import React from 'react';
import { Accordion } from '../tabs/Accordion';
import Markdown from 'react-markdown';
import { useTheme } from '@mui/material';
import useTitleContentParser from '../hooks/useTitleContentParser';

interface MarkdownRendererProps {
	markdownText: string | undefined;
}

const FAQsMarkdown: React.FC<MarkdownRendererProps> = ({ markdownText }) => {
	const renderers = {
		code: CustomCodeBlock,
		h1: CustomHeading
	};

	return <Markdown components={renderers}>{markdownText}</Markdown>;
};

export default FAQsMarkdown;

const CustomCodeBlock: React.FC<React.HTMLProps<HTMLElement>> = ({ children, ...rest }) => {
	const faqContent = useTitleContentParser(children as string);
	if (!faqContent) return null;
	return <Accordion title={faqContent.title} content={faqContent.content} {...rest} />;
};

const CustomHeading: React.FC<React.HTMLProps<HTMLParagraphElement>> = ({ children, ...rest }) => {
	const theme = useTheme();
	const customStyle: React.CSSProperties = {
		fontSize: 'large',
		fontWeight: 'bold',
		borderBottom: `1px dashed ${theme.palette.primary.main}`,
		textAlign: 'center',
		padding: '15px'
	};
	return (
		<p style={customStyle} {...rest}>
			{children}
		</p>
	);
};
