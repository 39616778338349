import useCustomization from '../../hooks/useCustomization';
import LegalDocsMarkdown from '../custom/LegalDocsMarkdown';
import React from 'react';
import { CustomizationKey } from '../../app/slices/customizations.slice';

const CONDITIONS_KEY: CustomizationKey = 'CONDITIONS';

const AGBModalCustomization = () => {
	const customization = useCustomization(CONDITIONS_KEY);
	if (!customization) return null;
	return <LegalDocsMarkdown content={customization} />;
};

export default AGBModalCustomization;
