import React, { FC, useState } from 'react';
import { CheckCircle } from '../../svg/CheckCircle';
import TextCustomization from '../../customizations/TextCustomization';
import { Box, useTheme } from '@mui/material';
import { SubmitButton } from '../shared/SubmitButton';
import ResultsSummaryText from '../ResultsSummaryText';
import DiscardCheckSegment from '../check-results/DiscardCheckSegment';
import { useAppDispatch } from '../../../app/hooks';
import { changeStep } from '../../../app/slices/step.slice';
import { discardCheck } from '../../../app/thunks/discard-check';
import { useAppTranslation } from '../../../i18n';
import { acceptCheck } from '../../../app/thunks/accept-check';
import { useObserveCheckAutoAccept } from '../../../hooks/useObserveCheckAutoAccept';

export const CheckResultsSummaryStep: FC = () => {
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const { t } = useAppTranslation();

	const [accepting, setAccepting] = useState(false);
	useObserveCheckAutoAccept();

	const handleComplete = () => {
		setAccepting(true);
		dispatch(acceptCheck()).catch(() => {
			setAccepting(false);
		});
	};

	const handleContinue = () => {
		dispatch(changeStep('CHECK_RESULTS_STEP'));
	};

	const handleDiscard = () => {
		dispatch(discardCheck()).catch(() => {});
	};

	return (
		<Box id="check-results-summary-step" className="results-summary" style={{ marginTop: '100px' }}>
			<Box className="results-summary-message-text">
				<CheckCircle />
				<Box className="results-summary-title" style={{ color: `${theme.palette.primary.main}` }}>
					<TextCustomization id="RESULTS_SUMMARY_PAGE_TITLE" />
				</Box>
				<Box className="results-summary-text">
					<TextCustomization id="RESULTS_SUMMARY_COMPLETE_CHECK_TEXT" />
				</Box>
			</Box>
			<SubmitButton
				text={t('button.complete')}
				disabled={false}
				submitting={accepting}
				onSubmit={handleComplete}
			/>
			<ResultsSummaryText onDetailsClick={handleContinue} />
			<DiscardCheckSegment onDiscard={handleDiscard} />
		</Box>
	);
};
