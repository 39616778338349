import React from 'react';
import { useAppSelector } from '../../../app/hooks';
import FinalScreen, { FinalScreenProps } from './FinalScreen';
import { AwardIcon } from '../../svg/Award';
import { XCircle } from '../../svg/XCircle';
import { CompletedCheckType, selectCompletedCheckType } from '../../../app/slices/ui.slice';
import GradientCheckIcon from '../../svg/GradientCheckIcon';

const getCompletedScreenProps = (completedCheckType: CompletedCheckType): FinalScreenProps => {
	switch (completedCheckType) {
		case 'ACCEPTED':
			return {
				titleCustomizationKey: 'ACCEPTED_PAGE_TITLE',
				textCustomizationKeys: ['ACCEPTED_PAGE_INFO', 'ACCEPTED_PAGE_ADDITIONAL_INFO'],
				icon: <AwardIcon />
			};
		case 'DISCARDED':
			return {
				titleCustomizationKey: 'DISCARDED_PAGE_TITLE',
				textCustomizationKeys: ['DISCARDED_PAGE_INFO', 'DISCARDED_PAGE_ADDITIONAL_INFO'],
				icon: <XCircle />
			};
		case 'ACCEPTED_RELOADED':
			return {
				titleCustomizationKey: 'ACCEPTED_RELOADED_PAGE_TITLE',
				textCustomizationKeys: ['ACCEPTED_RELOADED_PAGE_INFO', 'ACCEPTED_RELOADED_PAGE_ADDITIONAL_INFO'],
				icon: <AwardIcon />
			};
		case 'DISCARDED_RELOADED':
			return {
				titleCustomizationKey: 'DISCARDED_RELOADED_PAGE_TITLE',
				textCustomizationKeys: [
					'DISCARDED_RELOADED_PAGE_INFO',
					'DISCARDED_RELOADED_PAGE_ADDITIONAL_INFO',
					'DISCARDED_RELOADED_PAGE_ADDITIONAL_SECTION'
				],
				icon: <XCircle />
			};
		case 'EMAIL_VALIDATION_FINISHED':
			return {
				titleCustomizationKey: 'EMAIL_VALIDATION_FINISHED_PAGE_TITLE',
				textCustomizationKeys: ['EMAIL_VALIDATION_FINISHED_PAGE_SUBTITLE'],
				icon: <GradientCheckIcon />
			};
		default:
			throw new Error('Unsupported completed check type occurred.');
	}
};

export const CompletedScreen = () => {
	const completedCheckType = useAppSelector(selectCompletedCheckType);
	const screenProps = getCompletedScreenProps(completedCheckType);

	return <FinalScreen {...screenProps} />;
};
