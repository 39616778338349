import { useAppSelector } from '../../app/hooks';
import React from 'react';
import ScoreComponent from './ScoreComponent';
import { selectLights, selectScore } from '../../app/slices/report.slice';

export const ScoreComponentWrapper = () => {
	let score = useAppSelector(selectScore);
	let scoreLights = useAppSelector(selectLights);

	if (!score && !scoreLights) return <></>;

	const props = { score, light: scoreLights };

	return <ScoreComponent {...props} />;
};
