import { Check } from '../check/types';
import { CheckStatus } from '../types/check';
import { FINcredbileReport } from '../check/report';

export const getCheckStatus = (check: Check): CheckStatus => {
	const report = getFincredibleReport(check);
	return report.status;
};

export const getFincredibleReport = (check: Check): FINcredbileReport => {
	const reports = check.reports;
	const fincredibleReport = reports.find((report) => report.name === 'FINCREDIBLE');
	if (!fincredibleReport) throw new Error('There is no FINCREDIBLE report');
	return fincredibleReport as FINcredbileReport;
};
