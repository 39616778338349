import React, { useMemo, useState } from 'react';
import infoIcon from '../../assets/images/icons/info.svg';
import commentIcon from '../../assets/images/icons/message-square.svg';
import commentIconFull from '../../assets/images/icons/message-square-full.svg';
import CheckAccordionItem from './CheckAccordionItem';
import { CheckStatusInfo } from './CheckStatusInfo';
import { Tooltip, Zoom } from '@mui/material';
import { WithChildren } from '../custom/SingleSpinner';
import { ItemStatus } from '../../types/constants';
import { useTranslation } from 'react-i18next';
import { RESULTS_PAGE_PREFIX } from '../../utils/translationPagePrefix';

export type AccordionItem = OptionsProps & {
	title: string | React.ReactElement;
	subtitle?: string;
	commentFull?: boolean;
	onInfoClick?: () => void;
	onDetailClick?: () => void;
	onCommentClick?: () => void;
	status?: ItemStatus;
};

type OpenableAccordionRow = {
	openable: true;
	items: Array<AccordionItem>;
};

type NotOpenableAccordionRow = {
	openable: false;
};

type OpenableAccordionType = OpenableAccordionRow | NotOpenableAccordionRow;

const AccordionRowStatusColorMap: Map<ItemStatus, string> = new Map([
	[ItemStatus.VERIFIED, '#69E2AB'],
	[ItemStatus.UNVERIFIED, '#707070']
]);

export type OptionsProps = {
	displayDetails?: boolean;
	displayInfo?: boolean;
	displayComment?: boolean;
};

type CheckAccordionRowProps = OpenableAccordionType &
	OptionsProps & {
		title: string | React.ReactElement;
		subtitle?: string;
		onDetailsClick?: () => void;
		onInfoClick?: () => void;
		onCommentClick?: () => void;
		commentFull?: boolean;
		verified?: ItemStatus;
		konto?: boolean;
		style?: object;
		indicatorColor?: string;
		soloTitle?: boolean;
	};

export const CheckAccordionRow = ({
	title,
	displayDetails = true,
	displayComment = true,
	displayInfo = true,
	openable = true,
	onDetailsClick,
	onCommentClick,
	onInfoClick,
	commentFull,
	konto = true,
	verified = ItemStatus.VERIFIED,
	style = {},
	//@ts-ignore
	items,
	indicatorColor = '',
	soloTitle = false
}: CheckAccordionRowProps) => {
	const [open, setOpen] = useState<boolean>(false);

	const onClickDetails = () => {
		if (onDetailsClick) onDetailsClick();
	};

	const onClickComment = () => {
		if (onCommentClick) onCommentClick();
	};

	const onClickInfo = () => {
		if (onInfoClick) onInfoClick();
	};

	const onAccordionClick = (event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
		if (openable) setOpen((open) => !open);
	};

	// Save mapped items, to NOT rerender on every close/open actions
	const mappedItems = useMemo(() => {
		if (!openable) return;
		return (
			<React.Fragment>
				{items.map((item: AccordionItem, index: number) => {
					return <CheckAccordionItem {...item} displayComment={item.displayComment} key={index} />;
				})}
				<CheckStatusInfo konto={konto} />
			</React.Fragment>
		);
	}, [items]);

	return (
		<React.Fragment>
			<div
				className={`accordion-summary ${openable && 'openable-accordion'}`}
				onClick={onAccordionClick}
				style={style}
			>
				<div
					className="accordion-summary-content"
					style={
						!displayComment && !displayInfo && !displayDetails
							? {
									width: '100%'
							  }
							: {}
					}
				>
					<div className="acc-status">
						<div
							className={`indicator-div ${indicatorColor}`}
							style={{
								background: AccordionRowStatusColorMap.get(verified)
							}}
						/>
					</div>
					<div className="acc-title">
						<span>{title}</span>
					</div>
				</div>
				<div className="accordion-summary-options" style={{ width: soloTitle === true ? '0px' : '' }}>
					<div className="acc-details">
						{displayDetails && (
							<div className="column-checkbox-button">
								<button onClick={onClickDetails}>Details</button>
							</div>
						)}
					</div>
					<div className={`acc-info ${!openable && 'acc-info-not-openable'}`}>
						{displayInfo && (
							<>
								<TooltipCustomized>
									<img src={infoIcon} alt="Info" className="acc-icon" onClick={onClickInfo} />
								</TooltipCustomized>
							</>
						)}
					</div>
					<div className={`acc-comment ${!openable && 'acc-info-not-openable'}`}>
						{displayComment && (
							<img
								src={commentFull ? commentIconFull : commentIcon}
								alt="Comment"
								className="acc-icon"
								onClick={onClickComment}
							/>
						)}
					</div>
				</div>
			</div>
			{open && openable && mappedItems}
		</React.Fragment>
	);
};

export default React.memo(CheckAccordionRow);

const tooltipTranslationKey = `${RESULTS_PAGE_PREFIX}.info tooltip text`;

type TooltipCustomizedProps = WithChildren<{
	text?: string;
}>;

export const TooltipCustomized = ({ children, text }: TooltipCustomizedProps) => {
	const { t } = useTranslation();

	if (!text) text = t(tooltipTranslationKey);

	return (
		<Tooltip
			title={text as string}
			placement="top"
			TransitionComponent={Zoom}
			TransitionProps={{ timeout: 50 }}
			className="tooltip-customized"
			PopperProps={{ disablePortal: true }}
		>
			{children as React.ReactElement}
		</Tooltip>
	);
};
