import React, { useState } from 'react';
import { useTheme } from '@mui/material';
import StepButton from '../StepButton';
import { useAppDispatch } from '../../../app/hooks';
import GradientCheckIcon from '../../svg/GradientCheckIcon';
import TextCustomization from '../../customizations/TextCustomization';
import { CustomizationKey } from '../../../app/slices/customizations.slice';
import { useTranslation } from 'react-i18next';
import { completeEmailVerification } from '../../../app/thunks/complete-email-verification';

const BLANK_CHECK_EMAIL_VERIFIED_PAGE_TITLE_KEY: CustomizationKey = 'BLANK_CHECK_EMAIL_VERIFIED_PAGE_TITLE';
const BLANK_CHECK_EMAIL_VERIFIED_PAGE_SUBTITLE_KEY: CustomizationKey = 'BLANK_CHECK_EMAIL_VERIFIED_PAGE_SUBTITLE';

const EmailVerifiedStep = () => {
	const dispatch = useAppDispatch();
	const theme = useTheme();
	const { t } = useTranslation();

	const [isNextButtonDisabled] = useState(true);

	const style = {
		mainColor: theme.palette.primary.main,
		secondaryColor: theme.palette.secondary.main
	};

	const handleContinue = () => {
		dispatch(completeEmailVerification());
	};

	return (
		<div className="email-step" style={{ marginTop: '120px' }}>
			<div className="email-step-container">
				<div style={{ marginBottom: '10px' }}>
					<GradientCheckIcon />
				</div>
				<br />
				<div className="email-step-main-title-style" style={{ color: `${style.mainColor}` }}>
					<TextCustomization id={BLANK_CHECK_EMAIL_VERIFIED_PAGE_TITLE_KEY} />
				</div>
				<br />
				<div className="email-step-sub-title-style">
					<TextCustomization id={BLANK_CHECK_EMAIL_VERIFIED_PAGE_SUBTITLE_KEY} />
				</div>
				<br />
			</div>
			<StepButton
				disabled={false}
				className={`${isNextButtonDisabled && 'disabled-next-button'}`}
				onClick={handleContinue}
				title={t('button.next')}
			/>
		</div>
	);
};

export default EmailVerifiedStep;
