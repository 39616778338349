import { FC } from 'react';
import { Box } from '@mui/material';
import sms from '../../../assets/images/sending-sms-icon.jpg';
import Loading from '../../custom/Loading';

export const LoadingAnimation: FC = () => {
	return (
		<Loading>
			<Box sx={containerSX}>
				<img src={sms} alt="Sending SMS message icon" style={imageSX} />
			</Box>
		</Loading>
	);
};

const containerSX = {
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column'
};

const imageSX = {
	width: '130%',
	borderRadius: '50%'
};
