import { ThemeProvider } from '@mui/material';
import { WithChildren } from '../custom/SingleSpinner';
import { useAppSelector } from '../../app/hooks';
import { selectClientColor } from '../../app/slices/metadata.slice';
import ThemePalette from '../../theme';
import { createTheme, Theme } from '@mui/material/styles';
import { set_brightness } from '../../theme/utils';

export const MuiThemeProvider = ({ children }: WithChildren) => {
	const clientColor = useAppSelector(selectClientColor);
	const primaryColor = getPrimaryColor(clientColor);
	const secondaryColor = getSecondaryColor(clientColor);

	const paletteColor = ThemePalette(primaryColor);
	const theme: Theme = createTheme({
		palette: {
			primary: {
				main: paletteColor.primary.main,
				light: paletteColor.primary.light,
				lighter: paletteColor.primary.lighter,
				dark: paletteColor.primary.dark,
				darker: paletteColor.primary.darker
			},
			secondary: {
				main: secondaryColor || ''
			}
		}
	});

	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

function getPrimaryColor(color: string | undefined) {
	return color || `#0082E1`;
}

function getSecondaryColor(color: string | undefined) {
	return color ? set_brightness(color, 40, 'increase') : `#48B0FF`;
}
