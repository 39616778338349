import React, { useCallback } from 'react';
import { useAppDispatch } from '../app/hooks';
import { ModalEnum, uiActions } from '../app/slices/ui.slice';
import { Dispatch } from '@reduxjs/toolkit';

const HREF_DELIMITER = '%';

enum ModalType {
	PRIVACY = 'PRIVACY',
	TERMS = 'TERMS',
	XS2A_TERMS = 'XS2ATERMS',
	XS2A_PRIVACY = 'XS2APRIVACY'
}

const OPEN_PRIVACY_MODAL_ACTION = uiActions.toggleModal(ModalEnum.PRIVACY_MODAL);
const OPEN_TERMS_MODAL_ACTION = uiActions.toggleModal(ModalEnum.AGB_MODAL);
const OPEN_XS2A_TERMS_MODAL_ACTION = uiActions.toggleModal(ModalEnum.XS2ATERMS_MODAL);
const OPEN_XS2A_PRIVACY_MODAL_ACTION = uiActions.toggleModal(ModalEnum.XS2APRIVACY_MODAL);

const handleMDLinkClick = (href: string, dispatch: Dispatch) => {
	switch (href) {
		case ModalType.PRIVACY:
			dispatch(OPEN_PRIVACY_MODAL_ACTION);
			return;
		case ModalType.TERMS:
			dispatch(OPEN_TERMS_MODAL_ACTION);
			return;
		case ModalType.XS2A_PRIVACY:
			dispatch(OPEN_XS2A_PRIVACY_MODAL_ACTION);
			return;
		case ModalType.XS2A_TERMS:
			dispatch(OPEN_XS2A_TERMS_MODAL_ACTION);
			return;
		default:
			window.location.href = href;
			return;
	}
};
const useMDLinkEventHandler = () => {
	const dispatch = useAppDispatch();

	return useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		const href = event.currentTarget.getAttribute('href');
		const [first, second] = href?.split(HREF_DELIMITER) ?? [];

		[first, second].filter(Boolean).forEach((link) => handleMDLinkClick(link, dispatch));
	}, []);
};

export default useMDLinkEventHandler;
