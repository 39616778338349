import React, { useMemo, useState } from 'react';
import { residenceOptionsSelect } from '../../../types/constants';
import moment from 'moment';
import StyledInput, { ValidationResult } from '../../custom/StyledInput';
import DatePicker from '../../custom/DatePicker';
import { SelectOption, StyledSelect } from '../../custom/StyledSelect';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import DateConverter, { DateFormat, LimitCheckPeriod } from '../../../utils/DateConverter';
import { FlagsEnum, selectFlag } from '../../../app/slices/flags.slice';
import { selectPersona, updatePersonaInformation } from '../../../app/slices/persona.slice';
import { Box } from '@mui/material';
import { SubmitButton } from '../shared/SubmitButton';
import { Header } from './Header';
import { useAppTranslation } from '../../../i18n';
import { Country } from '../../../constants/country-phone-number';
import { changeStep } from '../../../app/slices/step.slice';
import { AppEnv, Env } from '../../../constants/env';

const PersonaInformationInputStep = () => {
	const dispatch = useAppDispatch();

	const persona = useAppSelector(selectPersona);
	const isEditingPermitted = useAppSelector((state) => selectFlag(state, FlagsEnum.EDIT_CHECK_DATA));

	const [firstname, setFirstname] = useState<string>(persona.firstName || '');
	const [lastname, setLastname] = useState<string>(persona.lastName || '');
	const [country, setCountry] = useState<string>(persona.residence.country || '');
	const [dateOfBirth, setDateOfBirth] = useState<string>(persona.dateOfBirth || '');

	const [errors, setErrors] = useState({
		firstname: !persona.firstName,
		lastname: !persona.lastName,
		country: !persona.residence.country,
		dateOfBirth: !persona.dateOfBirth
	});

	const { t, i18n } = useAppTranslation();

	// for testing purpose
	if (getCurrentEnv() === AppEnv.DEV && firstname === 'error') {
		throw new Error('test');
	}

	const handleChangeFirstName = (result: ValidationResult) => {
		const { value, isValid } = result;

		setFirstname(value);
		setErrors((errors) => ({
			...errors,
			firstname: !isValid
		}));
	};

	const handleChangeLastName = (result: ValidationResult) => {
		const { value, isValid } = result;
		setLastname(value);
		setErrors((errors) => ({
			...errors,
			lastname: !isValid
		}));
	};

	const handleChangeCountry = (option: SelectOption) => {
		setCountry(option.code);
		setErrors((errors) => ({
			...errors,
			country: false
		}));
	};

	const handleChangeDateOfBirth = (param: { isValid: boolean; date: Date | null }) => {
		const { isValid, date } = param;

		if (date) {
			const formattedDate = moment(new Date(date)).format('DD.MM.YYYY');
			setDateOfBirth(formattedDate);
		}

		setErrors((errors) => ({
			...errors,
			dateOfBirth: !isValid
		}));
	};

	const countryOptions = useMemo(() => {
		return residenceOptionsSelect.map((residence) => ({
			value: t(`countries.${residence.value as Country}`),
			code: residence.value
		}));
	}, [i18n.language]);

	const handleSubmit = () => {
		dispatch(
			updatePersonaInformation({
				firstName: firstname,
				lastName: lastname,
				country,
				dateOfBirth
			})
		);

		dispatch(changeStep('PERSONA_CONSENT_INPUT_STEP'));
	};

	const isSubmitDisabled = Object.values(errors).filter((value) => value).length > 0;
	const maxDate = moment().subtract(LimitCheckPeriod.MAX_DATE, LimitCheckPeriod.TIME_METRIC);

	// inputs are disabled if editing is not permitted and persona info is provided
	const isEditingFirstnameDisabled = !isEditingPermitted && !!persona.firstName;
	const isEditingLastnameDisabled = !isEditingPermitted && !!persona.lastName;
	const isEditingCountryDisabled = !isEditingPermitted && !!persona.residence.country;
	const isEditingDateOfBirthDisabled = !isEditingPermitted && !!persona.dateOfBirth;
	return (
		<Box id="persona-information-input-step">
			<Header />
			<Box className="personal-info-step" sx={{ pt: 3, pb: 3 }}>
				<StyledInput
					id="name"
					placeholder={t(`personal-info-page.inputs.firstname`)}
					onChange={handleChangeFirstName}
					inputValue={persona?.firstName}
					disabled={isEditingFirstnameDisabled}
					minCharacters={2}
				/>
				<StyledInput
					id="lastname"
					placeholder={t(`personal-info-page.inputs.lastname`)}
					onChange={handleChangeLastName}
					inputValue={persona?.lastName}
					disabled={isEditingLastnameDisabled}
					minCharacters={2}
				/>
				<StyledSelect
					id="select-country"
					label={t(`personal-info-page.inputs.country`)}
					options={countryOptions}
					onChange={handleChangeCountry}
					initValue={country ? { value: t(`countries.${country as Country}`), code: country } : undefined}
					disabled={isEditingCountryDisabled}
				/>
				<DatePicker
					id="date-picker"
					maxDate={maxDate}
					placeholder={t(`personal-info-page.inputs.dateOfBirth`)}
					onChange={handleChangeDateOfBirth}
					initValue={persona?.dateOfBirth ? formatDate(persona.dateOfBirth) : null}
					disabled={isEditingDateOfBirthDisabled}
				/>
			</Box>
			<Box sx={{ mb: 5 }}>
				<SubmitButton disabled={isSubmitDisabled} submitting={false} onSubmit={handleSubmit} />
			</Box>
		</Box>
	);
};

export default PersonaInformationInputStep;

const formatDate = (dateOfBirth: string) => {
	return new Date(DateConverter.convert(dateOfBirth, DateFormat.API_FORMAT));
};

const getCurrentEnv = () => {
	return process.env[Env.REACT_APP_ENV] || AppEnv.LIVE;
};
