import { Menu, MenuItem } from '@mui/material';
import React, { FC } from 'react';
import { LanguageOption } from './LanguageSelect';
import { Language } from '../../app/slices/customizations.slice';
import { useAppTranslation } from '../../i18n';

interface Props {
	isOpen: boolean;
	options: Array<LanguageOption>;
	language: Language;
	anchor: null | HTMLElement;
	onLanguageChange(language: Language): void;
	onClose(): void;
}

const LanguageMenu: FC<Props> = ({ options, isOpen, onLanguageChange, onClose, language, anchor }) => {
	const { t } = useAppTranslation();

	return (
		<Menu
			id="language-menu"
			open={isOpen}
			anchorEl={anchor}
			MenuListProps={{ 'aria-labelledby': 'language-container' }}
			onClose={onClose}
		>
			{options.map((option) => {
				const isCurrent = option.language === language;

				const handleClick = () => {
					onLanguageChange(option.language);
				};

				const text = t(`common.language.${option.language}`);
				return (
					<MenuItem
						key={option.language}
						onClick={handleClick}
						className={`language-menu-item ${isCurrent && 'selected-language-item'}`}
						style={{ background: '#fff' }}
					>
						<img id={`${option.language}-flag`} className="language-flag" src={option.src} alt="Language" />
						{text}
					</MenuItem>
				);
			})}
		</Menu>
	);
};

export default LanguageMenu;
