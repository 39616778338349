import { ICheckItem } from '../types/Reports';
import { INFLOW, OUTFLOW } from '../check/codes';

export const parseResultForValue = (item: ICheckItem, ifParent: boolean, parentCode?: string): any => {
	let retVal = {};
	if (Array.isArray(item.result)) {
		item.result.find((val) => {
			if (val.interval === '6m' || val.interval === '6M' || val.interval === 'all') {
				retVal = {
					code: item.code,
					result: val.mean.value
				};
				if (ifParent) {
					retVal = {
						...retVal,
						parent: parentCode
					};
				}
			}
		});
	}
	return retVal;
};

export const getValueFromType = (check: ICheckItem) => {
	if (check.newResult) return getValueForCheck(check.newResult);
	const retPrimitiveType = getValueForCheck(check.result);
	if (retPrimitiveType || retPrimitiveType === 0) return retPrimitiveType;
	else if (Array.isArray(check.result)) return parseResultForValue(check, false).result;
	return '';
};

const getValueForCheck = (result: any) => {
	if (typeof result === 'number' || typeof result === 'string') {
		return result;
	}
	return '';
};

export const checkIfEnumExistInHousehold = (object: ICheckItem) => {
	let retVal = ifExistInEnum(object, INFLOW);
	if (!retVal) retVal = ifExistInEnum(object, OUTFLOW);
	return retVal;
};

const ifExistInEnum = (object: ICheckItem, enums: any) => {
	if (enums[object.parent]) {
		if (Object.keys(enums[object.parent]).some((v) => v === object.code)) {
			return enums[object.parent][object.code];
		}
	} else {
		if (Object.keys(enums).some((v) => v === object.code)) {
			return enums[object.code];
		}
	}
	return false;
};
