import { set_brightness } from './utils';

const Theme = (color: string) => {
	const colorsOptions = {
		0: set_brightness(color, 80, 'increase'),
		300: set_brightness(color, 30, 'increase'),
		500: `${color}`,
		600: set_brightness(color, 30, 'decrease'),
		800: set_brightness(color, 80, 'decrease')
	};

	return {
		primary: {
			lighter: colorsOptions[0],
			light: colorsOptions[300],
			main: colorsOptions[500],
			dark: colorsOptions[600],
			darker: colorsOptions[800]
		}
	};
};

export default Theme;
