import { AppDispatch, GetState } from '../store';
import { changeStep, Step } from '../slices/step.slice';
import { isPersonaInformationPrefilled } from './complete-email-verification';

export const completePhoneNumberVerification = () => {
	return (dispatch: AppDispatch, getState: GetState): void => {
		const isPersonaInformationProvided = isPersonaInformationPrefilled(getState());
		const nextStep: Step = isPersonaInformationProvided
			? 'PERSONA_CONSENT_INPUT_STEP'
			: 'PERSONA_INFORMATION_INPUT_STEP';
		dispatch(changeStep(nextStep));
	};
};
