import { SVGIcon } from './SVGIcon';
import React from 'react';

export function CheckCircle() {
	return (
		<SVGIcon
			width="312.884"
			height="312.898"
			viewBox="0 0 312.884 312.898"
			size={{ x1: '-0.595', y1: '0.528', x2: '1.555', y2: '0.528' }}
		>
			<path
				id="Pfad_108"
				d="M220.11,14.545a14.223,14.223,0,0,1-11.576,25.983,128,128,0,1,0,75.9,120.842V144.432a14.222,14.222,0,0,1,28.344-1.664l.1,1.664v13.084A156.439,156.439,0,1,1,220.11,14.545Zm88.6,19.142a14.222,14.222,0,0,1,1.195,18.773l-1.18,1.337L166.508,196.156a14.222,14.222,0,0,1-18.773,1.18l-1.337-1.18-42.665-42.665a14.222,14.222,0,0,1,18.773-21.333l1.337,1.18,32.6,32.71L288.587,33.787a14.222,14.222,0,0,1,20.124-.1Z"
				transform="translate(0 0)"
				fill="url(#linear-gradient)"
			/>
		</SVGIcon>
	);
}
