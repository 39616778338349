export class Assessment {
	code!: string;
	value!: string;
	comment!: string;

	public constructor(init?: Partial<Assessment>) {
		Object.assign(this, init);
	}
}

export class UserAssessment {
	user: Assessment[] = [];

	public constructor(init: Partial<UserAssessment>) {
		Object.assign(this, init);
	}
}

class Residence {
	street!: string;
	houseNumber!: string;
	topNumber!: string;
	city!: string;
	postalCode!: string;
	country!: string;

	public constructor(init?: Partial<Residence>) {
		Object.assign(this, init);
	}
}

class Contacts {
	email!: string;
	phoneNumber!: string;

	public constructor(init?: Partial<Contacts>) {
		Object.assign(this, init);
	}
}

export interface IPersona {
	firstName: string;
	lastName: string;
	country: string;
	dateOfBirth: string;
	email: string;
	consentCollectingData: boolean;
	consentForwardingData: boolean;
	residence: Residence;
	contacts: Contacts;
	employerStatus: string;
	currentEmployer: string;
	canEditData: boolean;
	userAssessment: UserAssessment;
	platform: any;
}

export class Persona implements IPersona {
	firstName!: string;
	lastName!: string;
	country!: string;
	dateOfBirth!: string;
	email!: string;
	consentCollectingData!: boolean;
	consentForwardingData!: boolean;
	residence: Residence = new Residence();
	contacts: Contacts = new Contacts();
	employerStatus!: string;
	currentEmployer!: string;
	canEditData = false;
	platform = '';
	userAssessment: UserAssessment = new UserAssessment({
		user: []
	});

	public constructor(init?: Partial<Persona>) {
		this.consentForwardingData = false;
		this.consentCollectingData = false;
		Object.assign(this, init);
	}
}
