import React, { FC, useEffect, useState } from 'react';
import { CloudLightning } from '../../svg/CloudLightning';
import { Box, Tooltip, Typography } from '@mui/material';
import TextCustomization from '../../customizations/TextCustomization';
import { useAppDispatch } from '../../../app/hooks';
import { useAppTranslation } from '../../../i18n';
import { submitError } from '../../../app/thunks/submit-error';
import NewRelicAgent from '../../../newrelic';

interface Props {
	error: Error;
}

export const TechErrorScreen: FC<Props> = ({ error }) => {
	const dispatch = useAppDispatch();
	const { t } = useAppTranslation();

	const [reference, setReference] = useState<string>('');

	useEffect(() => {
		dispatch(submitError(error))
			.then((id) => {
				setReference(id);
			})
			.catch((error) => {
				NewRelicAgent.noticeError(error, {
					stack: error.stack
				});
			});
	}, []);

	return (
		<Box className="error-step">
			<Box className="error-step-message">
				<CloudLightning />
				<Box className="error-step-title">
					<TextCustomization
						id="TECHNICAL_ERROR_PAGE_TITLE"
						fallback={t('steps.technical-error.technical-error-title')}
					/>
				</Box>
				<TextCustomization
					id="TECHNICAL_ERROR_PAGE_INFO"
					fallback={t('steps.technical-error.technical-error-header')}
				/>
				<TextCustomization
					id="TECHNICAL_ERROR_PAGE_ADDITIONAL_INFORMATION"
					fallback={t('steps.technical-error.technical-error-subheader')}
				/>
				{reference && (
					<Tooltip
						title={
							<Typography sx={{ fontSize: '14px' }}>
								{t('steps.technical-error.issue-reference-tooltip')}
							</Typography>
						}
					>
						<Typography sx={{ pt: 3 }}>
							{t('steps.technical-error.issue-reference')}: <code id="issue-reference">{reference}</code>
						</Typography>
					</Tooltip>
				)}
			</Box>
		</Box>
	);
};
