import React, { MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ErrorObject from '../../types/ErrorsObject';
import { useTheme } from '@mui/material';
import { selectError } from '../../app/slices/errors.slice';
import { changeStep } from '../../app/slices/step.slice';

const TRANSLATION_PREFIX = 'technical-error-page';
const KLARNA_RESTART_PREFIX = `${TRANSLATION_PREFIX}.If you want to restart the login with Klarna please click here`;

const KlarnaAdditionalContent = () => {
	const errorsObject = useAppSelector(selectError) as ErrorObject;
	const dispatch = useAppDispatch();
	const theme = useTheme();
	const { t } = useTranslation();

	const refresh = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		dispatch(changeStep('BANK_STEP'));
	};

	return (
		<>
			<div className="error-step-text-klarna" style={{ color: theme.palette.primary.main }}>
				{errorsObject.message}
			</div>
			<div className="error-step-text-klarna-reload">
				<Trans t={t} i18nKey={KLARNA_RESTART_PREFIX} components={[<a className="link" onClick={refresh} />]} />
			</div>
		</>
	);
};

export default KlarnaAdditionalContent;
