import { AppDispatch, GetState } from '../store';
import { selectPersonaEmail } from '../slices/persona.slice';
import { selectValidationJWT } from '../slices/session.slice';
import { completeCheck } from './complete-check';
import { FlagsEnum, selectFlag } from '../slices/flags.slice';
import { ReportSliceState } from '../slices/report.slice';
import { changeStep, Step } from '../slices/step.slice';
import { isPersonaInformationPrefilled } from './complete-email-verification';

/**
 *	Initialize current step based on application state (redux store).
 * */
export const initializeCurrentStep = () => {
	return (dispatch: AppDispatch, getState: GetState) => {
		const state = getState();
		const reportStatus = state.reports.reportStatus;

		switch (reportStatus) {
			case 'NO_EMAIL':
				dispatch(initializeEmailInputStep());
				break;
			case 'EMAIL_VALIDATION_PENDING':
				dispatch(initializeEmailVerificationPendingStep());
				break;
			case 'PENDING':
				dispatch(initializePendingStep());
				break;
			case 'PROCESSING':
				dispatch(initializeProcessingStep());
				break;
			case 'READY':
				dispatch(initializeReadyStep());
				break;
			case 'ACCEPTED':
			case 'DISCARDED':
			case 'CANCELLED':
				dispatch(initializeFinalStep());
				break;
			case 'NO_PHONE_NUMBER':
				dispatch(initializePhoneNumberInputStep());
				break;
			case 'PHONE_NUMBER_PROVIDED':
				dispatch(initializePhoneNumberSmsDeliveryPendingStep());
				break;
			case 'PHONE_NUMBER_VALIDATION_PENDING':
				dispatch(initializePhoneNumberVerificationStep());
				break;
			case 'PHONE_NUMBER_SMS_DELIVERY_ERROR':
				dispatch(initializePhoneNumberSmsDeliveryErrorStep());
				break;
			default:
				throw new Error('Unsupported report status: ' + reportStatus);
		}
	};
};

export function initializeEmailInputStep() {
	return (dispatch: AppDispatch, getState: GetState) => {
		const state = getState();
		const email = selectPersonaEmail(state);
		if (email) {
			dispatch(changeStep('EMAIL_VERIFICATION_PENDING_STEP'));
		} else {
			dispatch(changeStep('EMAIL_INPUT_STEP'));
		}
	};
}

export function initializeEmailVerificationPendingStep() {
	return (dispatch: AppDispatch) => {
		dispatch(changeStep('EMAIL_VERIFICATION_PENDING_STEP'));
	};
}

export function initializePendingStep() {
	return (dispatch: AppDispatch, getState: GetState) => {
		const state = getState();
		const validationJWT = selectValidationJWT(state);

		if (validationJWT !== '') {
			dispatch(changeStep('EMAIL_VERIFIED_STEP'));
		} else {
			const isPersonaInformationProvided = isPersonaInformationPrefilled(state);
			dispatch(
				changeStep(
					isPersonaInformationProvided ? 'PERSONA_CONSENT_INPUT_STEP' : 'PERSONA_INFORMATION_INPUT_STEP'
				)
			);
		}
	};
}

export function initializeProcessingStep() {
	return (dispatch: AppDispatch, getState: GetState) => {
		const state = getState();
		const bankProcessFinished = state.ui.bankProcessFinished;

		if (bankProcessFinished) {
			dispatch(changeStep('WAITING_CHECK_RESULTS_STEP'));
		} else {
			dispatch(changeStep('BANK_STEP'));
		}
	};
}

export function initializeFinalStep() {
	return (dispatch: AppDispatch) => {
		dispatch(changeStep('CHECK_FINISHED_STEP'));
	};
}

export function initializePhoneNumberInputStep() {
	return (dispatch: AppDispatch, getState: GetState) => {
		const state = getState();
		const validationJWT = selectValidationJWT(state);

		if (validationJWT) {
			dispatch(changeStep('EMAIL_VERIFIED_STEP'));
		} else {
			dispatch(changeStep('PHONE_NUMBER_INPUT_STEP'));
		}
	};
}

export function initializePhoneNumberVerificationStep() {
	return (dispatch: AppDispatch) => {
		dispatch(changeStep('PHONE_NUMBER_VERIFICATION_CODE_INPUT_STEP'));
	};
}

export function initializePhoneNumberSmsDeliveryPendingStep() {
	return (dispatch: AppDispatch) => {
		dispatch(changeStep('PHONE_NUMBER_SMS_DELIVERY_PENDING_STEP'));
	};
}

export function initializePhoneNumberSmsDeliveryErrorStep() {
	return (dispatch: AppDispatch) => {
		dispatch(changeStep('PHONE_NUMBER_SMS_DELIVERY_ERROR_STEP'));
	};
}

export function initializeReadyStep() {
	return (dispatch: AppDispatch, getState: GetState) => {
		const state = getState();
		const reports = state.reports;
		const reportEmpty = isReportEmpty(reports);

		if (reportEmpty) {
			dispatch(completeCheck('accepted'));
			return;
		}

		const isShowResultsFeatureActive = selectFlag(state, FlagsEnum.SHOW_RESULT);
		const isAssessmentFeatureActive = selectFlag(state, FlagsEnum.ASSESSMENT_USER_INPUT);
		if (isShowResultsFeatureActive) {
			const nextStep: Step = isAssessmentFeatureActive ? 'CHECK_RESULTS_STEP' : 'CHECK_RESULTS_SUMMARY_STEP';
			dispatch(changeStep(nextStep));
		} else {
			dispatch(changeStep('CHECK_FINISHED_STEP'));
		}
	};
}

function isReportEmpty(reports: ReportSliceState) {
	const { namecheck, household, kontoCheck } = reports;
	return namecheck === undefined && household.length === 0 && kontoCheck.length === 0;
}
