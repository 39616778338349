import { WithChildren } from '../custom/SingleSpinner';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectAppStatus } from '../../app/slices/app.slice';
import Loading from '../custom/Loading';
import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { initializeApp } from '../../app/thunks/app-init';

export const InitializationBoundary = ({ children }: WithChildren) => {
	const status = useAppSelector(selectAppStatus);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(initializeApp());
	}, []);

	if (status === 'NOT_INITIALIZED') {
		return (
			<Box className="App-wrapper" sx={boxSX}>
				<Loading />
			</Box>
		);
	}

	return <>{children}</>;
};

const boxSX = { justifyContent: 'center' };
