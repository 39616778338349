import React, { FC, useState } from 'react';
import { PERSONAL_CONSENT_PAGE_PREFIX } from '../../../utils/translationPagePrefix';
import { Box } from '@mui/material';
import { useAppTranslation } from '../../../i18n';
import { ConsentBox } from './ConsentBox';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
	personaActions,
	PersonaInformation,
	selectConsentCollectingData,
	selectConsetForwardingData,
	selectPersonaInformation
} from '../../../app/slices/persona.slice';
import { ModalEnum, uiActions } from '../../../app/slices/ui.slice';
import { CustomizationKey } from '../../../app/slices/customizations.slice';
import { Header } from './Header';
import { SubmitButton } from '../shared/SubmitButton';
import ConsentText from '../ConsentText';
import { putPersonaData } from '../../../app/thunks/put-persona-data';
import moment from 'moment';

const CONSENT_COLLECTION_KEY: CustomizationKey = 'CONSENT_COLLECTION_AND_PROCESSING_SHORT';
const CONSENT_FORWARDING_KEY: CustomizationKey = 'CONSENT_FORWARDING_AND_RESULTS_SHORT';

export const PersonaConsentStep: FC = () => {
	const dispatch = useAppDispatch();
	const { t } = useAppTranslation();

	const persona = useAppSelector(selectPersonaInformation);
	const isCollectingChecked = useAppSelector(selectConsentCollectingData);
	const isForwardingChecked = useAppSelector(selectConsetForwardingData);

	const [collectingChecked, setCollectingChecked] = useState<boolean>(isCollectingChecked);
	const [forwardingChecked, setForwardingChecked] = useState<boolean>(isForwardingChecked);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const handleChangeCollectingDataConsent = (event: any) => {
		setCollectingChecked(event.target.checked);
		dispatch(personaActions.setPersonaCollectingDataConsent(event.target.checked));
	};

	const handleChangeForwardingDataConsent = (event: any) => {
		setForwardingChecked(event.target.checked);
		dispatch(personaActions.setPersonaForwardingDataConsent(event.target.checked));
	};

	const handlerOpenConsentCollectingDataModal = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		dispatch(uiActions.setCollectingDataConsent(true));
		dispatch(uiActions.setCollectingForwardDataConsent(false));
		dispatch(uiActions.toggleModal(ModalEnum.CONSENT_MODAL));
	};
	const handlerOpenConsentForwardingDataModal = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		dispatch(uiActions.setCollectingForwardDataConsent(true));
		dispatch(uiActions.setCollectingDataConsent(false));
		dispatch(uiActions.toggleModal(ModalEnum.CONSENT_MODAL));
	};

	const onCollectionClick = () => {
		dispatch(personaActions.setPersonaCollectingDataConsent(!collectingChecked));
		setCollectingChecked((prev) => !prev);
	};

	const onForwardingClick = () => {
		dispatch(personaActions.setPersonaForwardingDataConsent(!forwardingChecked));
		setForwardingChecked((prev) => !prev);
	};

	const handleSubmit = async () => {
		try {
			setSubmitting(true);
			const data = createPersonaData(persona);
			await dispatch(putPersonaData(data));
		} finally {
			setSubmitting(false);
		}
	};

	const isSubmitDisabled = !collectingChecked || !forwardingChecked;
	return (
		<Box id="persona-consent-step">
			<Header />
			<Box className="personal-consent">
				<div className="title">
					<h3>{t('personal-consent-page.consents')}</h3>
				</div>
				<ConsentBox
					id="collecting"
					onClick={onCollectionClick}
					onButtonClick={handlerOpenConsentCollectingDataModal}
					onCheckboxChange={handleChangeCollectingDataConsent}
					customizationKey={CONSENT_COLLECTION_KEY}
					header={t(`${PERSONAL_CONSENT_PAGE_PREFIX}.collection & processing of you data`)}
					checked={collectingChecked}
				/>
				<ConsentBox
					id="forwarding"
					onClick={onForwardingClick}
					onButtonClick={handlerOpenConsentForwardingDataModal}
					onCheckboxChange={handleChangeForwardingDataConsent}
					customizationKey={CONSENT_FORWARDING_KEY}
					header={t(`${PERSONAL_CONSENT_PAGE_PREFIX}.forwarding of your data & results`)}
					checked={forwardingChecked}
				/>
			</Box>
			<SubmitButton
				disabled={isSubmitDisabled}
				submitting={submitting}
				text={t('button.start')}
				onSubmit={handleSubmit}
			/>
			<ConsentText />
		</Box>
	);
};

const createPersonaData = (persona: PersonaInformation): PersonaInformation => {
	const dateOfBirth = moment(persona.dateOfBirth, 'DD.MM.YYYY').format('YYYY-MM-DD');
	return {
		...persona,
		dateOfBirth
	};
};
