import { TechErrorService } from '../../services/TechErrorService';
import { isAxiosError } from 'axios';
import { serializeAxiosRequest, serializeAxiosResponse } from '../../utils/serialization';

export const submitError = (error: Error) => {
	return async (): Promise<string> => {
		const data = createErrorReportData(error);
		return await new TechErrorService().sendErrorReport(data, getCheckToken());
	};
};

export const createErrorReportData = (error: Error) => {
	const network = getErrorNetworkData(error);
	const url = window.location.href;
	const userAgent = window.navigator.userAgent;
	const errorData = createErrorData(error);

	return {
		error: errorData,
		url,
		userAgent,
		request: network?.request,
		response: network?.response
	};
};

const createErrorData = (error: Error): Error => {
	return Object.getOwnPropertyNames(error)
		.filter((name) => !['request', 'response', 'config'].includes(name))
		.reduce((acc, key) => {
			const _key = key as keyof Error;
			acc[_key] = error[_key] as string;
			return acc;
		}, {} as Error);
};

const getErrorNetworkData = (error: Error): undefined | { request?: object; response?: object } => {
	if (!isAxiosError(error)) return undefined;

	return {
		request: error.config && JSON.parse(serializeAxiosRequest(error.config)),
		response: error.response && JSON.parse(serializeAxiosResponse(error.response))
	};
};

export function getCheckToken(): string {
	const pathname = window.location.pathname;
	// pathname contains an initial "/" followed by the path of the URL (token)
	return pathname.substring(1);
}
