import { ICheckItem } from '../../types/Reports';
import { CheckAccordionRow } from './CheckAccordionRow';
import React from 'react';
import { SCORE_LIGHT } from '../../check/codes';
import { useTranslation } from 'react-i18next';
import { FINAL_PAGE_PREFIX, RESULTS_PAGE_PREFIX } from '../../utils/translationPagePrefix';

interface LightOnlyComponent {
	indicatorColor: string;
	title: string;
	onInfoClick(): void;
}

export const LightOnlyComponent = React.memo(({ indicatorColor, onInfoClick, title }: LightOnlyComponent) => {
	return (
		<CheckAccordionRow
			openable={false}
			title={title}
			displayComment={false}
			displayDetails={false}
			displayInfo={true}
			onInfoClick={onInfoClick}
			style={{ fontWeight: 'normal', borderBottom: 'none' }}
			indicatorColor={indicatorColor}
		/>
	);
});

interface LightOnlyWrapper {
	light: ICheckItem;
	onInfoClick(): void;
}

export const LightsOnlyWrapper = React.memo(({ light, onInfoClick }: LightOnlyWrapper) => {
	const { t } = useTranslation();

	const { indicatorColor, text } = getLightsTextAndIndicatorColor(light, t);

	return <LightOnlyComponent indicatorColor={indicatorColor} title={text} onInfoClick={onInfoClick} />;
});

const ERROR_INDICATOR_COLOR = 'black';
const CODES_PREFIX = `codes`;
const LIGHTS_PREFIX = `${RESULTS_PAGE_PREFIX}.lights`;
const translationKeyRisk = `${LIGHTS_PREFIX}.risk`;
const translationErrorKey = `${FINAL_PAGE_PREFIX}.not available`;

type LightsAndText = {
	light: string;
	translationKey: string;
};

const RiskMap: Record<SCORE_LIGHT, LightsAndText> = {
	[SCORE_LIGHT.HIGH_RISK]: { light: 'red', translationKey: 'high-risk' },
	[SCORE_LIGHT.MEDIUM_RISK]: { light: 'yellow', translationKey: 'normal-risk' },
	[SCORE_LIGHT.LOW_RISK]: { light: 'green', translationKey: 'low-risk' }
};

export function getLightsTextAndIndicatorColor(light: ICheckItem, t: Function) {
	let indicatorColor: string;
	let text: string;

	if (light.error) {
		indicatorColor = ERROR_INDICATOR_COLOR;
		text = getErrorLightsText(t);
	} else {
		const scoreLight = (light.result as any).risk_level;
		const [title, scorelight] = getRiskTextAndLight(scoreLight, t);
		text = title;
		indicatorColor = scorelight;
	}
	return { indicatorColor, text };
}

function getErrorLightsText(t: Function) {
	const lightsTranslation = t(`${CODES_PREFIX}.LIGHTS`);
	const notAvailableTranslation = t(translationErrorKey);

	return `${lightsTranslation}: ${notAvailableTranslation}`;
}

function getRiskTextAndLight(risk: SCORE_LIGHT, t: any): [string, string] {
	const lightsAndText = RiskMap[risk];
	const riskTranslationKey = lightsAndText.translationKey;

	const translationKeyRiskLevel = getTranslationKeyForRiskLevel(riskTranslationKey);
	const riskLevelTranslated = t(translationKeyRiskLevel);
	const riskTranslated = t(translationKeyRisk);

	const text = composeRiskTranslatedText(riskLevelTranslated, riskTranslated);

	return [text, lightsAndText.light];
}

function getTranslationKeyForRiskLevel(riskTranslationKey: string) {
	return `${LIGHTS_PREFIX}.${riskTranslationKey}`;
}

function composeRiskTranslatedText(riskLevelTranslated: string, riskTranslated: string) {
	return `${riskLevelTranslated} ${riskTranslated}`;
}
