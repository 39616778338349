import { useEffect } from 'react';
import useCustomization from '../../hooks/useCustomization';
import { CustomizationKey } from '../../app/slices/customizations.slice';

const useWindowTitle = (customizationKey: CustomizationKey) => {
	const customization = useCustomization(customizationKey);

	useEffect(() => {
		if (customization) {
			document.title = customization;
		}
	}, [customization]);
};

export default useWindowTitle;
