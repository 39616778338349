import React from 'react';
import { OptionsProps, TooltipCustomized } from './CheckAccordionRow';
import infoIcon from '../../assets/images/icons/info.svg';
import commentIcon from '../../assets/images/icons/message-square.svg';
import commentIconFull from '../../assets/images/icons/message-square-full.svg';
import { ItemStatus } from '../../types/constants';
import { useTranslation } from 'react-i18next';
import { RESULTS_PAGE_PREFIX } from '../../utils/translationPagePrefix';
import { useAppSelector } from '../../app/hooks';
import { FlagsEnum, selectFlag } from '../../app/slices/flags.slice';

// Status color map, for each status there is corresponding color
const StatusColorMap: Map<ItemStatus, string> = new Map([
	[ItemStatus.VERIFIED, '#69E2AB'],
	[ItemStatus.USER_CHANGED, '#0082E1'],
	[ItemStatus.UNVERIFIED, '#FE7E28']
]);

type CheckAccordionItemProps = OptionsProps & {
	title: string | React.ReactElement;
	subtitle?: string;
	commentFull?: boolean;
	onInfoClick?: () => void;
	onDetailClick?: () => void;
	onCommentClick?: () => void;
	style?: object;
	status?: ItemStatus;
	statusStyle?: object;
};

const translationKey = `${RESULTS_PAGE_PREFIX}.Change`;

export const CheckAccordionItem = ({
	title,
	subtitle,
	displayDetails = true,
	displayInfo = true,
	displayComment = true,
	commentFull = false,
	onCommentClick,
	onDetailClick,
	onInfoClick,
	statusStyle = {},
	status = ItemStatus.VERIFIED
}: CheckAccordionItemProps) => {
	const { t } = useTranslation();
	const userAssessmentFlag = useAppSelector((state) => selectFlag(state, FlagsEnum.ASSESSMENT_USER_INPUT));

	const onDetailsClick = () => {
		if (onDetailClick) onDetailClick();
	};

	const onInfosClick = () => {
		if (onInfoClick) onInfoClick();
	};

	const onCommentsClick = () => {
		if (onCommentClick) onCommentClick();
	};

	return (
		<div
			className={`check-accordion-item ${subtitle ? '' : 'check-acc-item-without-sub'}`}
			id={'check-item-' + title}
		>
			<div
				className="item-content"
				style={!displayDetails && !displayInfo && !displayComment ? { width: '90%' } : {}}
			>
				<div className="item-content-status">
					<div
						className="status"
						style={{
							...statusStyle,
							background: `${StatusColorMap.get(status)}`
						}}
					></div>
				</div>
				<div className="item-content-content">
					{subtitle ? (
						<React.Fragment>
							<div className="row1">
								<span>{title}</span>
							</div>
							<div className="row2">
								<span>{subtitle}</span>
							</div>
						</React.Fragment>
					) : (
						<React.Fragment>
							<div className="subtitle-solo-container">{title}</div>
						</React.Fragment>
					)}
				</div>
			</div>
			<div className="item-options">
				<div className="item-options-button-container">
					<div className="acc-details">
						{displayDetails && userAssessmentFlag && (
							<div className="column-checkbox-button">
								<button onClick={onDetailsClick}>{t(translationKey)}</button>
							</div>
						)}
					</div>
				</div>
				<div className="item-options-info">
					{displayInfo === true ? (
						<TooltipCustomized>
							<img src={infoIcon} alt="Info" className="acc-icon" onClick={onInfosClick} />
						</TooltipCustomized>
					) : (
						<div className="acc-icon" />
					)}
				</div>
				<div className="item-options-comment">
					{displayComment === true ? (
						<img
							src={commentFull ? commentIconFull : commentIcon}
							alt="Comment"
							className="acc-icon"
							onClick={onCommentsClick}
						/>
					) : (
						<div className="acc-icon" />
					)}
				</div>
			</div>
		</div>
	);
};

export default React.memo(CheckAccordionItem);
