import React from 'react';

export enum EnumErrorType {
	REACT_ERROR,
	KLARNA_ERROR,
	APP_ERROR,
	CHECK_EXPIRED,
	VALIDATION_TOKEN_NO_LONGER_VALID_ERROR,
	LOAD_ISSUES_ERROR
}

export interface ErrorObjectContent {
	title: string;
	texts: Array<string>;
	components?: Array<React.ReactElement>;
}

export default interface ErrorObject {
	type: EnumErrorType;
	object: Object;
	message: string;
}
