import React from 'react';
import { EnumErrorType } from '../../types/ErrorsObject';
import { CustomizationKey } from '../../app/slices/customizations.slice';
import Markdown from 'react-markdown';
import useCustomizations from '../../hooks/useCustomizations';

interface ErrorContentTextCustomizationProps {
	errorType: EnumErrorType;
}

const getErrorContentTextCustomizationKeys = (errorType: EnumErrorType): CustomizationKey[] => {
	switch (errorType) {
		case EnumErrorType.REACT_ERROR:
		case EnumErrorType.APP_ERROR:
			return ['TECHNICAL_ERROR_PAGE_INFO', 'TECHNICAL_ERROR_PAGE_ADDITIONAL_INFORMATION'];
		case EnumErrorType.CHECK_EXPIRED:
			return ['CHECK_EXPIRED_ERROR_PAGE_INFO', 'CHECK_EXPIRED_ERROR_PAGE_ADDITIONAL_INFORMATION'];
		case EnumErrorType.VALIDATION_TOKEN_NO_LONGER_VALID_ERROR:
			return [
				'VALIDATION_TOKEN_EXPIRED_ERROR_PAGE_INFO',
				'VALIDATION_TOKEN_EXPIRED_ERROR_PAGE_ADDITIONAL_INFORMATION'
			];
		case EnumErrorType.LOAD_ISSUES_ERROR:
			return ['LOAD_ISSUES_NOTICE_PAGE_INFO', 'LOAD_ISSUES_NOTICE_PAGE_ADDITIONAL_INFO'];
		case EnumErrorType.KLARNA_ERROR:
			return ['KLARNA_LOGIN_ERROR_PAGE_INFORMATION'];
		default:
			throw new Error('');
	}
};
export const ErrorContentTextCustomization = ({ errorType }: ErrorContentTextCustomizationProps) => {
	const errorContentCustomizationTextsKeys = getErrorContentTextCustomizationKeys(errorType);
	const errorContentCustomizationTexts = useCustomizations(errorContentCustomizationTextsKeys);

	return (
		<React.Fragment>
			{errorContentCustomizationTexts.map((text, index) => (
				<div key={index} style={{ padding: '3%' }}>
					<Markdown>{text}</Markdown>
				</div>
			))}
		</React.Fragment>
	);
};
