import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export type Language = 'en' | 'de';

export type PopupCustomizationKey =
	| 'CONDITIONS'
	| 'PRIVACY'
	| 'IMPRINT'
	| 'FAQs'
	| 'CONSENT_COLLECTION_AND_PROCESSING_LONG'
	| 'CONSENT_FORWARDING_AND_RESULTS_LONG'
	| 'CONSENT_COLLECTION_AND_PROCESSING_SHORT'
	| 'CONSENT_FORWARDING_AND_RESULTS_SHORT'
	| 'COMPLETE_CHECK_TEXT'
	| 'RESULTS_SUMMARY_COMPLETE_CHECK_TEXT'
	| 'BLANK_CHECK_CONSENT_TEXT'
	| 'ENTER_COMMENT_TEXT'
	| 'POPUP_MAIN_TITLE'
	| 'POPUP_BROWSER_TITLE'
	| 'POPUP_MAIN_LOGO'
	| 'BLANK_CHECK_EMAIL_INPUT_PAGE_TITLE'
	| 'BLANK_CHECK_EMAIL_INPUT_PAGE_SUBTITLE'
	| 'BLANK_CHECK_EMAIL_SENT_PAGE_TITLE'
	| 'BLANK_CHECK_EMAIL_SENT_PAGE_SUBTITLE'
	| 'BLANK_CHECK_EMAIL_SENT_PAGE_SECTION'
	| 'BLANK_CHECK_EMAIL_SENT_PAGE_SUBSECTION'
	| 'BLANK_CHECK_EMAIL_VERIFIED_PAGE_TITLE'
	| 'BLANK_CHECK_EMAIL_VERIFIED_PAGE_SUBTITLE'
	| 'PERSONAL_CONSENT_FOOTER_TEXT'
	| 'TRANSMISSION_STARTED_DISPLAY_TEXT'
	| 'RESULTS_PAGE_TITLE'
	| 'HELP_PAGE_SECTION_1_TITLE'
	| 'HELP_PAGE_SECTION_1_CONTENT'
	| 'HELP_PAGE_SECTION_2_TITLE'
	| 'HELP_PAGE_SECTION_2_CONTENT_1'
	| 'HELP_PAGE_SECTION_2_CONTENT_2'
	| 'HELP_PAGE_SECTION_2_CONTENT_3'
	| 'HELP_PAGE_SECTION_3_TITLE'
	| 'HELP_PAGE_SECTION_3_CONTENT_1'
	| 'HELP_PAGE_SECTION_3_CONTENT_2'
	| 'HELP_PAGE_SECTION_3_CONTENT_3'
	| 'TECHNICAL_ERROR_PAGE_TITLE'
	| 'TECHNICAL_ERROR_PAGE_INFO'
	| 'TECHNICAL_ERROR_PAGE_ADDITIONAL_INFORMATION'
	| 'CHECK_EXPIRED_ERROR_PAGE_TITLE'
	| 'CHECK_EXPIRED_ERROR_PAGE_INFO'
	| 'CHECK_EXPIRED_ERROR_PAGE_ADDITIONAL_INFORMATION'
	| 'VALIDATION_TOKEN_EXPIRED_ERROR_PAGE_TITLE'
	| 'VALIDATION_TOKEN_EXPIRED_ERROR_PAGE_INFO'
	| 'VALIDATION_TOKEN_EXPIRED_ERROR_PAGE_ADDITIONAL_INFORMATION'
	| 'KLARNA_LOGIN_ERROR_PAGE_TITLE'
	| 'KLARNA_LOGIN_ERROR_PAGE_INFORMATION'
	| 'RESULTS_SUMMARY_PAGE_TITLE'
	| 'ACCEPTED_PAGE_TITLE'
	| 'ACCEPTED_PAGE_INFO'
	| 'ACCEPTED_PAGE_ADDITIONAL_INFO'
	| 'DISCARDED_PAGE_TITLE'
	| 'DISCARDED_PAGE_INFO'
	| 'DISCARDED_PAGE_ADDITIONAL_INFO'
	| 'ACCEPTED_RELOADED_PAGE_TITLE'
	| 'ACCEPTED_RELOADED_PAGE_INFO'
	| 'ACCEPTED_RELOADED_PAGE_ADDITIONAL_INFO'
	| 'DISCARDED_RELOADED_PAGE_TITLE'
	| 'DISCARDED_RELOADED_PAGE_INFO'
	| 'DISCARDED_RELOADED_PAGE_ADDITIONAL_INFO'
	| 'DISCARDED_RELOADED_PAGE_ADDITIONAL_SECTION'
	| 'CANCELLED_PAGE_TITLE'
	| 'CANCELLED_PAGE_INFO'
	| 'CANCELLED_PAGE_ADDITIONAL_INFO'
	| 'CANCELLED_PAGE_ADDITIONAL_SECTION'
	| 'EMAIL_VALIDATION_FINISHED_PAGE_TITLE'
	| 'EMAIL_VALIDATION_FINISHED_PAGE_SUBTITLE'
	| 'XS2A_PRIVACY'
	| 'XS2A_TERMS'
	| 'LOAD_ISSUES_NOTICE_PAGE_TITLE'
	| 'LOAD_ISSUES_NOTICE_PAGE_INFO'
	| 'LOAD_ISSUES_NOTICE_PAGE_ADDITIONAL_INFO'
	| 'PHONE_NUMBER_INPUT_PAGE_HEADER'
	| 'PHONE_NUMBER_INPUT_PAGE_SUBHEADER'
	| 'PHONE_NUMBER_INPUT_PAGE_ADDITIONAL_INFO'
	| 'WAITING_SMS_VERIFICATION_CODE_DELIVERY_PAGE_HEADER'
	| 'WAITING_SMS_VERIFICATION_CODE_DELIVERY_PAGE_SUBHEADER'
	| 'PHONE_NUMBER_VERIFICATION_CODE_INPUT_STEP_HEADER'
	| 'PHONE_NUMBER_VERIFICATION_CODE_INPUT_STEP_CONTENT_1'
	| 'PHONE_NUMBER_VERIFICATION_CODE_INPUT_STEP_CONTENT_2'
	| 'PHONE_NUMBER_VERIFIED_SCREEN_HEADER'
	| 'PHONE_NUMBER_VERIFIED_SCREEN_SUBHEADER'
	| 'PHONE_NUMBER_VERIFIED_SCREEN_ADDITIONAL_INFO';

export type BankSdkCustomizationKey =
	| 'FINCREDIBLE_XS2A_LOADING_TEXT'
	| 'BANK_SELECTION_PAGE_TITLE'
	| 'FORWARDING_PAGE_SUBTITLE'
	| 'ACCOUNT_SELECTION_TITLE'
	| 'ACCOUNT_SELECTION_SUBTITLE'
	| 'FINCREDIBLE_XS2A_ERROR_PAGE_TITLE'
	| 'FINCREDIBLE_XS2A_ERROR_PAGE_INFO'
	| 'FINCREDIBLE_XS2A_ERROR_PAGE_SUPPORT_SECTION'
	| 'FINCREDIBLE_XS2A_ERROR_PAGE_SUPPORT_SUBSECTION';

export type CustomizationKey = PopupCustomizationKey | BankSdkCustomizationKey;
export type CustomizationValue = string | null;

export type CustomizationsRecord = Record<CustomizationKey, CustomizationValue>;

export type BankSdkCustomizations = Record<Language, Record<BankSdkCustomizationKey, string | null>>;

export type CustomizationState = {
	[K in Language]: CustomizationsRecord | undefined;
};

const initialState: CustomizationState = {
	en: undefined,
	de: undefined
};

const customizationsSlice = createSlice({
	name: 'customizations',
	initialState,
	reducers: {
		setCustomizations: (state, action: PayloadAction<{ language: Language; data: CustomizationsRecord }>) => {
			const { language, data } = action.payload;
			state[language] = data;
		}
	}
});
export const customizationsActions = customizationsSlice.actions;

export const { setCustomizations } = customizationsSlice.actions;

export const selectCustomizations = (state: RootState): CustomizationState => state.customizations;

const pickOnlyBankSdkKeys = (customizations: CustomizationsRecord) => {
	const filteredCustomizations: Record<string, string | null> = {};
	BANK_SDK_CUSTOMIZATION_KEYS.forEach((key) => {
		filteredCustomizations[key] = customizations[key];
	});

	return filteredCustomizations;
};

export const selectBankSdkCustomizations = (state: RootState): BankSdkCustomizations => {
	const bankSdkCustomizations: BankSdkCustomizations = <BankSdkCustomizations>{};

	const customizations = state.customizations;
	Object.keys(customizations).forEach((language) => {
		const languageKey = language as Language;
		const languageCustomizations = customizations[languageKey];
		if (languageCustomizations) {
			bankSdkCustomizations[languageKey] = pickOnlyBankSdkKeys(languageCustomizations);
		}
	});

	return bankSdkCustomizations;
};

export const selectCustomization = (key: CustomizationKey, language: Language) => {
	return (state: RootState): CustomizationValue => {
		const customizations = state.customizations;
		const record = customizations[language];
		if (!record) return null;
		return record[key];
	};
};

export const selectCustomizationForKeyAndLanguage =
	(key: CustomizationKey, language: Language) =>
	(state: RootState): string => {
		const customizations = state.customizations[language];
		return customizations ? customizations[key] || '' : '';
	};

export default customizationsSlice;

const BANK_SDK_CUSTOMIZATION_KEYS: Array<BankSdkCustomizationKey> = [
	'FINCREDIBLE_XS2A_LOADING_TEXT',
	'BANK_SELECTION_PAGE_TITLE',
	'FORWARDING_PAGE_SUBTITLE',
	'ACCOUNT_SELECTION_TITLE',
	'ACCOUNT_SELECTION_SUBTITLE',
	'FINCREDIBLE_XS2A_ERROR_PAGE_TITLE',
	'FINCREDIBLE_XS2A_ERROR_PAGE_INFO',
	'FINCREDIBLE_XS2A_ERROR_PAGE_SUPPORT_SECTION',
	'FINCREDIBLE_XS2A_ERROR_PAGE_SUPPORT_SUBSECTION'
];
