import React from 'react';
import { ICheckItem } from '../../types/Reports';
import { ScoreWithLightsComponent } from './ScoreWithLightsComponent';
import { useTranslation } from 'react-i18next';
import { getScoreTitle } from './ScoreOnlyComponent';
import { getLightsTextAndIndicatorColor } from './LightOnlyComponent';

interface ScoreWithLightsWrapperProps {
	score: ICheckItem;
	light: ICheckItem;
	onInfoClick(): void;
}

const ScoreWithLightsWrapper = React.memo(({ light, score, onInfoClick }: ScoreWithLightsWrapperProps) => {
	const { t } = useTranslation();

	const scoreText = getScoreTitle(score, t);
	const { text: lightsText, indicatorColor } = getLightsTextAndIndicatorColor(light, t);

	return (
		<ScoreWithLightsComponent
			lightColor={indicatorColor}
			scoreText={scoreText}
			lightText={lightsText}
			onInfoClick={onInfoClick}
		/>
	);
});

export default ScoreWithLightsWrapper;
