import React, { MouseEventHandler } from 'react';
import Markdown from 'react-markdown';

interface DiscardResultsFooterMarkdownProps {
	content: string;
	onClick: MouseEventHandler<HTMLAnchorElement>;
}
const DiscardResultsFooterMarkdown: React.FC<DiscardResultsFooterMarkdownProps> = ({ content, onClick }) => {
	const customComponents = {
		code: ({ inline, node, ...props }: any) => {
			const codeBlockStyle = {
				textDecoration: 'underline',
				cursor: 'pointer',
				fontFamily: 'Roboto, sans-serif'
			};
			return <code style={codeBlockStyle} {...props} onClick={onClick} />;
		}
	};

	return (
		<Markdown className="custom-markdown" components={customComponents}>
			{content}
		</Markdown>
	);
};

export default DiscardResultsFooterMarkdown;
