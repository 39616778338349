type SuccessfullValidation = { passed: true };
type ErrorValidation = { passed: false; errorMessage: string };
// discriminant union
type ValidationResult = SuccessfullValidation | ErrorValidation;

export interface IValidator<T> {
	name: string;

	validate(value: T): ValidationResult;
}

export class RegExValidator implements IValidator<string> {
	private regex: RegExp;
	private readonly errorMessage: string;
	public name: string;

	public constructor(re: string | RegExp, errorMessage: string, name?: string) {
		if (typeof re === 'string') this.regex = new RegExp(re);
		else this.regex = re;
		this.errorMessage = errorMessage;
		this.name = name || 'validator';
	}

	public validate(value: string): ValidationResult {
		const result = this.regex.test(value);
		if (result) return { passed: true };
		else
			return {
				passed: false,
				errorMessage: this.errorMessage
			};
	}
}

const NUMBERS_ONLY_REGEX = /^[0-9-]*$/;
export const NumbersValidator = new RegExValidator(NUMBERS_ONLY_REGEX, 'Numbers only', 'NumberValidator');

const CHARACTERS_ONLY_REGEX = /^[a-zA-Z\s\-\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u01BF\u01C4-\u02B8\u0391-\u03FF]+$/;
const CHARACTERS_ONLY_REGEX_MESSAGE = 'Input must contain only letters and hyphen';
export const TextValidator = new RegExValidator(CHARACTERS_ONLY_REGEX, CHARACTERS_ONLY_REGEX_MESSAGE, 'TextValidator');

const NUMERIC_ALPHABETIC_ONLY_REGEX = '^[A-Za-z\\s0-9]*$';
const NUMERIC_ALPHABETIC_ONLY_REGEX_MESSAGE = 'Input must contain only letters and numbers';
export const NumericAlphabeticValidator = new RegExValidator(
	NUMERIC_ALPHABETIC_ONLY_REGEX,
	NUMERIC_ALPHABETIC_ONLY_REGEX_MESSAGE,
	'NumericAlphabeticValidator'
);

const MIN_CHARACTERS_REGEX = (min: number) =>
	`^[A-Za-z\\s\\-\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u01BF\u01C4-\u02B8\u0391-\u03FF]{${min},}$`;
const MIN_CHARACTERS_REGEX_MESSAGE = (min: number) => `Input must contain at least ${min} characters`;
export const MinCharactersValidator = (min: number) =>
	new RegExValidator(MIN_CHARACTERS_REGEX(min), MIN_CHARACTERS_REGEX_MESSAGE(min), 'MinCharactersValidator');

const EMAIL_REGEX =
	/^(([^<>()[\]\\. ,;:\s@"]+(\.[^<>()[\]\\. ,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/;
const EMAIL_REGEX_MESSAGE = 'Invalid email format. Please try again.';
export const EmailValidator = new RegExValidator(EMAIL_REGEX, EMAIL_REGEX_MESSAGE, 'EmailValidator');
