import { HttpStatus } from '../constants/http';
import { AxiosError } from 'axios';

export abstract class Service {
	protected hasResponseStatus(error: unknown, status: HttpStatus): boolean {
		if (!(error instanceof AxiosError)) return false;
		const response = error.response;
		if (!response) return false;
		return response.status === status;
	}
}
