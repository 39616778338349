import React from 'react';
import { WithChildren } from '../custom/SingleSpinner';
import Loading from '../custom/Loading';
import '../../i18n';

type I18NextProviderProps = WithChildren;

const I18NProvider = ({ children }: I18NextProviderProps) => {
	return <React.Suspense fallback={<Loading />}>{children}</React.Suspense>;
};

export default I18NProvider;
