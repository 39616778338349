import { FormControl, InputLabel, MenuItem, Select as MuiSelect, SelectChangeEvent } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import chevron from '../../assets/images/icons/chevron-down.svg';

export interface SelectOption {
	value: string;
	label: ReactNode;
}

interface Props {
	id: string;
	name: string;
	value: string;
	label: string;
	options: Array<SelectOption>;
	disabled?: boolean;
	onChange(value: string): void;
}

export const Select: FC<Props> = ({ id, name, value, label, options, onChange, disabled = false }) => {
	const handleChange = (event: SelectChangeEvent): void => {
		const value = event.target.value;
		onChange(value);
	};

	return (
		<FormControl variant="filled" className="controlled-input select-component" disabled={false} sx={formSX}>
			<InputLabel sx={labelSX}>{label}</InputLabel>
			<MuiSelect
				id={id}
				name={name}
				value={value}
				label={label}
				onChange={handleChange}
				disabled={disabled}
				IconComponent={(props) => (
					<img src={chevron} alt={'chevron'} {...props} className={`chevron-icon ${props.className}`} />
				)}
			>
				{options.map(({ value, label }) => (
					<MenuItem value={value} key={value}>
						{label}
					</MenuItem>
				))}
			</MuiSelect>
		</FormControl>
	);
};

const formSX = {
	border: '1px solid #969696',
	borderRadius: '5px',
	background: 'background: #FFFFFF',
	width: '100%',
	'& .MuiInputLabel-root': {
		color: '#707070'
	},
	'& .Mui-focused': {
		color: '#707070'
	},
	'& .MuiInputBase-root': {
		'& .MuiInputBase-input:focus': {
			border: 'none'
		}
	},
	marginTop: '20px'
};

const labelSX = {
	fontSize: '18px',
	color: '#707070'
};
