import { AxiosResponse } from 'axios';
import { store } from '../app/store';
import { tokenService } from './TokenService';
import { selectLongTermJWT, sessionActions } from '../app/slices/session.slice';

export const CHECK_TOKEN_HEADER = 'x-check-token';
export const TOKEN_REFRESHED_STATUS_CODE = 205;
export const ACCESS_FORBIDDEN = 401;

export interface IRequestInterceptor {
	name: string;
	interceptRequest(request: () => Promise<any>): Promise<AxiosResponse>;
}

export class JWTRequestInterceptor implements IRequestInterceptor {
	public name = 'JWTRequestInterceptor';

	public constructor() {}

	/**
	 * Intercept async function used to intercept request sent to APIv2.
	 * If server responds with HTTP status 401 that means that Short Term Token has expired
	 * than send request with Long Term Token again to fetch new active Short Term Token.
	 * After new Short Term Token has been fetched, resend initial request to server...
	 * @param request - request to be made to APIv2
	 */
	public async interceptRequest<T>(request: () => Promise<AxiosResponse<T>>): Promise<AxiosResponse<T>> {
		try {
			// send initial request to server
			const res: AxiosResponse = await request();
			const handledResult = await this.handleFetchedSTTResponse(res, request);
			if (handledResult) return handledResult;
			else return res;
		} catch (error: any) {
			return await this.handleAxiosErrorResponse(error, request);
		}
	}

	private async handleAxiosErrorResponse<T>(
		error: any,
		request: () => Promise<AxiosResponse<T>>
	): Promise<AxiosResponse<T>> {
		const response = error.response;
		if (!response) return Promise.reject(error);
		const responseStatus = response.status;

		// if response HTTP status is 401 start fetch of new Short Term Token with Long Term JWToken
		if (responseStatus === ACCESS_FORBIDDEN) {
			const state = store.getState();
			const longTermToken = selectLongTermJWT(state);

			// send request with Long Term Token to fetch new short term token
			const fetchShortTermTokenResponse = await tokenService.fetchAuthorizationToken(longTermToken);

			const handledResult = await this.handleFetchedSTTResponse(fetchShortTermTokenResponse, request);
			if (handledResult) return handledResult;
			else return Promise.reject(new Error('Received invalid response status fetching STT'));
		}

		// else just reject with error (else than HTTP 401)
		return Promise.reject(error);
	}

	/**
	 * Function that handles response of fetching Short Term Token with Long Term Token.
	 * If there is no valid token in header, error will be thrown.
	 *
	 * @param response - fetch response for Short Term Token with Long Term token (AxiosResponse)
	 * @param request - request to be made with fetched short term token
	 */
	public async handleFetchedSTTResponse<T>(
		response: AxiosResponse,
		request: () => Promise<AxiosResponse<T>>
	): Promise<AxiosResponse<T> | undefined> {
		const responseStatus = response.status;
		if (responseStatus === TOKEN_REFRESHED_STATUS_CODE) {
			const refreshedToken = this.getRefreshedTokenFromResponse(response);
			this.saveRefreshedShortTermToken(refreshedToken);

			return await request();
		}
	}

	private getRefreshedTokenFromResponse(response: AxiosResponse) {
		const responseHeaders = response.headers;
		const refreshedToken = responseHeaders[CHECK_TOKEN_HEADER];
		if (!refreshedToken) {
			throw new Error('Received invalid token on 205 response status. Received token: ' + refreshedToken);
		}

		return refreshedToken;
	}

	private saveRefreshedShortTermToken(refreshedToken: string) {
		store.dispatch(sessionActions.setShortTermJWT(refreshedToken));
	}
}
