import React from 'react';
import { CustomizationKey } from '../../app/slices/customizations.slice';
import useCustomization from '../../hooks/useCustomization';
import LegalDocsMarkdown from '../custom/LegalDocsMarkdown';

const XS2A_TERMS_KEY: CustomizationKey = 'XS2A_TERMS';

const XS2ATermsModalCustomization = () => {
	const customization = useCustomization(XS2A_TERMS_KEY);
	if (!customization) return null;
	return <LegalDocsMarkdown content={customization} />;
};

export default XS2ATermsModalCustomization;
