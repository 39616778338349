import { WithChildren } from '../custom/SingleSpinner';
import CriteriaExplanationModal from '../modals/CriteriaExplanationModal';
import React from 'react';

interface WithCriteriaModalProps extends WithChildren {
	isCriteriaModalOpen: boolean;
	onClose(): void;
	codes: Array<string>;
}

export const WithCriteriaModalHOC = ({ children, isCriteriaModalOpen, onClose, codes }: WithCriteriaModalProps) => {
	return (
		<>
			{children}
			{isCriteriaModalOpen && <CriteriaExplanationModal criteriaCodes={codes} onClose={onClose} />}
		</>
	);
};
