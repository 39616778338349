import React from 'react';
import Modal from '../custom/Modal';
import ModalClose from './ModalClose';
import { getCriteriaWithCodeOnly } from '../hooks/useCriteria';
import { useTranslation } from 'react-i18next';
import CriteriaExplanationContainer from './CriteriaExplanationContainer';

interface CriteriaExplanationModalProps {
	criteriaCodes: Array<string>;
	onClose(): void;
}

const CriteriaExplanationModal = ({ criteriaCodes, onClose }: CriteriaExplanationModalProps) => {
	const { t } = useTranslation();

	const { title, text } = getCriteriaInformation(criteriaCodes, t);

	return (
		<Modal onClose={onClose}>
			<ModalClose onClose={onClose} />
			<CriteriaExplanationContainer title={title} content={text} />
		</Modal>
	);
};

export default React.memo(CriteriaExplanationModal);

function getCriteriaInformation(criteriaCodes: Array<string>, t: any) {
	const titles: Array<React.ReactElement> = [];
	const texts: Array<React.ReactElement> = [];

	criteriaCodes.forEach((criteriaElement) => {
		const [criteriaTitle, criteriaText] = getCriteriaWithCodeOnly(criteriaElement, t);
		titles.push(criteriaTitle);
		texts.push(criteriaText);
	});

	const title = (
		<>
			{titles.map((title, index) => {
				const trailingComma = index < titles.length - 1;
				return (
					<React.Fragment key={index}>
						{title}
						{trailingComma ? ' & ' : ''}
					</React.Fragment>
				);
			})}
		</>
	);

	const text = (
		<>
			{texts.map((text, index) => {
				const trailingLineBreak = index < titles.length - 1;
				return (
					<React.Fragment key={index}>
						{text}
						{trailingLineBreak ? <br /> : null}
					</React.Fragment>
				);
			})}
		</>
	);

	return { title, text };
}
