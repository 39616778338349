import { AxiosRequestInterceptor } from './types';
import store, { RootState } from '../app/store';
import { selectShortTermJWT } from '../app/slices/session.slice';
import { InternalAxiosRequestConfig } from 'axios';
import { HttpHeader } from '../constants/http';

export const AuthorizationInterceptor: AxiosRequestInterceptor = {
	onFulfilled: (configuration) => {
		const state = store.getState();
		const token = getAuthorizationToken(state);
		return attachAuthorization(configuration, token);
	}
};

const getAuthorizationToken = (state: RootState): string => {
	const token = selectShortTermJWT(state);
	if (token === '') throw new Error('Short term token not defined inside request authorization interceptor');
	return token;
};

const attachAuthorization = (configuration: InternalAxiosRequestConfig, token: string): InternalAxiosRequestConfig => {
	configuration.headers.set(HttpHeader['Authorization'], token);
	return configuration;
};
