import React from 'react';
import InfoIcon from '../../assets/images/icons/info.svg';

interface CriteriaExplanationContainerProps {
	title: string | React.ReactElement;
	content: string | React.ReactElement;
}

const CriteriaExplanationContainer = ({ title, content }: CriteriaExplanationContainerProps) => {
	return (
		<div className="code-info">
			<div className="code-info-header">
				<img src={InfoIcon} alt={'Info'} />
				<p>{title}</p>
			</div>
			<div className="code-info-template">{content}</div>
		</div>
	);
};

export default CriteriaExplanationContainer;
