import React, { FC } from 'react';
import { PERSONAL_INFO_PAGE_PREFIX } from '../../../utils/translationPagePrefix';
import { Box, useTheme } from '@mui/material';
import { ReactComponent as CheckInfoIcon } from '../../../assets/images/icons/box.svg';
import { ReactComponent as UserIcon } from '../../../assets/images/icons/user.svg';
import { useAppTranslation } from '../../../i18n';
import { useAppSelector } from '../../../app/hooks';
import { selectTitleText } from '../../../app/slices/metadata.slice';

export const Header: FC = () => {
	const theme = useTheme();
	const { t } = useAppTranslation();

	const title = useAppSelector(selectTitleText);

	return (
		<Box className="content-header">
			<div className="container-personal-info">
				<div className="check-info-icon-wrapper">
					<CheckInfoIcon fill={theme.palette.primary.main} className="check-info-icon" />
				</div>
				<div className="check-info-text">{title}</div>
			</div>
			<div className="container-personal-info">
				<div className="user-icon-wrapper">
					<UserIcon className="user-icon" fill={theme.palette.primary.main} />
				</div>
				<div className="user-info-container">
					<div className="user-info-text">
						{t(`${PERSONAL_INFO_PAGE_PREFIX}.header.personal information`)}
					</div>
					<div className="user-info-text-complete">
						{t(`${PERSONAL_INFO_PAGE_PREFIX}.header.please provide the following information`)}:
					</div>
				</div>
			</div>
		</Box>
	);
};
