import React, { FC } from 'react';
import DateConverter, { DateFormat } from '../../../utils/DateConverter';
import { Country } from '../../../constants/country-phone-number';
import pen from '../../../assets/images/icons/pen.svg';
import { ReactComponent as CheckInfoIcon } from '../../../assets/images/icons/box.svg';
import { ReactComponent as UserIcon } from '../../../assets/images/icons/user.svg';
import { useTheme } from '@mui/material';
import { useAppTranslation } from '../../../i18n';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectPersona } from '../../../app/slices/persona.slice';
import { selectTitleText } from '../../../app/slices/metadata.slice';
import { changeStep } from '../../../app/slices/step.slice';

export const Header: FC = () => {
	const dispatch = useAppDispatch();
	const theme = useTheme();
	const { t } = useAppTranslation();

	const persona = useAppSelector(selectPersona);
	const title = useAppSelector(selectTitleText);

	const handleGoBack = () => {
		dispatch(changeStep('PERSONA_INFORMATION_INPUT_STEP'));
	};

	return (
		<div className="content-header">
			<div className="container-personal-info">
				<div className="check-info-icon-wrapper">
					<CheckInfoIcon fill={theme.palette.primary.main} className="check-info-icon" />
				</div>
				<div className="check-info-text">{title}</div>
			</div>
			<div className="container-personal-info">
				<div className="user-icon-wrapper">
					<UserIcon className="user-icon" fill={theme.palette.primary.main} />
				</div>
				<div className="user-info-container">
					<div className="user-info-text">
						{persona.firstName} {persona.lastName}
					</div>
					{persona && persona.dateOfBirth && persona.residence.country && (
						<div className="user-info-text-complete">
							{DateConverter.convert(persona.dateOfBirth, DateFormat.VIEW_FORMAT)},{' '}
							{persona &&
								persona.residence &&
								persona.residence.country &&
								capitalizeFirstLetter(t(`countries.${persona.residence.country as Country}`))}
						</div>
					)}
				</div>
				<div className="edit-data-wrapper">
					<img src={pen} alt="edit pen" className="edit-pen" onClick={handleGoBack} />
				</div>
			</div>
		</div>
	);
};

const capitalizeFirstLetter = (str: string) => {
	if (!str) return '';
	return str.charAt(0).toUpperCase() + str.slice(1);
};
