import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loading from '../custom/Loading';
import { useAppSelector } from '../../app/hooks';
import { selectPersona } from '../../app/slices/persona.slice';
import { CurrentStep } from './CurrentStep';
import { selectCurrentStep } from '../../app/slices/step.slice';

const Steps: React.FC = () => {
	const currentStep = useAppSelector(selectCurrentStep);
	const loading = useSelector((state: any) => state.ui.loading);
	const persona = useAppSelector(selectPersona);
	const [_, setIsNextButtonDisabled] = useState(true);

	const isValidFormPersonalInfo = useCallback(() => {
		const {
			isValidFirstName,
			isValidLastName,
			residence: { country },
			isValidDateOfBirth
		} = persona;

		if (isValidFirstName && isValidLastName && country && isValidDateOfBirth) {
			setIsNextButtonDisabled(false);
		} else {
			setIsNextButtonDisabled(true);
		}
	}, [persona]);

	const isValidFormPersonalConsent = useCallback(() => {
		const { consentCollectingData, consentForwardingData } = persona;

		if (consentCollectingData && consentForwardingData) {
			setIsNextButtonDisabled(false);
		} else {
			setIsNextButtonDisabled(true);
		}
	}, [persona]);

	const checkIfButtonIsDisabled = useCallback(() => {
		switch (currentStep) {
			case 'PERSONA_INFORMATION_INPUT_STEP':
				isValidFormPersonalInfo();
				break;
			case 'PERSONA_CONSENT_INPUT_STEP':
				isValidFormPersonalConsent();
				break;
			default:
				break;
		}
	}, [currentStep, isValidFormPersonalInfo, isValidFormPersonalConsent]);

	useEffect(() => {
		checkIfButtonIsDisabled();
	}, [persona, checkIfButtonIsDisabled]);

	if (loading) {
		return (
			<div className="box-steps">
				<div className="steps">
					<Loading />
				</div>
			</div>
		);
	}

	return (
		<div className="box-steps">
			<div className="steps">
				<CurrentStep />
			</div>
		</div>
	);
};

export default Steps;
