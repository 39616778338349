import React from 'react';
import ModalHyperlinkMarkdown from '../custom/ModalHyperlinkMarkdown';

interface ForwardingPageXS2ACustomizationProps {
	customizationContent: string;
	handleMDLinkClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const ForwardingPageXS2ACustomization = ({
	customizationContent,
	handleMDLinkClick
}: ForwardingPageXS2ACustomizationProps) => {
	return <ModalHyperlinkMarkdown content={customizationContent} handleMDLinkClick={handleMDLinkClick} />;
};

export default ForwardingPageXS2ACustomization;
