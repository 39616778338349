export function set_brightness(hex: string, percent: number, operation: 'increase' | 'decrease') {
	const { r, g, b } = extractRGB(hex);

	if (operation === 'increase') {
		return (
			'#' +
			(0 | ((1 << 8) + r + ((256 - r) * percent) / 100)).toString(16).substr(1) +
			(0 | ((1 << 8) + g + ((256 - g) * percent) / 100)).toString(16).substr(1) +
			(0 | ((1 << 8) + b + ((256 - b) * percent) / 100)).toString(16).substr(1)
		);
	} else if (operation === 'decrease') {
		return (
			'#' +
			(0 | ((1 << 8) + (r * (100 - percent)) / 100)).toString(16).substr(1) +
			(0 | ((1 << 8) + (g * (100 - percent)) / 100)).toString(16).substr(1) +
			(0 | ((1 << 8) + (b * (100 - percent)) / 100)).toString(16).substr(1)
		);
	}
}

export function extractRGB(hex: string) {
	// strip the leading # if it's there
	hex = hex.replace(/^\s*#|\s*$/g, '');

	// convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
	if (hex.length == 3) {
		hex = hex.replace(/(.)/g, '$1$1');
	}

	return {
		r: parseInt(hex.substr(0, 2), 16),
		g: parseInt(hex.substr(2, 2), 16),
		b: parseInt(hex.substr(4, 2), 16)
	};
}
