import { AppDispatch, GetState } from '../store';
import { changeStep, selectCurrentStep, Step } from '../slices/step.slice';
import { setBankProcessFinished } from '../slices/ui.slice';

export const bankAppCompleted = () => {
	return (dispatch: AppDispatch, getState: GetState) => {
		const state = getState();
		const currentStep = selectCurrentStep(state);

		if (!isCurrentBankStep(currentStep)) return;

		dispatch(setBankProcessFinished());
		dispatch(changeStep('WAITING_CHECK_RESULTS_STEP'));
	};
};

const isCurrentBankStep = (step: Step) => {
	return step === 'BANK_STEP';
};
