import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CustomizationKey, Language, selectCustomizationForKeyAndLanguage } from '../app/slices/customizations.slice';
import { RootState } from '../app/store';

const useCustomizations = (keys: CustomizationKey[]) => {
	const [customizationTexts, setCustomizationTexts] = useState<string[]>([]);
	const {
		i18n: { language }
	} = useTranslation();
	const languageString: Language = language as Language;

	const selectCustomization = useCallback(
		(key: CustomizationKey) => selectCustomizationForKeyAndLanguage(key, languageString),
		[languageString]
	);

	const selectedCustomizations = useSelector((state: RootState) =>
		keys.map((key) => selectCustomization(key)(state))
	);

	useEffect(() => {
		setCustomizationTexts(selectedCustomizations);
	}, [selectedCustomizations.join(',')]);

	return customizationTexts;
};

export default useCustomizations;
