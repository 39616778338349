import { ICheckItem } from '../types/Reports';
import { PlaceholdersRecord } from './HTMLTemplateParser';

type CriteriaPlaceholderObject = Record<string, PlaceholdersRecord<ICheckItem>>;

export const criteriaPlaceholderObjects: CriteriaPlaceholderObject = {
	RMIN: {
		threshold: {
			name: 'threshold',
			value: (sourceObject) => {
				const threshold = sourceObject.params.threshold;
				if (threshold) return <>{threshold * 100}</>;
				else return <></>;
			}
		}
	},
	MAX_RENT: {
		threshold: {
			name: 'threshold',
			value: (sourceObject) => {
				const threshold = sourceObject.params.threshold;
				if (threshold) return <>{threshold * 100}</>;
				else return <></>;
			}
		}
	}
};
