import { AppDispatch } from '../store';
import { checkService } from '../../services/CheckService';
import { getCheckStatus } from '../../utils/check';
import { changeStep } from '../slices/step.slice';
import { initializeState } from './init-state';
import { handleError } from '../slices/errors.slice';
import { sleep } from '../../utils/sleep';

export const observePhoneNumberSmsDelivery = () => {
	return async (dispatch: AppDispatch): Promise<void> => {
		try {
			const check = await checkService.fetchCheckReport();
			const status = getCheckStatus(check);

			if (status === 'PHONE_NUMBER_PROVIDED') {
				await sleep(2500);
				await dispatch(observePhoneNumberSmsDelivery());
				return;
			}

			if (status === 'PHONE_NUMBER_VALIDATION_PENDING') {
				dispatch(changeStep('PHONE_NUMBER_VERIFICATION_CODE_INPUT_STEP'));
				return;
			}

			if (status === 'PHONE_NUMBER_SMS_DELIVERY_ERROR') {
				dispatch(changeStep('PHONE_NUMBER_SMS_DELIVERY_ERROR_STEP'));
				return;
			}

			await dispatch(initializeState());
		} catch (error) {
			dispatch(handleError(error));
		}
	};
};
