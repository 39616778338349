import { ApiService } from '../api/axios';
import { AxiosResponse } from 'axios';
import { Service } from './Service';
import { HttpStatus } from '../constants/http';

interface NextCheckState {
	status: 'PENDING' | 'ACCEPTED';
}

export interface IPhoneNumberVerificationService {
	sendPhoneNumber(phone: string): Promise<void>;
	submitConfirmationCode(code: string): Promise<NextCheckState>;
	resendConfirmationCode(): Promise<void>;
}

export class PhoneNumberVerificationService extends Service implements IPhoneNumberVerificationService {
	async sendPhoneNumber(phone: string): Promise<void> {
		try {
			await ApiService.put('/check/phone-number', { phoneNumber: phone });
		} catch (error) {
			if (this.hasResponseStatus(error, HttpStatus.BAD_REQUEST)) {
				throw new PhoneNumberInvalidFormatError();
			}

			if (this.hasResponseStatus(error, HttpStatus.FORBIDDEN)) {
				throw new SendRateLimitExceededError();
			}

			throw error;
		}
	}

	async submitConfirmationCode(code: string): Promise<NextCheckState> {
		try {
			const response: AxiosResponse<NextCheckState> = await ApiService.put('/check/phone-code', { code });
			return response.data;
		} catch (error) {
			if (this.hasResponseStatus(error, HttpStatus.NOT_ACCEPTABLE)) {
				throw new PhoneNumberVerificationCodeIncorrectError();
			}

			throw error;
		}
	}

	async resendConfirmationCode(): Promise<void> {
		try {
			await ApiService.put('/check/phone-code-resend');
		} catch (error) {
			if (this.hasResponseStatus(error, HttpStatus.NOT_ACCEPTABLE)) {
				throw new ResendConfirmationCodeTimeoutError();
			}

			throw error;
		}
	}
}

export class PhoneNumberInvalidFormatError extends Error {
	constructor() {
		super('Phone number invalid format error.');
	}
}

export class PhoneNumberVerificationCodeIncorrectError extends Error {
	constructor() {
		super('Phone number verification code incorrect.');
	}
}

export class ResendConfirmationCodeTimeoutError extends Error {
	constructor() {
		super('Phone number confirmation code timeout error.');
	}
}

export class SendRateLimitExceededError extends Error {
	constructor() {
		super('Phone number SMS sending rate limit exceeded');
	}
}
