import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import enTranslations from './locales/en/translation.json';
import deTranslations from './locales/de/translation.json';
import { Paths } from './types/utility-types';

export const resources = {
	en: {
		translation: enTranslations
	},
	de: {
		translation: deTranslations
	}
} as const;

export const i18nInstance = i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		fallbackLng: ['de', 'en'],
		supportedLngs: ['en', 'de'],
		nonExplicitSupportedLngs: false,
		debug: false, // enable only in development
		load: 'languageOnly',
		preload: ['de'],
		cleanCode: true,
		returnEmptyString: false, // allow empty strings
		react: {
			bindI18n: 'languageChanged', // which event triggers rerender
			useSuspense: false,
			transSupportBasicHtmlNodes: true,
			transKeepBasicHtmlNodesFor: ['br', 'p', 'i', 'u', 'strong', 'b', 'span']
		}
	});

export default i18nInstance;

export type TranslationKey = Paths<typeof resources['en']['translation']>;

export const useAppTranslation = () => {
	const { t, ...rest } = useTranslation();

	const translate = (key: TranslationKey, ...rest: Array<any>) => {
		return t(key, ...rest);
	};

	return {
		t: translate,
		...rest
	};
};
