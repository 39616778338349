import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import jwt_decode from 'jwt-decode';
import { Asset, AssetType, Metadata, MetadataAccount, Party } from '../../check/types';

type AssetsRecord = Partial<Record<AssetType, Asset>>;

type Parties = Partial<Party>;

type MetadataSlice = Omit<Metadata, 'assets'> & {
	assets: AssetsRecord;
	parties?: Parties;
};

const initialState: MetadataSlice = {
	account: {
		companyName: null,
		firstName: '',
		lastName: ''
	},
	check: {
		language: ''
	},
	assets: {},
	parties: []
};

export const metadataSlice = createSlice({
	name: 'metadata',
	initialState,
	reducers: {
		saveMetadata(state, action: PayloadAction<Metadata>) {
			const metadata = action.payload;
			const assets = action.payload.assets;
			const parties = action.payload.parties;
			const assetsRecord: AssetsRecord = {};

			if (assets) {
				assets.forEach((asset) => {
					assetsRecord[asset.type] = asset;
				});
			}

			return {
				...metadata,
				assets: assetsRecord,
				parties: parties
			};
		},
		saveColorFromJWT(state, action: PayloadAction<string>) {
			const jwt = action.payload;
			const color = extractColorFromJWT(jwt);

			if (!color) return state;

			const colorAsset: Asset = {
				type: 'color',
				value: color
			};

			state.assets = {
				...state.assets,
				color: colorAsset
			};
		}
	}
});

function extractColorFromJWT(jwt: string) {
	const decodedJwt = jwt_decode(jwt) as any;
	return decodedJwt.color;
}

export const metadataActions = metadataSlice.actions;

export const { saveMetadata, saveColorFromJWT } = metadataActions;

/**
 *  SELECTORS
 */

const selectAccount = (state: RootState) => state.metadata.account;
export const selectCompany = (state: RootState) => state.metadata.account.companyName;

export const selectAccountOwner = (state: RootState) => {
	const company = selectCompany(state);
	// if company name on whose behalf credit change is done is available - use company name
	if (company) return company;
	// else use firstname and lastname on who behalf we do a credit check
	return getAccountOwnerName(selectAccount(state));
};

const getAccountOwnerName = (account: MetadataAccount) => {
	const { firstName, lastName } = account;
	if (firstName && lastName) return `${firstName} ${lastName}`;
	return firstName || lastName || '';
};

export const selectParties = (state: RootState) => state.metadata.parties;

export const selectPrimaryParty = (state: RootState) => {
	const parties = selectParties(state);
	if (parties && parties.length > 0) {
		return parties.find((party) => party.type === 'primary');
	}
	return undefined;
};

export const selectPrimaryPartyName = (state: RootState) => {
	const primaryParty = selectPrimaryParty(state);
	if (!primaryParty) return '';
	if (primaryParty.companyName) return primaryParty.companyName;
	return getAccountOwnerName(primaryParty);
};

const selectAssets = (state: RootState) => state.metadata.assets;

export const selectTitleText = (state: RootState) => {
	const assets = selectAssets(state);
	const titleAsset = assets['title'];

	if (!titleAsset) return '';

	return titleAsset.value;
};

export const selectClientLogo = (state: RootState): string | undefined => {
	const assets = selectAssets(state);
	const logoAsset = assets['logo'];

	return logoAsset && logoAsset.value;
};

export const selectClientColor = (state: RootState): string | undefined => {
	const assets = selectAssets(state);
	const clientColorAsset = assets['color'];

	return clientColorAsset && clientColorAsset.value;
};

export const selectCheckLanguage = (state: RootState): string | undefined => {
	const checkLanguage = state.metadata.check.language;
	return checkLanguage || undefined;
};
