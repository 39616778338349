import useCustomization from '../../hooks/useCustomization';
import LegalDocsMarkdown from '../custom/LegalDocsMarkdown';
import React from 'react';
import { CustomizationKey } from '../../app/slices/customizations.slice';

const PRIVACY_KEY: CustomizationKey = 'PRIVACY';

const PrivacyModalCustomization = () => {
	const customization = useCustomization(PRIVACY_KEY);
	if (!customization) return null;
	return <LegalDocsMarkdown content={customization} />;
};

export default PrivacyModalCustomization;
