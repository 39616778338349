import { Service } from './Service';
import { AxiosResponse } from 'axios';
import { BaseService } from '../api/axios';
import { HttpHeader } from '../constants/http';

export interface ErrorData {
	error: Error;
	url: string;
	userAgent: string;
	request?: object;
	response?: object;
}

interface ReportedError {
	id: string;
}

interface ITechErrorService {
	sendErrorReport(data: ErrorData, token: string): Promise<string>;
}

export class TechErrorService extends Service implements ITechErrorService {
	async sendErrorReport(data: ErrorData, token: string): Promise<string> {
		try {
			const response: AxiosResponse<ReportedError> = await BaseService.post('/errors', data, {
				headers: {
					[HttpHeader['Authorization']]: token
				}
			});

			return response.data.id;
		} catch (error) {
			throw error;
		}
	}
}
