import React from 'react';
import { Container } from '@mui/material';
import Steps from '../steps/Steps';
import { AppErrorBoundary } from '../custom/AppErrorBoundary';
import useWindowTitle from '../hooks/useWindowTitle';

const POPUP_BROWSER_TITLE_KEY = 'POPUP_BROWSER_TITLE';

const ApplicationWrapper = () => {
	useWindowTitle(POPUP_BROWSER_TITLE_KEY);

	return (
		<AppErrorBoundary>
			<Container style={{ padding: '0', height: 'auto' }}>
				<Steps />
			</Container>
		</AppErrorBoundary>
	);
};

export default ApplicationWrapper;
