import { useAppDispatch } from '../app/hooks';
import { useEffect } from 'react';
import { checkService } from '../services/CheckService';
import { getCheckStatus } from '../utils/check';
import { uiActions } from '../app/slices/ui.slice';
import { changeStep } from '../app/slices/step.slice';

export const useObserveCheckAutoAccept = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		const ref = setInterval(async () => {
			const check = await checkService.fetchCheckReport();
			const status = getCheckStatus(check);
			if (status === 'ACCEPTED') {
				dispatch(uiActions.setFinalStepType('ACCEPTED'));
				dispatch(changeStep('CHECK_FINISHED_STEP'));
				return;
			}
			if (status === 'DISCARDED') {
				dispatch(uiActions.setFinalStepType('DISCARDED'));
				dispatch(changeStep('CHECK_FINISHED_STEP'));
			}
		}, 5000);
		return () => {
			clearInterval(ref);
		};
	}, []);
};
