import React from 'react';
import { DoubleSpinner } from './DoubleSpinner';
import { WithChildren } from './SingleSpinner';

type LoadingProps = Partial<WithChildren> & {
	wrapperClassname?: string;
};

const Loading = ({ children, wrapperClassname }: LoadingProps) => {
	return (
		<div className={`waiting-results-step ${wrapperClassname || ''}`}>
			<div className="equal-width-height-container">
				<DoubleSpinner />
			</div>
			<div
				className="equal-width-height-container"
				style={{
					position: 'absolute',
					marginBottom: '10px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					textAlign: 'center'
				}}
			>
				{/* This div mocks/replaces spinner that is absolute  */}
				<div className="spinner-relative-content">{children}</div>
			</div>
		</div>
	);
};

export default Loading;
