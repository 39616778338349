import { Box, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { LoadingAnimation } from './LoadingAnimation';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectPersonaPhoneNumber } from '../../../app/slices/persona.slice';
import { observePhoneNumberSmsDelivery } from '../../../app/thunks/observe-phone-number-sms-delivery';
import { DecomposedPhoneNumber, decomposePhoneNumber } from '../../../utils/phone-number-decompose';
import { COUNTRY_CODES } from '../../../constants/country-phone-number';
import TextCustomization from '../../customizations/TextCustomization';

export const PhoneNumberWaitingSmsDeliveryStep: FC = () => {
	const dispatch = useAppDispatch();

	const phoneNumber = useAppSelector(selectPersonaPhoneNumber) as string;
	const { country, prefix, rest } = decomposePhoneNumber(phoneNumber) as DecomposedPhoneNumber;

	useEffect(() => {
		dispatch(observePhoneNumberSmsDelivery()).catch(() => {});
	}, []);

	const code = COUNTRY_CODES[country];
	const phone = `${code} ${prefix} ${rest}`;
	return (
		<Box id="phone-number-waiting-sms-delivery-step" sx={{ pt: 5, pb: 5 }}>
			<LoadingAnimation />
			<Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" sx={{ pt: 3 }}>
				<Typography sx={{ ...textSX, fontSize: 'large' }}>
					<TextCustomization id="WAITING_SMS_VERIFICATION_CODE_DELIVERY_PAGE_HEADER" />
				</Typography>
				<Typography sx={{ ...textSX, fontSize: 'x-large' }}>
					<TextCustomization
						id="WAITING_SMS_VERIFICATION_CODE_DELIVERY_PAGE_SUBHEADER"
						data={{
							PhoneNumber: '`' + phone + '`'
						}}
					/>
				</Typography>
			</Box>
		</Box>
	);
};

const textSX = {
	textAlign: 'center',
	width: '80%',
	pt: 2
};
