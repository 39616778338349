import React from 'react';
import { useAppSelector } from '../../../app/hooks';
import { selectAutoCloseOption } from '../../../app/slices/configuration.slice';
import { selectReportStatus } from '../../../app/slices/report.slice';
import { CanceledScreen } from './CanceledScreen';
import { CompletedScreen } from './CompletedScreen';
import TimedWindowCloser from '../../custom/TimedWindowCloser';
import { Box } from '@mui/material';
import { CheckStatus } from '../../../types/check';

export const FinalStep = () => {
	const status = useAppSelector(selectReportStatus);
	const autoClose = useAppSelector(selectAutoCloseOption);

	const screen = getScreen(status);

	return (
		<Box>
			{autoClose && <TimedWindowCloser />}
			{screen}
		</Box>
	);
};

export default FinalStep;

function getScreen(status: CheckStatus) {
	if (status === 'CANCELLED') {
		return <CanceledScreen />;
	}

	return <CompletedScreen />;
}
