import React, { ReactEventHandler, useState } from 'react';
import { Skeleton } from '@mui/material';

type ClientLogoProps = {
	logo?: string;
	alt?: string;
};

const displayNoneStyle = {
	display: 'none'
};

const ClientLogo = ({ logo, alt }: ClientLogoProps) => {
	const [errorLoadingImage, setErrorLoadingImage] = useState<boolean>(false);
	const [loadingImage, setLoadingImage] = useState<boolean>(true);

	// if there is no client logo OR error happened, don't display anything
	if (!logo || errorLoadingImage) return <></>;

	const handleLoadingImageError: ReactEventHandler<HTMLImageElement> = (event) => {
		event.preventDefault();
		event.stopPropagation();
		setErrorLoadingImage(true);
	};

	const handleImageLoad: ReactEventHandler<HTMLImageElement> = (event) => {
		event.preventDefault();
		event.stopPropagation();
		setLoadingImage(false);
	};

	return (
		<div className="client-logo">
			{loadingImage && <Skeleton variant="circular" />}
			<img
				className="client-logo-img"
				src={logo}
				alt={alt}
				onError={handleLoadingImageError}
				onLoad={handleImageLoad}
				style={loadingImage ? displayNoneStyle : {}}
			/>
		</div>
	);
};

export default ClientLogo;
