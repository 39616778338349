import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import Footer from '../footer/Footer';

const Backdrop = (props: any) => {
	return <div className="backdrop" onClick={props.onClose} />;
};

const ModalOverlay = (props: any) => {
	return (
		<div className="modal">
			<div className="modal-content">{props.children}</div>
			<Footer />
		</div>
	);
};

const portalElement: Element = document.getElementById('overlays') as Element;
const appElement: Element = document.getElementById('root') as Element;

const Modal = (props: any) => {
	React.useEffect(() => {
		//@ts-ignore
		appElement.style.overflowY = 'hidden';
		return () => {
			//@ts-ignore
			appElement.style.overflowY = 'auto';
		};
	}, []);

	return (
		<Fragment>
			{ReactDOM.createPortal(<Backdrop onClose={props.onClose} />, portalElement)}
			{ReactDOM.createPortal(<ModalOverlay>{props.children}</ModalOverlay>, portalElement)}
		</Fragment>
	);
};

export default Modal;
