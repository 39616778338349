import { AppDispatch, GetState, RootState } from '../store';
import { changeStep, Step } from '../slices/step.slice';
import { selectPersona } from '../slices/persona.slice';
import { z } from 'zod';
import { selectLongTermJWT } from '../slices/session.slice';
import { selectReportStatus } from '../slices/report.slice';

export const completeEmailVerification = () => {
	return (dispatch: AppDispatch, getState: GetState): void => {
		const state = getState();
		const token = selectLongTermJWT(state);
		window.history.pushState({}, '', `/${token}`);

		const status = selectReportStatus(state);
		if (status === 'NO_PHONE_NUMBER') {
			dispatch(changeStep('PHONE_NUMBER_INPUT_STEP'));
			return;
		}

		const isPersonaInformationProvided = isPersonaInformationPrefilled(getState());
		const nextStep: Step = isPersonaInformationProvided
			? 'PERSONA_CONSENT_INPUT_STEP'
			: 'PERSONA_INFORMATION_INPUT_STEP';
		dispatch(changeStep(nextStep));
	};
};

export const isPersonaInformationPrefilled = (state: RootState): boolean => {
	const persona = selectPersona(state);
	const { success } = personaSchema.safeParse(persona);
	return success;
};

const personaSchema = z.object({
	firstName: z.string().min(1),
	lastName: z.string().min(1),
	residence: z.object({
		country: z.string().min(1)
	}),
	dateOfBirth: z.string().min(1)
});
