import { AppDispatch } from '../store';
import { checkReportAPI } from '../../services/CheckReportService';
import { isErrorResponse } from './init-state';
import { handleError } from '../slices/errors.slice';
import { BankAccount, Check } from '../../check/types';
import { getFincredibleReport } from '../../check/utils';
import { reportsActions } from '../slices/report.slice';
import { uiActions } from '../slices/ui.slice';
import { FINcredbileReport } from '../../check/report';
import { parseKontoData } from './report';
import { bankAccountActions } from '../slices/bank.slice';
import { initializeFinalStep, initializeReadyStep } from './init-current-step';

export function fetchAnalyticsResults() {
	return async (dispatch: AppDispatch) => {
		try {
			const response = await checkReportAPI.fetchReport();

			if (isErrorResponse(response)) {
				const errors = response.data.errors;
				const error = new Error(errors);
				dispatch(handleError(error));
				return;
			}

			dispatch(processAnalyticsResponse(response.data));
		} catch (error: any) {
			dispatch(handleError(error));
		}
	};
}

function processAnalyticsResponse(check: Check) {
	return (dispatch: AppDispatch) => {
		const report = getFincredibleReport(check);
		const status = report.status;
		const accepted = report.userAccepted;

		if (typeof accepted === 'boolean') {
			dispatch(processAcceptedReport(report));
			return;
		}

		switch (status) {
			case 'PENDING':
			case 'PROCESSING':
			case 'CANCELLED':
				return;
			case 'READY':
				dispatch(processReadyReport(report, check));
				return;
		}
	};
}

function processAcceptedReport(report: FINcredbileReport) {
	return (dispatch: AppDispatch) => {
		const accepted = report.userAccepted;
		const status = accepted ? 'ACCEPTED' : 'DISCARDED';
		dispatch(uiActions.setFinalStepType(status));
		dispatch(reportsActions.setReportStatus(status));
		dispatch(initializeFinalStep());
	};
}

function processReadyReport(report: FINcredbileReport, check: Check) {
	return (dispatch: AppDispatch) => {
		dispatch(reportsActions.setReportStatus('READY'));
		dispatch(bankAccountActions.setBankAccount(check.bankAccount as BankAccount));
		dispatch(parseKontoData(report));
		dispatch(initializeReadyStep());
	};
}
