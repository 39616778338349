import { SVGIcon } from './SVGIcon';
import React from 'react';

export function AwardIcon() {
	return (
		<SVGIcon
			width="339.977"
			height="375.654"
			viewBox="0 0 339.977 375.654"
			size={{ x1: '1.62', y1: '-0.011', x2: '-0.528', y2: '0.864' }}
		>
			<path
				id="award"
				d="M104.311,0a104.3,104.3,0,0,1,63.374,187.136l14.732,111a13.037,13.037,0,0,1-18.4,13.559l-1.239-.665-58.472-35.083-58.485,35.1A13.037,13.037,0,0,1,26.087,299.53l.117-1.395L40.95,187.149A104.3,104.3,0,0,1,104.311,0ZM143.24,201.086a104.676,104.676,0,0,1-77.845,0L55.6,274.759l41.993-25.2a13.037,13.037,0,0,1,11.825-.808l1.591.808,41.98,25.2ZM104.311,26.074a78.223,78.223,0,1,0,41.6,144.478,10.274,10.274,0,0,1,1.773-1.16A78.223,78.223,0,0,0,104.311,26.074Z"
				transform="translate(161.156 0.001) rotate(31)"
				fill="url(#linear-gradient)"
			/>
		</SVGIcon>
	);
}
