import React from 'react';
import Spinner, { WithChildren } from './SingleSpinner';
import { Paper, useTheme } from '@mui/material';

type DoubleSpinnerProps = Partial<WithChildren>;

export const DoubleSpinner = ({ children }: DoubleSpinnerProps) => {
	const theme = useTheme();
	const paperSX = {
		backgroundColor: 'transparent',
		'&:before': {
			background: `${theme.palette.primary.lighter}`
		},
		'&:after': {
			background: `${theme.palette.primary.lighter}`
		}
	};
	return (
		<div className="spinner-container">
			<Spinner>
				<div
					className="inner-spinner-container"
					style={{ borderRight: `10px solid ${theme.palette.primary.lighter}` }}
				>
					<Paper elevation={0} className="spinner-inner-2" sx={paperSX}>
						<div className="spinner-inner2-reverse">
							<div className="spinner-2-content">{children}</div>
						</div>
					</Paper>
				</div>
			</Spinner>
		</div>
	);
};

export default DoubleSpinner;
