import useCustomization from '../../hooks/useCustomization';
import { CustomizationKey } from '../../app/slices/customizations.slice';
import Markdown from 'react-markdown';
import { FC, memo, useMemo } from 'react';

interface TextCustomizationProps {
	id: CustomizationKey;
	data?: Record<string, string>;
	fallback?: string;
}

const TextCustomization: FC<TextCustomizationProps> = ({ id, data, fallback }) => {
	const customization = useDynamicCustomizationContent(id, data || {});
	if (!customization) return <Markdown>{fallback}</Markdown>;
	return <Markdown>{customization}</Markdown>;
};

export const useDynamicCustomizationContent = (
	id: CustomizationKey,
	data: Record<string, string>
): string | undefined => {
	const customization = useCustomization(id);

	return useMemo(() => {
		if (!customization) return undefined;

		return Object.keys(data).reduce((content, placeholder) => {
			const replacement = data[placeholder];
			return content.replace(`[[${placeholder}]]`, replacement);
		}, customization);
	}, [customization, data]);
};

export default memo(TextCustomization);
