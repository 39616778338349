import React, { useCallback, useMemo } from 'react';
import { getCodesResultValue, getInOutFlowItemStatus, ICheckItem } from '../../types/Reports';
import { AccordionItem, CheckAccordionRow } from './CheckAccordionRow';
import { ItemStatus } from '../../types/constants';
import { useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import { RESULTS_PAGE_PREFIX } from '../../utils/translationPagePrefix';
import { FlagsEnum, selectFlag } from '../../app/slices/flags.slice';
import { checkIfEnumExistInHousehold, getValueFromType } from '../../utils/report-utils';
import { CurrencyFormatter } from '../../utils/CurrencyTextFormatter';

type HouseholdListProps = {
	onCommentClick: (...args: any[]) => void;
	onInfoClick: (...args: any[]) => void;
};

const HouseholdList = ({ onCommentClick, onInfoClick }: HouseholdListProps) => {
	const household = useAppSelector((state) => state.reports.household);
	const userAssessmentFlag = useAppSelector((state) => selectFlag(state, FlagsEnum.ASSESSMENT_USER_INPUT));
	const { t, i18n } = useTranslation();

	const HOUSEHOLDS_TEXT = useMemo(() => i18n.t(`${RESULTS_PAGE_PREFIX}.households`), [i18n.language]);

	const mapInflowItems = useCallback(
		(checks: Array<ICheckItem>): [Array<AccordionItem>, { verified: ItemStatus }] => {
			if (!household) return undefined as unknown as any;

			const verifiedObject = { verified: ItemStatus.VERIFIED };
			const arr: Array<false | AccordionItem> = checks.map((check) => {
				const titleReport = checkIfEnumExistInHousehold(check);

				if (!titleReport) return false;

				let checkResult = getValueFromType(check);
				const itemStatus = getInOutFlowItemStatus(check);
				const itemSubtitle = getCodesResultValue(check);
				// if at least one check item has status that is not verified, inflow is not verified
				if (itemStatus !== ItemStatus.VERIFIED) verifiedObject.verified = ItemStatus.UNVERIFIED;

				// translation key
				const translationKey = `codes.${check.code}`;
				const title = t(translationKey);

				let retVal = {
					title,
					displayComment: check.comment?.length > 0 && userAssessmentFlag,
					commentFull: true,
					status: itemStatus,
					onCommentClick: () =>
						onCommentClick(titleReport, check.code, check.comment, getCheckItemValue(check)),
					onDetailClick: () =>
						onCommentClick(titleReport, check.code, check.comment, getCheckItemValue(check)),
					onInfoClick: () => onInfoClick(check, 'IN_OUT')
				} as AccordionItem;
				if (itemSubtitle || itemSubtitle === 0) {
					retVal = {
						...retVal,
						subtitle: `EUR ${CurrencyFormatter.formatValueToCurrencyString(checkResult)}`
					};
				}
				return retVal;
			});
			return [arr.filter((element) => element !== false) as Array<AccordionItem>, verifiedObject];
		},
		[household]
	);

	const [mappedInflowItems, inflowVerified] = useMemo(() => {
		return mapInflowItems(household);
	}, [mapInflowItems, i18n.language]);

	// if there are no items, return empty fragment
	if (!household || household.length === 0) return <></>;

	return (
		<CheckAccordionRow
			title={HOUSEHOLDS_TEXT}
			displayInfo={false}
			displayComment={false}
			openable={true}
			items={mappedInflowItems}
			verified={inflowVerified.verified}
			konto={false}
		/>
	);
};

export default HouseholdList;

const getCheckItemValue = (check: ICheckItem) => {
	if (check.newResult !== undefined) return check.newResult;
	return '';
};
