import React from 'react';
import { useTranslation } from 'react-i18next';
import { EnumErrorType, ErrorObjectContent } from '../../types/ErrorsObject';
import AdditionalContent from './AdditionalContent';
import { useAppSelector } from '../../app/hooks';
import { selectCustomizations } from '../../app/slices/customizations.slice';
import { errorsMap } from './errors';
import { ErrorContentTextCustomization } from '../customizations/ErrorCustomizations';

const TRANSLATION_PREFIX = 'technical-error-page';

interface ErrorContentProps {
	errorType: EnumErrorType;
}

const ErrorContent = ({ errorType }: ErrorContentProps) => {
	const { t } = useTranslation();
	const { texts } = errorsMap.get(errorType) as ErrorObjectContent;
	const customizations = useAppSelector(selectCustomizations);
	const translatedTexts: string[] = texts.map((text) => {
		return t(`${TRANSLATION_PREFIX}.${text}`);
	});

	return (
		<>
			<div className="error-step-text">
				{Object.keys(customizations).length === 0 ? (
					translatedTexts.map((text, index) => (
						<div key={index} style={{ padding: '3%' }}>
							{text}
						</div>
					))
				) : (
					<ErrorContentTextCustomization errorType={errorType} />
				)}
			</div>
			<AdditionalContent type={errorType} />
		</>
	);
};

export default ErrorContent;
