import { RootState } from './store';
import _ from 'lodash';
import { maskString } from '../utils/maskString';

/**
 * Selects a subset of the Redux store state to be passed to the analytics service
 * during error reporting.
 *
 * This function is responsible for extracting specific state data that is relevant
 * to an error report. The selected data is intended to provide contextual information
 * about the state of the application at the time the error occurred, and may include
 * details such as user session info, ui info, feature flags, or any other
 * relevant state slices.
 *
 * @function selectErrorAnalyticsData
 * @param {Object} state - The current Redux store state.
 * @returns {Object} - An object containing the selected subset of state to be sent
 *                     for analytics purposes.
 */
export const selectErrorAnalyticsData = (state: RootState) => {
	// not sensitive data
	const data: Record<string, any> = _.pick(state, [
		'app',
		'configuration',
		'customizations',
		'error',
		'flagsSlice',
		'metadata',
		'session',
		'step',
		'ui'
	]);

	const persona = state.persona;
	data.persona = {
		firstName: persona.firstName && maskString(persona.firstName),
		lastName: persona.lastName && maskString(persona.lastName),
		country: persona.country && maskString(persona.country),
		dateOfBirth: persona.dateOfBirth && maskString(persona.dateOfBirth)
	};

	const bankAccount = state.bankAccount;
	data.bankAccount = {
		accountHolder: maskString(bankAccount.accountHolder),
		bankCountry: maskString(bankAccount.bankCountry),
		bic: maskString(bankAccount.bic),
		bankName: maskString(bankAccount.bankName),
		iban: maskString(bankAccount.iban)
	};

	const reports = state.reports;
	data.reports = {
		reportStatus: reports.reportStatus,
		userAccepted: reports.userAccepted
	};

	return data;
};
