export interface ICurrencyFormatter {
	formatValueToCurrencyString(value: string | number): string;
	deformatCurrencyString(currencyString: string): string;
}

interface CurrencyFormatterConfig {
	thousandsSeparator?: string;
	decimalSeparator?: string;
	numberOfDecimals?: number;
	currency?: string;
	currencyPosition?: 'prefix' | 'postfix';
}

const defaultConfiguration: Required<CurrencyFormatterConfig> = {
	thousandsSeparator: '.',
	decimalSeparator: ',',
	currencyPosition: 'postfix',
	currency: '',
	numberOfDecimals: 2
};

export class CurrencyTextFormatter implements ICurrencyFormatter {
	private thousandsSeparator!: string;
	private decimalSeparator!: string;
	private numberOfDecimals!: number;
	private currency!: string;
	private currencyPosition!: 'prefix' | 'postfix';

	public constructor(config?: CurrencyFormatterConfig) {
		if (config) {
			this.useUserConfigurationOrDefault(config);
		} else {
			this.useDefaultConfiguration();
		}
	}

	private useUserConfigurationOrDefault(config: CurrencyFormatterConfig) {
		this.thousandsSeparator = config.thousandsSeparator || defaultConfiguration.thousandsSeparator;
		this.decimalSeparator = config.decimalSeparator || defaultConfiguration.decimalSeparator;
		this.numberOfDecimals = config.numberOfDecimals || defaultConfiguration.numberOfDecimals;
		this.currency = config.currency || defaultConfiguration.currency;
		this.currencyPosition = config.currencyPosition || defaultConfiguration.currencyPosition;
	}

	private useDefaultConfiguration() {
		const { thousandsSeparator, decimalSeparator, numberOfDecimals, currencyPosition, currency } =
			defaultConfiguration;
		this.thousandsSeparator = thousandsSeparator;
		this.decimalSeparator = decimalSeparator;
		this.numberOfDecimals = numberOfDecimals;
		this.currencyPosition = currencyPosition;
		this.currency = currency;
	}

	/**
	 * Convert (format) number/string to formatted currency string with thousands separated and currency support
	 * example: 123000 -> 123.000,00
	 * example with currency EUR: 123000 -> 123.000,00 EUR
	 * example (edge case): '' -> ''
	 *
	 * @param value - number to be formatted
	 * @throws InvalidNumberError if provided value is not a valid number
	 */
	public formatValueToCurrencyString(value: string | number): string {
		if (value === '') return '';

		this.checkIfValueIsValidNumber(value);

		let stringValue = value.toString().trim();
		let formattedString = '';

		const isNumberZero = this.isZero(stringValue);
		if (isNumberZero) return '0';

		const POSTFIX = this.createDecimalPostfix(stringValue);
		const carriage = this.calculateCarriage(stringValue);
		stringValue = this.convertToIntegerString(stringValue, carriage);

		formattedString = this.separateThousands(stringValue);

		let finalString = formattedString + POSTFIX;
		finalString = this.addCurrencyIfNeeded(finalString);

		return finalString;
	}

	private checkIfValueIsValidNumber(value: string | number) {
		if (typeof value === 'string') {
			const trimmedValue = value.trim();
			const parseResult = Number.parseFloat(trimmedValue);
			const isInvalid = Object.is(parseResult, NaN);
			if (isInvalid) throw new InvalidNumberError(value);
		}
		if (Object.is(value, NaN)) {
			throw new InvalidNumberError('NaN');
		}
	}

	private isZero(value: string) {
		return value === '0' || Number.parseFloat(value) === 0;
	}

	private createDecimalPostfix(value: string): string {
		if (this.numberOfDecimals === 0) return '';

		let floatValue = Number.parseFloat(value);
		let floatString = floatValue.toFixed(this.numberOfDecimals);

		const split = floatString.split('.');
		return ',' + split[1];
	}

	private calculateCarriage(value: string) {
		const integerValue = Number.parseInt(value);
		let floatValue = Number.parseFloat(value);
		let floatString = floatValue.toFixed(this.numberOfDecimals);
		const integerValueCompare = Number.parseInt(floatString);
		return integerValueCompare - integerValue;
	}

	private convertToIntegerString(value: string, carriage: number): string {
		const int = Number.parseInt(value) + carriage;
		return int.toString();
	}

	private separateThousands(integerString: string): string {
		let thousandsCount = Math.floor(integerString.length / 3);
		let leftOver = integerString.length % 3;
		let separatedString = '';

		const noNeedForSeparation = this.hasNoNeedForSeparation(thousandsCount, leftOver);
		if (noNeedForSeparation) return integerString;

		const totalDigits = integerString.length;
		let thousandsSeparated = 0;
		while (thousandsCount > 0) {
			const start = totalDigits - thousandsSeparated * 3 - 3;
			const end = totalDigits - thousandsSeparated * 3;
			const slice = integerString.substring(start, end);
			separatedString = this.appendSlice(slice, separatedString, thousandsSeparated);
			thousandsSeparated++;
			thousandsCount--;
		}

		separatedString = this.appendLeftOver(separatedString, leftOver, integerString);

		return separatedString;
	}

	private hasNoNeedForSeparation(thousandsCount: number, leftOver: number) {
		return thousandsCount === 0 || (thousandsCount === 1 && leftOver === 0);
	}

	private appendSlice(slice: string, currentSeparatedString: string, thousandsSeparated: number) {
		const middleman = thousandsSeparated === 0 ? '' : '.';
		return slice + middleman + currentSeparatedString;
	}

	private appendLeftOver(separatedString: string, leftOver: number, originalString: string) {
		if (leftOver === 0) return separatedString;
		const leftDigits = originalString.substring(0, leftOver);
		return leftDigits + '.' + separatedString;
	}

	private addCurrencyIfNeeded(formattedString: string) {
		if (this.currency) return formattedString + ' ' + this.currency;
		return formattedString;
	}

	/**
	 * Convert (deformat) currency string back to original value.
	 * Should only be used to deformat currency strings that were formatted by same instance using format method.
	 * example: "123,000.00" -> "123000"
	 * @param currencyString - formatted currency string (by same CurrencyTextFormatter instance)
	 */
	public deformatCurrencyString(currencyString: string): string {
		if (currencyString === '') return '';

		const isNumberZero = this.isZero(currencyString);
		if (isNumberZero) return '0';

		const withoutCurrencyString = this.removeCurrency(currencyString);
		const withoutThousandsSeparator = this.removeThousandsSeparator(withoutCurrencyString);
		const withReplacedDecimalsSeparator = this.replaceDecimalSeparator(withoutThousandsSeparator);

		const valueAsNumber = Number.parseFloat(withReplacedDecimalsSeparator);
		return valueAsNumber.toString();
	}

	private removeCurrency(currencyString: string) {
		if (this.currency) {
			const split = currencyString.split(' ');
			if (this.currencyPosition === 'prefix') return split[1];
			else return split[0];
		}
		return currencyString;
	}

	private removeThousandsSeparator(currencyString: string) {
		const pattern = this.thousandsSeparator;
		return currencyString.replaceAll(pattern, '');
	}

	private replaceDecimalSeparator(currencyString: string) {
		return currencyString.replace(this.decimalSeparator, '.');
	}
}

export class InvalidNumberError extends Error {
	private static message = 'Provided value is not a number: ';
	private value: string;

	public constructor(value: string) {
		super(InvalidNumberError.message + value);
		this.name = 'InvalidNumberError';
		this.value = value;
	}
}

export const CurrencyFormatter = new CurrencyTextFormatter();
