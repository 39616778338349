import HeaderProgress from './HeaderProgress';
import ClientLogo from './ClientLogo';
import { useAppSelector } from '../../app/hooks';
import { useTheme } from '@mui/material';
import { selectClientLogo } from '../../app/slices/metadata.slice';
import TextCustomization from '../customizations/TextCustomization';
import ImageCustomization from '../customizations/ImageCustomization';
import { CustomizationKey } from '../../app/slices/customizations.slice';

const POPUP_MAIN_TITLE_KEY: CustomizationKey = 'POPUP_MAIN_TITLE';
const POPUP_MAIN_LOGO_KEY: CustomizationKey = 'POPUP_MAIN_LOGO';

const Header = () => {
	const clientLogo = useAppSelector(selectClientLogo);
	const theme = useTheme();

	return (
		<header>
			<HeaderProgress />
			<div className="logo-container">
				<div className="container-konto-check">
					<ImageCustomization
						customizationKey={POPUP_MAIN_LOGO_KEY}
						imageStyle={{ display: 'block', height: '100%' }}
					/>
					<div className="logo-text-container">
						<h1 className="logo-text" style={{ color: theme.palette.primary.main }}>
							<TextCustomization id={POPUP_MAIN_TITLE_KEY} />
						</h1>
					</div>
				</div>
				<ClientLogo logo={clientLogo} alt="client logo" />
			</div>
		</header>
	);
};

export default Header;
