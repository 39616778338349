import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import GradientCheckIcon from '../../svg/GradientCheckIcon';
import StepButton from '../StepButton';
import { useAppTranslation } from '../../../i18n';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { completePhoneNumberVerification } from '../../../app/thunks/complete-phone-number-verification';
import TextCustomization from '../../customizations/TextCustomization';
import { selectPersonaPhoneNumber } from '../../../app/slices/persona.slice';

export const PhoneNumberVerifiedStep: FC = () => {
	const dispatch = useAppDispatch();
	const { t } = useAppTranslation();

	const phone = useAppSelector(selectPersonaPhoneNumber) as string;

	const handleContinue = () => {
		dispatch(completePhoneNumberVerification());
	};

	return (
		<Box id="phone-number-verified-step" sx={{ mt: 5 }}>
			<Box sx={boxSX}>
				<GradientCheckIcon />
			</Box>
			<Box sx={{ ...boxSX, mt: 5, mb: 5 }}>
				<Typography sx={headerSX}>
					<TextCustomization id="PHONE_NUMBER_VERIFIED_SCREEN_HEADER" data={{ PhoneNumber: phone }} />
				</Typography>
			</Box>
			<Box sx={{ ...boxSX, mt: 5, mb: 2 }}>
				<Typography sx={subheaderSX}>
					<TextCustomization id="PHONE_NUMBER_VERIFIED_SCREEN_SUBHEADER" data={{ PhoneNumber: phone }} />
				</Typography>
			</Box>
			<StepButton disabled={false} title={t('button.next')} onClick={handleContinue} />
			<Typography sx={{ ...subheaderSX, mt: 3 }}>
				<TextCustomization id="PHONE_NUMBER_VERIFIED_SCREEN_ADDITIONAL_INFO" data={{ PhoneNumber: phone }} />
			</Typography>
		</Box>
	);
};

const headerSX = {
	width: '80%',
	fontSize: 'x-large',
	fontWeight: '500',
	textAlign: 'center'
};

const subheaderSX = {
	width: '80%',
	fontSize: 'medium',
	textAlign: 'center'
};

const boxSX = {
	width: '100%',
	display: 'flex',
	justifyContent: 'center'
};
