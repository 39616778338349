import { AppDispatch } from '../store';
import { PhoneNumberVerificationService } from '../../services/PhoneNumberVerificationService';
import { changeStep } from '../slices/step.slice';
import { setPhoneNumber } from '../slices/persona.slice';

export const sendPhoneNumber = (phone: string) => {
	return async (dispatch: AppDispatch): Promise<void> => {
		const service = new PhoneNumberVerificationService();
		await service.sendPhoneNumber(phone);

		dispatch(setPhoneNumber(phone));
		dispatch(changeStep('PHONE_NUMBER_SMS_DELIVERY_PENDING_STEP'));
	};
};
