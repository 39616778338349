import React, { ChangeEvent, FC } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import alert_triangle from '../../assets/images/icons/alert-triangle.svg';

export interface InputProps {
	id: string;
	name: string;
	label: string;
	value: string;
	disabled?: boolean;
	error?: boolean;
	onChange(value: string): void;
	onBlur?(): void;
}

export const TextInput: FC<InputProps> = ({
	id,
	name,
	label,
	value,
	error = false,
	disabled = false,
	onChange,
	onBlur
}) => {
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		onChange(value);
	};

	return (
		<TextField
			id={id}
			name={name}
			value={value}
			variant="filled"
			className="controlled-input txt-field"
			label={label}
			inputMode="text"
			disabled={disabled}
			sx={{
				...fieldSX,
				...(error ? { border: '1px solid #FF0000 !important' } : {})
			}}
			onChange={handleChange}
			onBlur={onBlur}
			InputLabelProps={{
				style: {
					...labelSX,
					color: `${error ? '#FF0000' : '#707070'}`
				}
			}}
			inputProps={{
				style: { color: `${error ? '#FF0000' : 'inherit'}` }
			}}
			InputProps={{
				startAdornment: error && <ErrorInputAdornment />
			}}
		/>
	);
};

export const ErrorInputAdornment: FC = () => {
	return (
		<InputAdornment
			position="start"
			className="input-adornment"
			sx={{
				backgroundColor: '#ffffff',
				borderRadius: '5px'
			}}
		>
			<img src={alert_triangle} alt="Alert" className="input-adornment-image" />
		</InputAdornment>
	);
};

const fieldSX = {
	width: '100%',
	border: '1px solid #969696',
	borderRadius: '5px',
	'& .MuiInputLabel-root': {
		color: '#707070'
	},
	'& .MuiInputBase-root': {
		'& .MuiInputBase-input:focus': {
			border: 'none'
		}
	},
	mt: '20px'
};

const labelSX = {
	fontSize: '18px'
};
