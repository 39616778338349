import { WithChildren } from '../custom/SingleSpinner';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MuiThemeProvider } from './MuiThemeProvider';
import I18NProvider from './I18NProvider';

export const AggregateAppProvider = ({ children }: WithChildren) => {
	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<MuiThemeProvider>
				<I18NProvider>{children}</I18NProvider>
			</MuiThemeProvider>
		</LocalizationProvider>
	);
};
