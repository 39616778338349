import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './store';

/**
 * Pre-typed version of the useDispatch hook.
 * Default dispatch type doesn't know about thunks. Use AppDispatch to correctly dispatch functions as actions.
 */
export const useAppDispatch: () => AppDispatch = useDispatch;
/**
 * Pre-typed version of the useSelector hook.
 * Default useSelect doesn't know about state structure. Use AppSelector as typed version of its original part with
 * state: RootState.
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
