import React, { useState } from 'react';
import { WithChildren } from './SingleSpinner';

interface TimedWindowCloser extends Partial<WithChildren> {
	timeout?: number;
	onTick?: (secondsPassed: number) => void;
	onBeforeClose?: () => void;
}

const DEFAULT_TIMEOUT = 5;

export const TimedWindowCloser = ({
	timeout = DEFAULT_TIMEOUT,
	children,
	onTick,
	onBeforeClose
}: TimedWindowCloser) => {
	const [passedSeconds, setPassedSeconds] = useState<number>(0);

	const _onBeforeClose = () => {
		if (onBeforeClose) onBeforeClose();
	};

	const _onTick = (seconds: number) => {
		if (onTick) onTick(seconds);
	};

	React.useEffect(() => {
		if (passedSeconds === timeout) {
			_onBeforeClose();
			window.close();
		}

		setTimeout(() => {
			_onTick(passedSeconds + 1);
			setPassedSeconds((secs) => secs + 1);
		}, 1000);
	}, [passedSeconds, timeout]);

	return children || undefined;
};

export default TimedWindowCloser;
