import React from 'react';
import { ReactComponent as CheckIcon } from '../../../src/assets/images/icons/check.svg';
import { useTheme } from '@mui/material';

const GradientEmailIcon = () => {
	const theme = useTheme();
	const style = {
		mainColor: theme.palette.primary.main,
		secondaryColor: theme.palette.secondary.main
	};
	return (
		<svg
			width="98.479"
			height="71.123"
			viewBox="0 0 98.479 71.123"
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="xMidYMid meet"
		>
			<defs>
				<defs>
					<linearGradient id="grad2" x1="0%" y1="0%" x2="90%" y2="0%">
						<stop offset="0%" stopColor={style.mainColor} />
						<stop offset="90%" stopColor={style.secondaryColor} />
					</linearGradient>
				</defs>
			</defs>
			<CheckIcon className="email-step-icon-style" fill="url(#grad2)" />
		</svg>
	);
};

export default GradientEmailIcon;
