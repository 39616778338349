import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import TextCustomization from '../../customizations/TextCustomization';

interface Props {
	phone: string;
}

export const Heading: FC<Props> = ({ phone }) => {
	return (
		<Box sx={{ display: 'flex', justifyContent: 'center' }}>
			<Typography sx={subheaderSX}>
				<TextCustomization
					id="PHONE_NUMBER_VERIFICATION_CODE_INPUT_STEP_HEADER"
					data={{ PhoneNumber: phone }}
				/>
			</Typography>
		</Box>
	);
};

const subheaderSX = {
	fontSize: 'large',
	fontWeight: '500',
	textAlign: 'center',
	width: '75%',
	mt: 4,
	mb: 2
};
