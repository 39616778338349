import { configureStore } from '@reduxjs/toolkit';
import bankAccountSlice from './slices/bank.slice';
import { configurationSlice } from './slices/configuration.slice';
import uiSlice from './slices/ui.slice';
import errorsSlice from './slices/errors.slice';
import sessionSlice from './slices/session.slice';
import personaSlice from './slices/persona.slice';
import flagsSlice from './slices/flags.slice';
import { metadataSlice } from './slices/metadata.slice';
import reportsSlice from './slices/report.slice';
import appSlice from './slices/app.slice';
import customizationsSlice from './slices/customizations.slice';
import { stepSlice } from './slices/step.slice';

export const createStore = () => {
	return configureStore({
		reducer: {
			app: appSlice.reducer,
			step: stepSlice.reducer,
			session: sessionSlice.reducer,
			ui: uiSlice.reducer,
			persona: personaSlice.reducer,
			bankAccount: bankAccountSlice.reducer,
			reports: reportsSlice.reducer,
			flagsSlice: flagsSlice.reducer,
			error: errorsSlice.reducer,
			metadata: metadataSlice.reducer,
			configuration: configurationSlice.reducer,
			customizations: customizationsSlice.reducer
		},
		middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
	});
};

export const store = createStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type GetState = () => RootState;

export default store;
