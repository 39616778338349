import { RotatingLines } from 'react-loader-spinner';
import React, { FC } from 'react';
import { Box } from '@mui/material';

const SPINNER_WIDTH = '30';
const STROKE_COLOR = 'white';

interface Props {
	visible: boolean;
	width?: string;
	color?: string;
}

export const SubmittingSpinner: FC<Props> = ({ visible, color = STROKE_COLOR, width = SPINNER_WIDTH }) => {
	return (
		<Box id="submitting-spinner">
			<RotatingLines visible={visible} width={width} strokeColor={color} />
		</Box>
	);
};
