import React, { useCallback, useMemo } from 'react';
import { useAppSelector } from '../../app/hooks';
import { getCodesResultParams, getKontoCheckItemStatus, ICheckItem } from '../../types/Reports';
import { AccordionItem, CheckAccordionRow } from './CheckAccordionRow';
import { ItemStatus } from '../../types/constants';
import { KONTOCHECK } from '../../check/codes';
import { useTranslation } from 'react-i18next';
import { getCriteriaTitle } from '../hooks/useCriteriaTitle';
import { FlagsEnum, selectFlag } from '../../app/slices/flags.slice';

export const checkIfEnumExist = (object: ICheckItem) => {
	if (Object.keys(KONTOCHECK).some((v) => v === object.code)) {
		// @ts-ignore
		return KONTOCHECK[object.code];
	}
};

type KontoCheckListProps = {
	onCommentClick: (...args: any[]) => void;
	onInfoClick: (...args: any[]) => void;
};

const KontoCheckList = ({ onCommentClick, onInfoClick }: KontoCheckListProps) => {
	const kontoCheck = useAppSelector((state) => state.reports.kontoCheck);
	const userAssessmentFlag = useAppSelector((state) => selectFlag(state, FlagsEnum.ASSESSMENT_USER_INPUT));
	const { t, i18n } = useTranslation();
	const mapKontoItems = useCallback(
		(checks: Array<ICheckItem>): [Array<AccordionItem>, { verified: ItemStatus }] => {
			if (!kontoCheck) return undefined as unknown as any;

			const verifiedObject = { verified: ItemStatus.VERIFIED };
			const arr: Array<false | AccordionItem> = checks.map((check) => {
				const titleReport = checkIfEnumExist(check);
				if (!titleReport) return false;
				const itemStatus = getKontoCheckItemStatus(check);
				const itemSubtitle = getCodesResultParams(check, t);
				// if at least one check item has status that is not verified, konto is not verified
				if (itemStatus !== ItemStatus.VERIFIED) verifiedObject.verified = ItemStatus.UNVERIFIED;
				let retVal = {
					title: getCriteriaTitle(check, t),
					displayInfo: true,
					displayComment: userAssessmentFlag,
					displayDetails: false,
					commentFull: check.comment?.length > 0,
					status: itemStatus,
					onCommentClick: () => onCommentClick(titleReport, check.code, check.comment),
					onInfoClick: () => onInfoClick(check, 'KONTO')
				} as AccordionItem;
				if (itemSubtitle) {
					retVal = {
						...retVal,
						subtitle: itemSubtitle
					};
				}
				return retVal;
			});
			return [arr.filter((element) => element !== false) as Array<AccordionItem>, verifiedObject];
		},
		[kontoCheck]
	);

	const [mappedKontoItems, kontoVerified] = useMemo(() => {
		return mapKontoItems(kontoCheck);
	}, [mapKontoItems, i18n.language]);

	if (!kontoCheck || kontoCheck.length === 0) return <></>;

	return (
		<CheckAccordionRow
			title="KontoCheck"
			displayInfo={false}
			displayComment={false}
			openable={true}
			items={mappedKontoItems}
			verified={kontoVerified.verified}
			konto={true}
		/>
	);
};

export default KontoCheckList;
