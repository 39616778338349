import { AppDispatch, GetState } from '../store';
import { PhoneNumberVerificationService } from '../../services/PhoneNumberVerificationService';
import { changeStep } from '../slices/step.slice';
import { uiActions } from '../slices/ui.slice';

export const sendPhoneVerificationCode = (code: string) => {
	return async (dispatch: AppDispatch, getState: GetState): Promise<void> => {
		const service = new PhoneNumberVerificationService();
		const { status } = await service.submitConfirmationCode(code);

		if (status === 'PENDING') {
			dispatch(changeStep('PHONE_NUMBER_VERIFIED_STEP'));
		} else {
			dispatch(uiActions.setFinalStepType('ACCEPTED'));
			dispatch(changeStep('CHECK_FINISHED_STEP'));
		}
	};
};
