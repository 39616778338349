import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

type SessionState = {
	shortTermJWT: string;
	longTermJWT: string;
	legacyToken: string;
	validationJWT: string;
};

const initialState: SessionState = {
	shortTermJWT: '',
	longTermJWT: '',
	legacyToken: '',
	validationJWT: ''
};

const sessionSlice = createSlice({
	name: 'session',
	initialState: initialState,
	reducers: {
		setShortTermJWT(state, action: PayloadAction<string>) {
			state.shortTermJWT = action.payload;
		},
		setLongTermJWT(state, action: PayloadAction<string>) {
			state.longTermJWT = action.payload;
		},
		setLegacyToken(state, action: PayloadAction<string>) {
			state.legacyToken = action.payload;
		},
		setValidationJWT(state, action: PayloadAction<string>) {
			state.validationJWT = action.payload;
		}
	}
});

export const selectShortTermJWT = (state: RootState) => state.session.shortTermJWT;
export const selectLongTermJWT = (state: RootState) => state.session.longTermJWT;
export const selectLegacyToken = (state: RootState) => state.session.legacyToken;
export const selectValidationJWT = (state: RootState) => state.session.validationJWT;

export const sessionActions = sessionSlice.actions;

export const { setShortTermJWT } = sessionActions;

export default sessionSlice;
