import { EnumErrorType, ErrorObjectContent } from '../../types/ErrorsObject';

export const errorsMap: Map<EnumErrorType, ErrorObjectContent> = new Map([
	[
		EnumErrorType.REACT_ERROR,
		{
			title: 'technical error',
			texts: [
				'There is a technical problem, please try again in a few minutes',
				'If this error persists, please contact us at support@fincredible.at'
			]
		}
	],
	[
		EnumErrorType.KLARNA_ERROR,
		{
			title: 'technical error',
			texts: ['Der Login mit Klarna war nicht erfolgreich:']
		}
	],
	[
		EnumErrorType.APP_ERROR,
		{
			title: 'technical error',
			texts: [
				'There is a technical problem, please try again in a few minutes',
				'If this error persists, please contact us at support@fincredible.at'
			]
		}
	],
	[
		EnumErrorType.CHECK_EXPIRED,
		{
			title: 'KontoCheck expired',
			texts: [
				'This KontoCheck has expired and can no longer be performed',
				'If you still wish to perform an KontoCheck, please contact the client of this KontoCheck'
			]
		}
	],
	[
		EnumErrorType.VALIDATION_TOKEN_NO_LONGER_VALID_ERROR,
		{
			title: 'Link not valid anymore',
			texts: [
				'The link you used to access this page is no longer valid',
				'If you believe this to be an error, please contact us at support@fincredible.io'
			]
		}
	],
	[
		EnumErrorType.LOAD_ISSUES_ERROR,
		{
			title: 'Sorry lots of traffic',
			texts: ['We are currently dealing', 'Please reload in few minutes']
		}
	]
]);
