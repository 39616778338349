import React from 'react';
import ModalHyperlinkMarkdown from '../custom/ModalHyperlinkMarkdown';

interface PersonalConsentFooterCustomizationProps {
	customizationContent: string;
	handleMDLinkClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const PersonalConsentFooterCustomization = ({
	customizationContent,
	handleMDLinkClick
}: PersonalConsentFooterCustomizationProps) => {
	return <ModalHyperlinkMarkdown content={customizationContent} handleMDLinkClick={handleMDLinkClick} />;
};

export default PersonalConsentFooterCustomization;
