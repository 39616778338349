import React from 'react';
import { EnumErrorType } from '../../types/ErrorsObject';
import { useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import { selectLegacyToken } from '../../app/slices/session.slice';

const TRANSLATION_PREFIX = 'technical-error-page';
const REFERENCE_NUMBER_TRANS_KEY = `${TRANSLATION_PREFIX}.reference number`;

interface ReferenceNumberProps {
	type: EnumErrorType;
}

const ReferenceNumber = ({ type }: ReferenceNumberProps) => {
	const reference = useAppSelector(selectLegacyToken);
	const { t } = useTranslation();

	if (!reference || !NOT_REFERENCED_ERRORS.includes(type)) return null;

	return (
		<div className="error-step-reference">
			{t(REFERENCE_NUMBER_TRANS_KEY)}: {reference}
		</div>
	);
};

export default ReferenceNumber;

const NOT_REFERENCED_ERRORS: Array<EnumErrorType> = [EnumErrorType.KLARNA_ERROR, EnumErrorType.LOAD_ISSUES_ERROR];
