import React, { FC } from 'react';
import { Box } from '@mui/material';
import ReactInputVerificationCode from 'react-input-verification-code';

interface Props {
	onChange(code: string): void;
}

export const VerificationCodeInput: FC<Props> = ({ onChange }) => {
	return (
		<Box sx={containerSX}>
			<ReactInputVerificationCode length={6} autoFocus={true} onChange={onChange} />
		</Box>
	);
};

const containerSX = {
	width: '100%',
	'& .ReactInputVerificationCode__container': {
		width: '100%'
	}
};
