import useCustomization from '../../hooks/useCustomization';
import React, { MouseEventHandler } from 'react';
import DiscardResultsFooterMarkdown from '../custom/DiscardResultsMarkdown';
import { CustomizationKey } from '../../app/slices/customizations.slice';

interface DiscardResultsTextCustomizationProps {
	onClick: MouseEventHandler<HTMLAnchorElement>;
}

const COMPLETE_CHECK_TEXT: CustomizationKey = 'COMPLETE_CHECK_TEXT';

const DiscardResultsTextCustomization = ({ onClick }: DiscardResultsTextCustomizationProps) => {
	const customization = useCustomization(COMPLETE_CHECK_TEXT);
	if (!customization) return null;
	return <DiscardResultsFooterMarkdown content={customization} onClick={onClick} />;
};

export default DiscardResultsTextCustomization;
