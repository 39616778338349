import React from 'react';
import { EnumErrorType } from '../../types/ErrorsObject';
import KlarnaAdditionalContent from './KlarnaAdditionalContent';

interface AdditionalContentProps {
	type: EnumErrorType;
}

const AdditionalContent = ({ type }: AdditionalContentProps) => {
	const additionalContent = additionalContentRecord[type] || null;
	return <>{additionalContent}</>;
};

export default AdditionalContent;

const additionalContentRecord: Partial<Record<EnumErrorType, JSX.Element>> = {
	[EnumErrorType.KLARNA_ERROR]: <KlarnaAdditionalContent />
};
