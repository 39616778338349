import { SVGIcon } from './SVGIcon';
import React from 'react';

export function XCircle() {
	return (
		<SVGIcon
			width="206.153"
			height="206.153"
			viewBox="0 0 296.153 296.153"
			size={{ x1: '1', y1: '0.5', x2: '0', y2: '0.5' }}
		>
			<path
				id="x-circle"
				d="M148.076,0A148.076,148.076,0,1,1,0,148.076,148.076,148.076,0,0,1,148.076,0Zm0,26.923A121.153,121.153,0,1,0,269.23,148.076,121.153,121.153,0,0,0,148.076,26.923ZM115.944,97.057l1.265,1.117,30.867,30.854,30.867-30.854a13.461,13.461,0,0,1,20.192,17.769l-1.117,1.265-30.894,30.867,30.854,30.867a13.461,13.461,0,0,1-17.769,20.192l-1.265-1.117-30.867-30.894-30.867,30.854a13.461,13.461,0,0,1-20.192-17.769l1.117-1.265,30.894-30.867L98.175,117.209a13.461,13.461,0,0,1,17.769-20.192Z"
				fill="url(#linear-gradient)"
			/>
		</SVGIcon>
	);
}
