import React, { FC, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Header } from '../blank-check-input-step/Header';
import { PhoneNumberCountrySelect } from './PhoneNumberCountrySelect';
import { PhoneNumberPrefixSelect } from './PhoneNumberPrefixSelect';
import { TextInput } from '../../custom/TextInput';
import { z } from 'zod';
import { useAppTranslation } from '../../../i18n';
import { TFunction } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { sendPhoneNumber } from '../../../app/thunks/send-phone-number';
import { Heading } from './Heading';
import { ErrorMessage } from './ErrorMessage';
import { Country, COUNTRY_CODES, COUNTRY_PREFIXES, CountryCode } from '../../../constants/country-phone-number';
import {
	PhoneNumberInvalidFormatError,
	SendRateLimitExceededError
} from '../../../services/PhoneNumberVerificationService';
import { SubmitButton } from '../shared/SubmitButton';
import { selectPersonaPhoneNumber } from '../../../app/slices/persona.slice';
import { DecomposedPhoneNumber, decomposePhoneNumber } from '../../../utils/phone-number-decompose';
import TextCustomization from '../../customizations/TextCustomization';

const DEFAULT_SELECTED_COUNTRY: Country = 'AT';

export const PhoneNumberInputStep: FC = () => {
	const dispatch = useAppDispatch();
	const { t } = useAppTranslation();

	const { country: initCountry, prefix: initPrefix, rest: initPhoneNumber } = usePhoneNumber();

	const [country, setCountry] = useState<Country>(initCountry);
	const [prefix, setPrefix] = useState<string>(initPrefix);
	const [phoneNumber, setPhoneNumber] = useState<string>(initPhoneNumber);
	const [error, setError] = useState<string>('');
	const [touched, setTouched] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const handleCountryCodeChange = (code: CountryCode): void => {
		const entry = Object.entries(COUNTRY_CODES).find(([_, countryCode]) => countryCode === code);
		if (!entry) throw new Error(`Unsupported country code: ${code}`);
		const country = entry[0] as Country;
		setCountry(country);
		setPrefix(COUNTRY_PREFIXES[country][0]);

		if (touched) {
			const schema = createPhoneNumberSchema(country, t);
			const { error } = schema.safeParse(phoneNumber);
			if (error) {
				const message = error.errors[0].message;
				setError(message);
			} else {
				setError('');
			}
		}
	};

	const handlePrefixChange = (prefix: string): void => {
		setPrefix(prefix);
	};

	const handlePhoneNumberChange = (value: string): void => {
		setPhoneNumber(value);

		const schema = createPhoneNumberSchema(country, t);
		const { error } = schema.safeParse(value);
		if (error) {
			const message = error.errors[0].message;
			setError(message);
		} else {
			setError('');
		}
	};

	const handlePhoneNumberBlur = (): void => {
		setTouched(true);
	};

	const handleSubmit = async (): Promise<void> => {
		try {
			setSubmitting(true);
			const phone = code + prefix + phoneNumber;
			await dispatch(sendPhoneNumber(phone));
		} catch (error) {
			if (error instanceof PhoneNumberInvalidFormatError) {
				setError(t('errors.phone-number-format-error'));
				return;
			}

			if (error instanceof SendRateLimitExceededError) {
				setError(t('steps.phone-number-input-step.sms-send-rate-limit-exceeded'));
				return;
			}

			setError(t('errors.technical-issue'));
		} finally {
			setSubmitting(false);
		}
	};

	const code = COUNTRY_CODES[country];
	const prefixes = COUNTRY_PREFIXES[country];

	const isButtonEnabled = error === '' && phoneNumber !== '';
	return (
		<Box id="phone-number-input-step">
			<Header />
			<Heading />
			<PhoneNumberCountrySelect code={code} onChange={handleCountryCodeChange} />
			<Grid container spacing={1}>
				<Grid item xs={4} lg={4} style={{ paddingTop: '0' }}>
					<PhoneNumberPrefixSelect prefix={prefix} options={prefixes} onChange={handlePrefixChange} />
				</Grid>
				<Grid item xs={8} lg={8} style={{ paddingTop: '0' }}>
					<TextInput
						id="phone-number"
						name="phone-number"
						label={t('inputs.phone-number')}
						value={phoneNumber}
						error={touched && !!error}
						onChange={handlePhoneNumberChange}
						onBlur={handlePhoneNumberBlur}
					/>
				</Grid>
				<Grid item xs={12} lg={12} sx={{ height: '40px' }}>
					<ErrorMessage error={touched ? error : undefined} />
				</Grid>
			</Grid>
			<Box sx={{ mt: 1 }}>
				<SubmitButton disabled={!isButtonEnabled} submitting={submitting} onSubmit={handleSubmit} />
			</Box>
			<Box sx={infoSX}>
				<TextCustomization id="PHONE_NUMBER_INPUT_PAGE_ADDITIONAL_INFO" />
			</Box>
		</Box>
	);
};

const infoSX = {
	fontWeight: '400',
	fontSize: 'small',
	textAlign: 'center',
	mt: 2,
	color: '#a7a7a7'
};

const usePhoneNumber = (): DecomposedPhoneNumber => {
	const phoneNumber = useAppSelector(selectPersonaPhoneNumber);
	const { country, prefix, rest } = decomposePhoneNumber(phoneNumber || '');

	const initCountry = country || DEFAULT_SELECTED_COUNTRY;
	const initPrefix = prefix || COUNTRY_PREFIXES[initCountry][0];
	return {
		country: initCountry,
		prefix: initPrefix,
		rest: rest || ''
	};
};

const createPhoneNumberSchema = (country: Country, t: TFunction) => {
	const max = getCountryPhoneNumberMaxLength(country);
	const min = 2;
	return z
		.string()
		.regex(/^[0-9]*$/, t('errors.digits-only', { input: t('inputs.phone-number') }))
		.max(max, t('errors.max-number-digits-exceeded', { input: t('inputs.phone-number'), max }))
		.min(min, t('errors.min-number-digits-exceeded', { input: t('inputs.phone-number'), min }));
};

const getCountryPhoneNumberMaxLength = (country: Country): number => {
	return country === 'AT' ? 12 : 15;
};
