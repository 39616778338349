import axios from 'axios';
import store from '../app/store';
import { selectShortTermJWT } from '../app/slices/session.slice';
import { attachRetryInterceptor, GatewayErrorInterceptor } from '../api/response.interceptor';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL as string;
const CHECK_REPORT_API = 'check/report/';
const CHECK_REQUEST_API = 'check/request/';
const AUTHORIZATION_HEADER = 'Authorization';

const requestHeaders = {
	'Content-Type': 'application/x-www-form-urlencoded',
	'Check-Origin': 'popup'
};

export const axios_instance = axios.create({
	baseURL: REACT_APP_API_URL,
	headers: requestHeaders
});

export const checkReportAxiosInstance = axios.create({
	baseURL: REACT_APP_API_URL + CHECK_REPORT_API,
	headers: requestHeaders
});

checkReportAxiosInstance.interceptors.request.use((config) => {
	const state = store.getState();
	const JWT = selectShortTermJWT(state);
	config.headers.set(AUTHORIZATION_HEADER, JWT);

	return config;
});
attachRetryInterceptor(axios_instance);
axios_instance.interceptors.response.use(null, GatewayErrorInterceptor.onRejected);

attachRetryInterceptor(checkReportAxiosInstance);
checkReportAxiosInstance.interceptors.response.use(null, GatewayErrorInterceptor.onRejected);
