import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { Ajax } from '@newrelic/browser-agent/features/ajax';
import { JSErrors } from '@newrelic/browser-agent/features/jserrors';
import { Metrics } from '@newrelic/browser-agent/features/metrics';
import { PageViewEvent } from '@newrelic/browser-agent/features/page_view_event';
import { PageViewTiming } from '@newrelic/browser-agent/features/page_view_timing';
import { SessionTrace } from '@newrelic/browser-agent/features/session_trace';
import { Spa } from '@newrelic/browser-agent/features/spa';

const options = {
	info: {
		beacon: 'bam.eu01.nr-data.net',
		errorBeacon: 'bam.eu01.nr-data.net',
		licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
		applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID
	},
	loader_config: {
		accountID: process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID,
		trustKey: process.env.REACT_APP_NEW_RELIC_TRUST_KEY,
		agentID: process.env.REACT_APP_NEW_RELIC_AGENT_ID,
		licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
		applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID
	},
	features: [JSErrors, Ajax, Metrics, PageViewTiming, PageViewEvent, SessionTrace, Spa]
};

const NewRelicAgent = new BrowserAgent(options);

export default NewRelicAgent;
