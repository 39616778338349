import React from 'react';
import { useTranslation } from 'react-i18next';
import { RESULTS_PAGE_PREFIX } from '../../utils/translationPagePrefix';

type CheckStatusInfoProps = {
	konto?: boolean;
};

const TRANSLATION_PREFIX = `${RESULTS_PAGE_PREFIX}.color-explanations`;

export const CheckStatusInfo = ({ konto = true }: CheckStatusInfoProps) => {
	const { t } = useTranslation();

	const MEANING_TEXT = t(`${TRANSLATION_PREFIX}.meaning`);
	const USER_UNVERIFIED_TEXT = t(`${TRANSLATION_PREFIX}.self-declaration`);
	const VERIFIED_TEXT = t(`${TRANSLATION_PREFIX}.verified/fulfilled`);
	const NOT_VERIFIED_TEXT = t(`${TRANSLATION_PREFIX}.not-verified`);
	const ALL_FIGURES_TEXT = t(`${RESULTS_PAGE_PREFIX}.all figues in EUR per month`);

	return (
		<div className="check-status-container-wrapper">
			<div className="check-status-container">
				<div className="check-status-container-title">
					<span>{MEANING_TEXT}</span>
				</div>
				<div className="check-status-container-content">
					<div className="verified-status-container">
						<StatusContainer title={VERIFIED_TEXT} status="verified" />
					</div>
					<div className="others-status-container">
						<StatusContainer title={USER_UNVERIFIED_TEXT} status="user_unverified" />
						<StatusContainer
							title={NOT_VERIFIED_TEXT}
							status="analytics_unverified"
							style={{
								marginLeft: '15px'
							}}
						/>
					</div>
					{konto ? (
						<></>
					) : (
						<div className="currency-text-container">
							<span>{ALL_FIGURES_TEXT}</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

type CheckItemStatus = 'verified' | 'analytics_unverified' | 'user_unverified';

type StatusContainerProps = {
	title: string;
	status: CheckItemStatus;
	style?: object;
};

const colorMap: Map<CheckItemStatus, string> = new Map([
	['verified', '#69E2AB'],
	['analytics_unverified', '#FE7E28'],
	['user_unverified', '#0082E1']
]);

const StatusContainer = ({ title, status, style = {} }: StatusContainerProps) => {
	return (
		<div
			className="status-container-colored"
			style={{
				borderLeft: `5px solid ${colorMap.get(status)}`,
				...style
			}}
		>
			<span>{title}</span>
		</div>
	);
};
