export enum DateFormat {
	VIEW_FORMAT = 'DD.MM.YYYY',
	API_FORMAT = 'YYYY-MM-DD'
}

export enum LimitCheckPeriod {
	MAX_DATE = 16,
	TIME_METRIC = 'years'
}

interface IDateConverter {
	convert(date: string, format: DateFormat): string;
}

class DateConverter implements IDateConverter {
	/**
	 * Convert date as string to specific format (e.g. 2022-12-20 to 12.20.2022)
	 * If date string is in API_FORMAT it will be converted in VIEW_FORMAT and vice versa ONLY.
	 * @param date - string representing date containing only day,month and year
	 * @param format - date format (DateFormat enum) default: VIEW_FORMAT
	 */
	convert(date: string, format: DateFormat = DateFormat.VIEW_FORMAT): string {
		let delimeter;
		switch (format) {
			case DateFormat.API_FORMAT:
				delimeter = '-';
				return date.split('.').reverse().join(delimeter);
			case DateFormat.VIEW_FORMAT:
				delimeter = '.';
				return date.split('-').reverse().join(delimeter);
			default:
				throw new Error('Unsupported date format');
		}
	}
}

export default new DateConverter();
