import React, { FC, ReactNode } from 'react';
import { useAppTranslation } from '../../../i18n';
import StepButton from '../StepButton';
import { SubmittingSpinner } from './SubmittingSpinner';

interface Props {
	disabled: boolean;
	submitting: boolean;
	text?: ReactNode;
	onSubmit(): void;
}

export const SubmitButton: FC<Props> = ({ disabled, submitting, onSubmit, text }) => {
	const { t } = useAppTranslation();

	const handleSubmit = submitting ? () => {} : onSubmit;

	const content = text || t('button.next');
	const title = submitting ? <SubmittingSpinner visible={true} /> : content;
	return <StepButton disabled={disabled} title={title} onClick={handleSubmit} />;
};
