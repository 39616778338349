import React, { FC, useState } from 'react';
import DE_AT_FLAG from '../../assets/images/flag_au_de.svg';
import EN_US_FLAG from '../../assets/images/English_language.svg';
import { useTranslation } from 'react-i18next';
import LanguageMenu from './LanguageMenu';
import LanguageSelectContainer from './LanguageSelectContainer';
import { Language } from '../../app/slices/customizations.slice';
import { Box } from '@mui/material';
import { CheckService } from '../../services/CheckService';
import NewRelicAgent from '../../newrelic';

export interface LanguageOption {
	language: Language;
	src: string;
}

const LANGUAGE_OPTIONS: Array<LanguageOption> = [
	{ language: 'en', src: EN_US_FLAG },
	{ language: 'de', src: DE_AT_FLAG }
];

const LanguageSelect: FC = () => {
	const {
		i18n: { changeLanguage, language }
	} = useTranslation();

	const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

	const isLanguageSelectOpen = Boolean(anchorElement);
	const currentLanguageOption = deriveCurrentLanguageOption(language) || LANGUAGE_OPTIONS[1];

	const handleOpenLanguageSelect = (event: React.MouseEvent<HTMLDivElement>) => {
		if (isLanguageSelectOpen) setAnchorElement(null);
		else setAnchorElement(event.currentTarget);
	};

	const handleCloseLanguageSelect = () => {
		setAnchorElement(null);
	};

	const handleSelectLanguage = (newLanguage: Language) => {
		const isSameLanguage = newLanguage === language;
		if (isSameLanguage) return;

		new CheckService().changeLanguage(newLanguage).catch((error: Error) => {
			NewRelicAgent.noticeError(error, { ...error });
		});

		changeLanguage(newLanguage)
			.then(() => {
				document.documentElement.lang = newLanguage;
			})
			.catch(() => {});
		handleCloseLanguageSelect();
	};

	return (
		<Box id="language-wrapper-container">
			<LanguageSelectContainer
				onClick={handleOpenLanguageSelect}
				open={isLanguageSelectOpen}
				language={language as Language}
				src={currentLanguageOption.src}
			/>
			<LanguageMenu
				isOpen={isLanguageSelectOpen}
				onClose={handleCloseLanguageSelect}
				options={LANGUAGE_OPTIONS}
				onLanguageChange={handleSelectLanguage}
				language={currentLanguageOption.language}
				anchor={anchorElement}
			/>
		</Box>
	);
};

export default LanguageSelect;

function deriveCurrentLanguageOption(currentI18NLanguage: string): LanguageOption | undefined {
	return LANGUAGE_OPTIONS.find((languageOption) => {
		const { language } = languageOption;
		return testLanguageEquality(currentI18NLanguage, language);
	});
}

// check language equality ignoring dialects (example: 'en-US' is equal to 'en')
function testLanguageEquality(inputLanguageWithDialect: string, lang: Language) {
	return new RegExp(`${lang}`).test(inputLanguageWithDialect);
}
