import React from 'react';
import { CheckAccordionRow } from './CheckAccordionRow';
import { useAppSelector } from '../../app/hooks';
import { selectNamecheck } from '../../app/slices/report.slice';
import { FlagsEnum, selectFlag } from '../../app/slices/flags.slice';

type IDCheckComponentProps = {
	onCommentClick: (...args: any[]) => void;
	onInfoClick: (...args: any[]) => void;
};

const IDCheckComponent = ({ onCommentClick, onInfoClick }: IDCheckComponentProps) => {
	const reportCommentTextIDCheck = useAppSelector((state) => state.ui.reportCommentTextIDCheck);
	const userAssessmentFlag = useAppSelector((state) => selectFlag(state, FlagsEnum.ASSESSMENT_USER_INPUT));
	const namecheck = useAppSelector(selectNamecheck);

	if (!namecheck) return <></>;

	const isVerified = isNamecheckVerified(namecheck);
	const indicatorColor = getNamecheckIndicatorColor(isVerified);

	return (
		<CheckAccordionRow
			title="ID Check"
			displayDetails={false}
			openable={false}
			onCommentClick={onCommentClick}
			onInfoClick={() => onInfoClick(namecheck)}
			commentFull={reportCommentTextIDCheck?.length > 0}
			displayComment={userAssessmentFlag}
			displayInfo={true}
			indicatorColor={indicatorColor}
		/>
	);
};

export default IDCheckComponent;

const VERIFIED = 'verified';
const UNVERIFIED = 'unverified';

function isNamecheckVerified(namecheck: any) {
	const verified = namecheck.result;
	if (verified === undefined || verified === UNVERIFIED) return false;
	return verified === VERIFIED;
}

function getNamecheckIndicatorColor(isVerified: boolean) {
	return isVerified ? undefined : 'yellow';
}
