import React from 'react';
import { useTranslation } from 'react-i18next';
import { RESULTS_PAGE_PREFIX } from '../../utils/translationPagePrefix';

interface ResultsSummaryTextProps {
	onDetailsClick(): void;
}

const translationKey = `${RESULTS_PAGE_PREFIX}.summary.here's the details`;

const ResultsSummaryText = ({ onDetailsClick }: ResultsSummaryTextProps) => {
	const { t } = useTranslation();

	return (
		<div className="details-under-button">
			<span className="details-under-button-text">
				{t(translationKey)} &nbsp;
				<div
					style={{
						display: 'inline-block',
						width: 'auto'
					}}
					className="column-checkbox-button"
				>
					<button onClick={onDetailsClick}>Details</button>
				</div>
			</span>
		</div>
	);
};

export default ResultsSummaryText;
