import React from 'react';
import infoIcon from '../../assets/images/icons/info.svg';
import { TooltipCustomized } from './CheckAccordionRow';

interface ScoreWithLightsComponentProps {
	scoreText: string;
	lightText: string;
	lightColor: string;
	onInfoClick(): void;
}

export const ScoreWithLightsComponent = React.memo(
	({ scoreText, lightText, lightColor, onInfoClick }: ScoreWithLightsComponentProps) => {
		return (
			<div className="check-accordion-item">
				<div className={`lights-indicator-container ${lightColor}`}>
					<div className="score-title">
						<span>{lightText}</span>
					</div>
					<div className="score-value">{scoreText}</div>
				</div>
				<div className="item-options">
					<div className="item-options-button-container"></div>
					<div className="item-options-info">
						<TooltipCustomized>
							<img src={infoIcon} alt="Info" className="acc-icon" onClick={onInfoClick} />
						</TooltipCustomized>
					</div>
					<div className="item-options-comment"></div>
				</div>
			</div>
		);
	}
);
