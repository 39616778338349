import React from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material';
import chevron from '../../assets/images/icons/chevron-down.svg';

export interface SelectOption {
	value: string;
	label?: React.ReactNode;
	[key: string]: any;
}

type StyledSelectProps = {
	label?: string;
	id: string;
	initValue?: SelectOption;
	onChange?: (selected: SelectOption) => void;
	options: Array<SelectOption>;
	disabled: boolean;
};

const ID_PREFIX = 'styled-select-label-';

export const StyledSelect = React.memo(
	({ label, id, initValue, onChange, options, disabled = false }: StyledSelectProps) => {
		const [selected, setSelected] = React.useState<SelectOption>(initValue || { value: '' });
		const theme = useTheme();
		const labelId = `${ID_PREFIX}${id}`;

		const handleChange = (event: SelectChangeEvent) => {
			const value = event.target.value;
			const newOption = options.find((option) => option.value === value) as SelectOption;
			setSelected(newOption);
			if (onChange) onChange(newOption);
		};

		const currentOption = options.find((option) => option.value === selected.value);

		const style = {
			border: '1px solid #969696',
			borderRadius: '5px',
			background: 'background: #FFFFFF',
			width: '100%',
			'& .MuiInputLabel-root': {
				color: '#707070'
			},
			'& .Mui-focused': {
				color: '#707070'
			},
			'& .MuiInputBase-root': {
				'& .MuiInputBase-input:focus': {
					border: 'none'
				}
			},
			marginTop: '20px',
			'&:focus-within': {
				border: `1px solid ${theme.palette.primary.main} !important`
			}
		};

		return (
			<Box sx={{ width: '100%' }}>
				<FormControl
					variant="filled"
					fullWidth
					sx={style}
					className={`controlled-input select-component`}
					disabled={disabled}
				>
					<InputLabel
						style={{
							fontSize: '18px',
							color: '#707070'
						}}
						id={labelId}
					>
						{label}
					</InputLabel>
					<Select
						labelId={labelId}
						id={id}
						value={currentOption ? currentOption.value : ''}
						label={label}
						onChange={handleChange}
						IconComponent={(props) => (
							<img
								src={chevron}
								alt={'chevron'}
								{...props}
								className={`chevron-icon ${props.className}`}
							/>
						)}
					>
						{options.map((option, index) => (
							<MenuItem value={option.value} key={index + option.value}>
								{option.label || option.value}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
		);
	}
);
