import React from 'react';
import { useAppSelector } from '../../../app/hooks';
import { BankApp } from '@fncrdbl/bank-sdk/dist/esm';
import { selectBankSdkCustomizations } from '../../../app/slices/customizations.slice';
import { useBankAppConfiguration } from '../../../hooks/useBankAppConfiguration';
import useForwardingSubtitleCustomization from '../../../hooks/useForwardingSubtitleCustomization';

export const BankInterface = () => {
	const configuration = useBankAppConfiguration();

	let bankSdkCustomizations = useAppSelector(selectBankSdkCustomizations) as any;
	bankSdkCustomizations = useForwardingSubtitleCustomization(bankSdkCustomizations);

	return (
		<div id="bank-step" className="bank-interface-step">
			<BankApp configuration={configuration} customizations={bankSdkCustomizations} />
		</div>
	);
};

export default BankInterface;
