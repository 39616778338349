import React, { useState } from 'react';
import { ICheckItem } from '../../../types/Reports';
import { useAppDispatch } from '../../../app/hooks';
import KontoCheckList from '../KontoCheckList';
import HouseholdList from '../HouseholdList';
import IDCheckComponent from '../IDCheckComponent';
import { ScoreComponentWrapper } from '../ScoreComponentWrapper';
import {
	CheckItemType,
	handlerOpenModalReportComments,
	handlerOpenModalReportCommentsWithValue,
	ModalEnum,
	uiActions
} from '../../../app/slices/ui.slice';
import { Box } from '@mui/material';
import { Header } from './Header';
import { SubmitButton } from '../shared/SubmitButton';
import DiscardCheckSegment from './DiscardCheckSegment';
import { acceptCheck } from '../../../app/thunks/accept-check';
import { discardCheck } from '../../../app/thunks/discard-check';
import { useAppTranslation } from '../../../i18n';
import { useObserveCheckAutoAccept } from '../../../hooks/useObserveCheckAutoAccept';

const CheckResultsStep = () => {
	const dispatch = useAppDispatch();
	const { t } = useAppTranslation();

	const [submitting, setSubmitting] = useState<boolean>(false);
	useObserveCheckAutoAccept();
	const _handlerOpenModalReportCommentsWithValue = (
		title: string,
		code: string,
		comment?: string,
		newResult?: string
	) => {
		dispatch(handlerOpenModalReportCommentsWithValue(title, code, comment, newResult));
	};

	const _handlerOpenModalReportComments = (
		title: string,
		code: string,
		comment?: string,
		isIDCheck: boolean = false
	) => {
		dispatch(handlerOpenModalReportComments(title, code, comment, isIDCheck));
	};

	const handleOpenCodeInfoModal = (checkItem: ICheckItem, type: CheckItemType) => {
		dispatch(
			uiActions.setSelectedCheckItem({
				item: checkItem,
				type
			})
		);
		dispatch(uiActions.toggleModal(ModalEnum.CODE_INFO_MODAL));
	};

	const handleAcceptCheck = async (): Promise<void> => {
		setSubmitting(true);
		try {
			await dispatch(acceptCheck());
		} finally {
			setSubmitting(false);
		}
	};

	const handleDiscardCheck = async (): Promise<void> => {
		setSubmitting(true);
		try {
			await dispatch(discardCheck());
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<Box id="check-results-step">
			<Header />
			<Box className="personal-check-info-step" sx={{ mt: 3 }}>
				<ScoreComponentWrapper />
				<IDCheckComponent
					onCommentClick={() => _handlerOpenModalReportComments('ID Check', 'NAMECHECK', '', true)}
					onInfoClick={handleOpenCodeInfoModal}
				/>
				<KontoCheckList
					onCommentClick={_handlerOpenModalReportComments}
					onInfoClick={handleOpenCodeInfoModal}
				/>
				<HouseholdList
					onCommentClick={_handlerOpenModalReportCommentsWithValue}
					onInfoClick={handleOpenCodeInfoModal}
				/>
			</Box>
			<SubmitButton
				disabled={false}
				submitting={submitting}
				text={t('button.complete')}
				onSubmit={handleAcceptCheck}
			/>
			<DiscardCheckSegment onDiscard={handleDiscardCheck} />
		</Box>
	);
};

export default CheckResultsStep;
