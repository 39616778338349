import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as HelpIcon } from '../../assets/images/icons/help-circle.svg';
import { useTranslation } from 'react-i18next';
import LanguageSelect from './LanguageSelect';
import { useTheme } from '@mui/material';
import { ModalEnum, uiActions } from '../../app/slices/ui.slice';

const FooterNavigation = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const theme = useTheme();

	const handlerOpenInfoModal = () => {
		dispatch(uiActions.toggleModal(ModalEnum.INFO_MODAL));
	};

	const handlerOpenPrivacyModal = () => {
		dispatch(uiActions.toggleModal(ModalEnum.PRIVACY_MODAL));
	};

	const handlerOpenAGBModal = () => {
		dispatch(uiActions.toggleModal(ModalEnum.AGB_MODAL));
	};

	return (
		<Fragment>
			<div className="footer-navigation" style={{ color: `${theme.palette.primary.main}` }}>
				<div className="footer-navigation-block">
					<LanguageSelect />
					<div onClick={handlerOpenInfoModal} className="help-link">
						<HelpIcon className="help-icon" style={{ fill: `${theme.palette.primary.main}` }} />
						<span className="help-link-text">{t('footer.help')}</span>
					</div>
				</div>
				<div className="footer-navigation-block">
					<div className="privacy-link" onClick={handlerOpenPrivacyModal}>
						{t('footer.privacy')}
					</div>
					<div className="conditions-link" onClick={handlerOpenAGBModal}>
						{t('footer.conditions')}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default FooterNavigation;
