import { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppDispatch } from '../../../app/hooks';
import { resendPhoneNumberVerificationCode } from '../../../app/thunks/resend-phone-number-verification-code';
import { useAppTranslation } from '../../../i18n';
import { AdvancedCustomization } from '../../customizations/AdvancedCustomization';

export const DEFAULT_COUNTDOWN_TIME_SECONDS = 60;
export const RESEND_VERIFICATION_CODE_TIMER = 'resend-verification-code-timer';

export const ResendVerificationCodeSegment: FC = () => {
	const dispatch = useAppDispatch();
	const { t } = useAppTranslation();

	const [counter, setCounter] = useState<number>(getPreservedResendTimer);
	const [resending, setResending] = useState<boolean>(false);

	useEffect(() => {
		const id = setInterval(() => {
			setCounter((counter) => {
				const next = counter - 1;
				if (next === 0) clearInterval(id);

				sessionStorage.setItem(RESEND_VERIFICATION_CODE_TIMER, next.toString());
				return next <= 0 ? 0 : next;
			});
		}, 1000);

		return () => {
			clearInterval(id);
		};
	}, []);

	const handleResend = async () => {
		if (counter > 0 || resending) return;

		try {
			setResending(true);
			await dispatch(resendPhoneNumberVerificationCode());
			sessionStorage.removeItem(RESEND_VERIFICATION_CODE_TIMER);
		} catch (error) {
		} finally {
			setResending(false);
		}
	};

	const linkStyle = counter === 0 ? { cursor: 'pointer' } : {};
	return (
		<Box sx={boxSX}>
			<Typography sx={textSX}>
				<AdvancedCustomization
					id="PHONE_NUMBER_VERIFICATION_CODE_INPUT_STEP_CONTENT_1"
					components={{
						code: ({ children, ...props }) => {
							if (counter > 0) {
								return (
									<span {...props}>
										{children} ({counter} {t('steps.phone-number-verification-step.seconds')})
									</span>
								);
							}

							return (
								<u style={linkStyle} onClick={handleResend} {...props}>
									{children}
								</u>
							);
						}
					}}
				/>
			</Typography>
		</Box>
	);
};

const getPreservedResendTimer = (): number => {
	const timer = sessionStorage.getItem(RESEND_VERIFICATION_CODE_TIMER);
	if (!timer) return DEFAULT_COUNTDOWN_TIME_SECONDS;
	if (isNaN(Number(timer))) return DEFAULT_COUNTDOWN_TIME_SECONDS;
	return Number(timer);
};

const boxSX = { width: '100%', textAlign: 'center', mt: 5 };
const textSX = { fontSize: 'medium' };
