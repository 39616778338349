import React, { useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { useDispatch } from 'react-redux';
import { ICheckItem } from '../../types/Reports';
import { LightsOnlyWrapper } from './LightOnlyComponent';
import { ScoreOnlyWrapper } from './ScoreOnlyComponent';
import { WithCriteriaModalHOC } from './WithCriteriaModalHOC';
import ScoreWithLightsWrapper from './ScoreWithLightsWrapper';
import { ModalEnum, uiActions } from '../../app/slices/ui.slice';

type WithScore = { score: ICheckItem };
type WithLights = { light: ICheckItem };
type ScoreComponentProps = Partial<WithScore & WithLights>;

const SCORE_CODE = 'SCORE';
const LIGHTS_CODE = 'LIGHTS';

const ScoreComponent = (props: ScoreComponentProps) => {
	const isCriteriaModalOpen = useAppSelector((state) => state.ui.criteriaInfoModalIsShown);
	const [codes, setCodes] = useState<Array<string>>([]);

	const dispatch = useDispatch();

	const toggleModal = () => {
		dispatch(uiActions.toggleModal(ModalEnum.CRITERIA_INFO_MODAL));
	};

	const handleModalClose = () => {
		toggleModal();
		setCodes([]);
	};

	const hasScore = hasScoreTypeGuard(props);
	const hasLights = hasLightTypeGuard(props);

	const handleOpenModal = () => {
		const codes: Array<string> = [];
		if (hasLights) codes.push(LIGHTS_CODE);
		if (hasScore) codes.push(SCORE_CODE);
		setCodes(codes);
		toggleModal();
	};

	// if both score and lights are present
	if (hasScore && hasLights) {
		return (
			<WithCriteriaModalHOC isCriteriaModalOpen={isCriteriaModalOpen} onClose={handleModalClose} codes={codes}>
				<ScoreWithLightsWrapper {...props} onInfoClick={handleOpenModal} />
			</WithCriteriaModalHOC>
		);
	}

	// if only light is present
	if (hasLights) {
		return (
			<WithCriteriaModalHOC isCriteriaModalOpen={isCriteriaModalOpen} onClose={handleModalClose} codes={codes}>
				<LightsOnlyWrapper light={props.light} onInfoClick={handleOpenModal} />
			</WithCriteriaModalHOC>
		);
	}

	// only score is present
	if (hasScore) {
		return (
			<WithCriteriaModalHOC isCriteriaModalOpen={isCriteriaModalOpen} onClose={handleModalClose} codes={codes}>
				<ScoreOnlyWrapper score={props.score} onInfoClick={handleOpenModal} />
			</WithCriteriaModalHOC>
		);
	}

	// SCORE+LIGHTS not available
	return <></>;
};

export default ScoreComponent;

function hasScoreTypeGuard(props: ScoreComponentProps): props is WithScore {
	return props.score !== undefined;
}

function hasLightTypeGuard(props: ScoreComponentProps): props is WithLights {
	return props.light !== undefined;
}
