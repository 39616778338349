import useCustomization from '../../hooks/useCustomization';
import LegalDocsMarkdown from '../custom/LegalDocsMarkdown';
import React from 'react';
import { useSelector } from 'react-redux';
import { CustomizationKey } from '../../app/slices/customizations.slice';

const CONSENT_COLLECTION_KEY: CustomizationKey = 'CONSENT_COLLECTION_AND_PROCESSING_LONG';
const CONSENT_FORWARDING_KEY: CustomizationKey = 'CONSENT_FORWARDING_AND_RESULTS_LONG';

const ConsentModalCustomization = () => {
	const collectingDataConsent = useSelector((state: any) => state.ui.collectingDataConsent);
	const collectingForwardDataConsent = useSelector((state: any) => state.ui.collectingForwardDataConsent);

	const customizationsConsentCollectionDataLong = useCustomization(CONSENT_COLLECTION_KEY);
	const customizationsConsentForwardingDataLong = useCustomization(CONSENT_FORWARDING_KEY);

	return (
		<React.Fragment>
			{collectingDataConsent && customizationsConsentCollectionDataLong && (
				<LegalDocsMarkdown content={customizationsConsentCollectionDataLong} />
			)}
			{collectingForwardDataConsent && customizationsConsentForwardingDataLong && (
				<LegalDocsMarkdown content={customizationsConsentForwardingDataLong} />
			)}
		</React.Fragment>
	);
};

export default ConsentModalCustomization;
