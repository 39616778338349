import { useTranslation } from 'react-i18next';
import { ICheckItem } from '../../types/Reports';
import React from 'react';
import { getCriteriaPlaceholders, getCriteriaTitle } from './useCriteriaTitle';
import { HTMLTemplateParserFactory } from '../../utils/HTMLTemplateParser';

interface UseCriteriaCustomHook {
	(criteria: ICheckItem): [React.ReactElement, React.ReactElement];
}

export const useCriteria: UseCriteriaCustomHook = (criteria: ICheckItem) => {
	const { t } = useTranslation();

	return getCriteria(criteria, t);
};

export function getCriteria(criteria: ICheckItem, t: any): [React.ReactElement, React.ReactElement] {
	const criteriaTitleComponent = getCriteriaTitle(criteria, t);

	const placeholders = getCriteriaPlaceholders(criteria.code);
	const template = t(getCodeExplanationTranslationKey(criteria.code));

	const criteriaExplanationTextComponent: React.ReactElement = HTMLTemplateParser.parseHTMLTemplate({
		sourceObject: criteria,
		template,
		placeholders
	});

	return [criteriaTitleComponent, criteriaExplanationTextComponent];
}

export function getCriteriaWithCodeOnly(code: string, t: any) {
	return getCriteria({ code } as ICheckItem, t);
}

const HTMLTemplateParser = HTMLTemplateParserFactory.createHTMLTemplateParser<ICheckItem>({
	enableCaching: false
});

const EXPLANATIONS_PREFIX = 'explanation-texts';

function getCodeExplanationTranslationKey(code: string) {
	return `${EXPLANATIONS_PREFIX}.${code}`;
}
