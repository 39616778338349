import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export type Step =
	| 'PHONE_NUMBER_INPUT_STEP'
	| 'PHONE_NUMBER_VERIFICATION_CODE_INPUT_STEP'
	| 'PHONE_NUMBER_SMS_DELIVERY_PENDING_STEP'
	| 'PHONE_NUMBER_SMS_DELIVERY_ERROR_STEP'
	| 'PHONE_NUMBER_VERIFIED_STEP'
	| 'EMAIL_INPUT_STEP'
	| 'EMAIL_VERIFICATION_PENDING_STEP'
	| 'EMAIL_VERIFIED_STEP'
	| 'LOADING_STEP'
	| 'PERSONA_INFORMATION_INPUT_STEP'
	| 'PERSONA_CONSENT_INPUT_STEP'
	| 'BANK_STEP'
	| 'WAITING_CHECK_RESULTS_STEP'
	| 'CHECK_RESULTS_STEP'
	| 'CHECK_RESULTS_SUMMARY_STEP'
	| 'CHECK_FINISHED_STEP'
	| 'ERROR_STEP';

interface State {
	current: Step;
	history: Array<Step>;
}

const initialState: State = {
	current: 'LOADING_STEP',
	history: []
};

export const stepSlice = createSlice({
	name: 'step',
	initialState,
	reducers: {
		changeStep(state, action: PayloadAction<Step>): void {
			const step = action.payload;
			if (state.current === step) {
				return;
			}
			state.history.push(state.current);
			state.current = step;
		}
	}
});

export const { changeStep } = stepSlice.actions;

export const selectCurrentStep = (state: RootState): Step => {
	return state.step.current;
};
