import { AppDispatch, GetState } from '../store';
import { selectPersona } from '../slices/persona.slice';
import { checkService } from '../../services/CheckService';
import { handleError } from '../slices/errors.slice';
import { changeStep } from '../slices/step.slice';
import { uiActions } from '../slices/ui.slice';

export const acceptCheck = () => {
	return async (dispatch: AppDispatch, getState: GetState): Promise<void> => {
		const state = getState();
		const persona = selectPersona(state);
		const assessments = persona.userAssessment.user;

		try {
			const data = assessments.length > 0 ? assessments : undefined;
			await checkService.acceptCheck(data);

			dispatch(uiActions.setFinalStepType('ACCEPTED'));
			dispatch(changeStep('CHECK_FINISHED_STEP'));
		} catch (error) {
			dispatch(handleError(error));
		}
	};
};
