import React, { FC } from 'react';
import { CustomizationKey } from '../../../app/slices/customizations.slice';
import { Checkbox, Paper, useTheme } from '@mui/material';
import PersonalConsentBoxCustomization from '../../customizations/PersonalConsentBoxCustomization';

interface ConsentBoxProps {
	onClick: () => void;
	onButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
	onCheckboxChange: (event: React.ChangeEvent) => void;
	checked: boolean;
	header: string;
	customizationKey: CustomizationKey;
	id: string;
}

export const ConsentBox: FC<ConsentBoxProps> = ({
	onClick,
	onButtonClick,
	checked,
	onCheckboxChange,
	customizationKey,
	id
}) => {
	const theme = useTheme();

	const paperSX = {
		padding: '5px',
		borderRadius: '5px',
		marginBottom: '10px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: '1px solid #969696',
		'&:hover': {
			border: `1px solid ${theme.palette.primary.main}`,
			cursor: 'pointer'
		}
	};
	return (
		<Paper elevation={0} style={{ backgroundColor: 'transparent' }} sx={paperSX}>
			<div className="collecting-data-card">
				<div className="row">
					<PersonalConsentBoxCustomization customizationKey={customizationKey} onClick={onClick} />
					<div className="column-checkbox">
						<div className="column-checkbox-button">
							<button onClick={onButtonClick}>Details</button>
						</div>
						<div className="column-checkbox-input" onClick={onClick}>
							<Checkbox
								checked={checked}
								onChange={onCheckboxChange}
								id={id}
								sx={{
									'& .MuiSvgIcon-root': { fontSize: 30 },
									border: '0'
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</Paper>
	);
};
