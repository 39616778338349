import { FC, ReactElement } from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectCurrentStep, Step } from '../../app/slices/step.slice';
import { PhoneNumberInputStep } from './phone-number-input/PhoneNumberInputStep';
import { PhoneNumberVerificationStep } from './phone-number-verification/PhoneNumberVerificationStep';
import { PhoneNumberVerifiedStep } from './phone-number-verified/PhoneNumberVerifiedStep';
import BlankCheckInputStep from './blank-check-input-step/BlankCheckInputStep';
import EmailSentStep from './email/EmalSentStep';
import EmailVerifiedStep from './email/EmailVerifiedStep';
import PersonaInformationInputStep from './persona-information-input/PersonaInformationInputStep';
import BankInterface from './bank/BankInterface';
import WaitingAnalyticsResults from './analytics-results/WaitingAnalyticsResults';
import Loading from '../custom/Loading';
import FinalStep from './final/FinalStep';
import ErrorStepsWrapper from '../error/ErrorStepsWrapper';
import { PersonaConsentStep } from './persona-consent/PersonaConsentStep';
import { PhoneNumberWaitingSmsDeliveryStep } from './phone-number-waiting-sms-delivery/PhoneNumberWaitingSmsDeliveryStep';
import { PhoneNumberSmsDeliveryErrorStep } from './phone-number-sms-delivery-error/PhoneNumberSmsDeliveryErrorStep';
import CheckResultsStep from './check-results/CheckResultsStep';
import { CheckResultsSummaryStep } from './check-results-summary/CheckResultsSummaryStep';

export const CurrentStep: FC = () => {
	const currentStep = useAppSelector(selectCurrentStep);

	const component = STEP_COMPONENT_MAP.get(currentStep);
	if (!component) throw new Error(`Unsupported current step: ${currentStep}`);

	return component;
};

export const STEP_COMPONENT_MAP: Map<Step, ReactElement> = new Map([
	['PHONE_NUMBER_INPUT_STEP', <PhoneNumberInputStep />],
	['PHONE_NUMBER_VERIFICATION_CODE_INPUT_STEP', <PhoneNumberVerificationStep />],
	['PHONE_NUMBER_SMS_DELIVERY_PENDING_STEP', <PhoneNumberWaitingSmsDeliveryStep />],
	['PHONE_NUMBER_SMS_DELIVERY_ERROR_STEP', <PhoneNumberSmsDeliveryErrorStep />],
	['PHONE_NUMBER_VERIFIED_STEP', <PhoneNumberVerifiedStep />],
	['EMAIL_INPUT_STEP', <BlankCheckInputStep />],
	['EMAIL_VERIFICATION_PENDING_STEP', <EmailSentStep />],
	['EMAIL_VERIFIED_STEP', <EmailVerifiedStep />],
	['PERSONA_INFORMATION_INPUT_STEP', <PersonaInformationInputStep />],
	['PERSONA_CONSENT_INPUT_STEP', <PersonaConsentStep />],
	['BANK_STEP', <BankInterface />],
	['WAITING_CHECK_RESULTS_STEP', <WaitingAnalyticsResults />],
	['CHECK_RESULTS_STEP', <CheckResultsStep />],
	['CHECK_RESULTS_SUMMARY_STEP', <CheckResultsSummaryStep />],
	['LOADING_STEP', <Loading />],
	['CHECK_FINISHED_STEP', <FinalStep />],
	['ERROR_STEP', <ErrorStepsWrapper />]
]);
