import React, { FC } from 'react';
import { useAppTranslation } from '../../../i18n';
import { Select } from '../../custom/Select';

interface Props {
	prefix: string;
	options: Array<string>;
	onChange(prefix: string): void;
}

export const PhoneNumberPrefixSelect: FC<Props> = ({ prefix, options, onChange }) => {
	const { t } = useAppTranslation();

	const label = t('inputs.prefix');
	return (
		<Select
			id="country-prefix"
			name="country-prefix"
			label={label}
			options={options.map((option) => ({ value: option, label: option }))}
			value={prefix}
			onChange={onChange}
		/>
	);
};
