export enum Env {
	'REACT_APP_ENV' = 'REACT_APP_ENV',
	'REACT_APP_MAX_REQUEST_RETRIES' = 'REACT_APP_MAX_REQUEST_RETRIES',
	'REACT_APP_REQUEST_RETRY_DELAY' = 'REACT_APP_REQUEST_RETRY_DELAY'
}

export enum AppEnv {
	DEV = 'DEV',
	SANDBOX = 'SANDBOX',
	LIVE = 'LIVE'
}
