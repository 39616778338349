import { Assessment } from '../../types/Persona';
import qs from 'qs';
import { AxiosResponse } from 'axios';
import { checkReportAPI } from '../../services/CheckReportService';
import { uiActions } from '../slices/ui.slice';
import { handleError } from '../slices/errors.slice';
import { isErrorResponse } from './init-state';
import { AppDispatch } from '../store';
import { initializeFinalStep } from './init-current-step';

export type CompleteStatus = 'accepted' | 'discarded';

export type CompleteCheckPayload = {
	status: CompleteStatus;
	userAssessment?: string;
};

export const completeCheck = (status: CompleteStatus, userAssessment?: Assessment[]) => {
	const payload = createCompletionPayload(status, userAssessment);
	return async (dispatch: AppDispatch) => {
		try {
			const response: AxiosResponse = await checkReportAPI.putReportData(payload);

			if (isErrorResponse(response)) {
				const errors = response.data.errors;
				const error = new Error(errors);
				dispatch(handleError(error));
				return;
			}

			const type = status === 'accepted' ? 'ACCEPTED' : 'DISCARDED';
			dispatch(uiActions.setFinalStepType(type));
			dispatch(initializeFinalStep());
		} catch (error: any) {
			dispatch(handleError(error));
		}
	};
};

function createCompletionPayload(status: CompleteStatus, userAssessment?: Assessment[]) {
	const payload: CompleteCheckPayload = { status };

	if (status === 'accepted' && userAssessment) {
		payload.userAssessment = JSON.stringify(userAssessment);
	}

	return qs.stringify(payload);
}
