import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';

interface PhoneNumberCountryOptionProps {
	text: string;
	image?: {
		src: string;
		alt: string;
	};
}

export const PhoneNumberCountryOption: FC<PhoneNumberCountryOptionProps> = ({ text, image }) => {
	return (
		<Stack direction="row" spacing={1}>
			{image && <img src={image.src} alt={image.alt} style={{ width: '35px', padding: '3px 0' }} />}
			<Typography sx={{ padding: '3px 0' }}>{text}</Typography>
		</Stack>
	);
};
