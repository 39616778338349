import { useTranslation } from 'react-i18next';
import { ICheckItem } from '../../types/Reports';
import { criteriaPlaceholderObjects } from '../../utils/criteria-template-objects';
import { HTMLTemplateParserFactory } from '../../utils/HTMLTemplateParser';

export const useCriteriaTitle = (criteria: ICheckItem) => {
	const { t } = useTranslation();

	return getCriteriaTitle(criteria, t);
};

export function getCriteriaTitle(criteria: ICheckItem, t: any) {
	const titleTemplate = t(getCriteriaTranslationKey(criteria.code));
	let criteriaTitle = <>{titleTemplate}</>;

	const placeholders = getCriteriaPlaceholders(criteria.code);
	if (placeholders) {
		criteriaTitle = HTMLTemplateParser.parseHTMLTemplate({
			sourceObject: criteria,
			template: titleTemplate,
			placeholders
		});
	}

	return criteriaTitle;
}

export function getCriteriaTitleWithCodeOnly(code: string, t: any) {
	return getCriteriaTitle({ code } as ICheckItem, t);
}

const CODES_PREFIX = 'codes';

const HTMLTemplateParser = HTMLTemplateParserFactory.createHTMLTemplateParser<ICheckItem>({
	enableCaching: false
});

export function getCriteriaPlaceholders(code: string) {
	return criteriaPlaceholderObjects[code];
}

export function getCriteriaTranslationKey(code: string) {
	return `${CODES_PREFIX}.${code}`;
}
