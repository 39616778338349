import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import TextCustomization from '../../customizations/TextCustomization';

export const Heading: FC = () => {
	return (
		<Box sx={{ pt: 2, pb: 2 }}>
			<Typography sx={headerSX}>
				<TextCustomization id="PHONE_NUMBER_INPUT_PAGE_HEADER" />
			</Typography>
			<Typography sx={subheaderSX}>
				<TextCustomization id="PHONE_NUMBER_INPUT_PAGE_SUBHEADER" />
			</Typography>
		</Box>
	);
};

const headerSX = {
	fontSize: 'x-large',
	fontWeight: '500',
	textAlign: 'center',
	mt: 2,
	mb: 2
};

const subheaderSX = {
	fontSize: 'large',
	fontWeight: '500',
	textAlign: 'center',
	mt: 4,
	mb: 2
};
