export type Country = 'AT' | 'DE';
export type CountryCode = '+43' | '+49';

export const COUNTRY_PREFIXES: Record<Country, Array<string>> = {
	AT: [
		'650',
		'660',
		'664',
		'665',
		'667',
		'670',
		'673',
		'675',
		'676',
		'677',
		'678',
		'680',
		'681',
		'683',
		'686',
		'688',
		'689',
		'690',
		'699'
	],
	DE: [
		'160',
		'162',
		'163',
		'170',
		'171',
		'172',
		'173',
		'174',
		'175',
		'176',
		'177',
		'178',
		'179',
		'1511',
		'1512',
		'1514',
		'1515',
		'1516',
		'1517',
		'1520',
		'1521',
		'1522',
		'1523',
		'1525',
		'1526',
		'1529',
		'1570',
		'1573',
		'1575',
		'1577',
		'1578',
		'1579',
		'1590',
		'15566'
	]
};

export const COUNTRY_CODES: Record<Country, CountryCode> = {
	AT: '+43',
	DE: '+49'
};

export const CODE_COUNTRY: Record<CountryCode, Country> = {
	'+43': 'AT',
	'+49': 'DE'
};
