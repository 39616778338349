// TODO: Refactor model of check item fully
// Currently it is causing many problems

import { ItemStatus } from './constants';
import { KONTOCHECK } from '../check/codes';
import { getValueFromType } from '../utils/report-utils';
import { CurrencyFormatter } from '../utils/CurrencyTextFormatter';

export enum EnumResultsPageType {
	RESULTS_SUMMARY,
	RESULTS_VIEW
}

interface ICheckValue {
	accuracy: string;
	value: number;
}

interface ICheckResultArray {
	interval: string;
	mean: ICheckValue;
}

interface ICheckItemNonStandard {
	name?: string;
	number?: string;
	status?: string;
	result?: string;
	numberOfDays?: string;
	optimalPaymentDate?: string;
	ratio?: string;
	numberOfEmployers?: string;
	employmentStatus?: string;
	score?: string;
	risk_level?: string;
}

interface ICheckItemParams {
	employmentStatus?: string;
	min?: string;
	name?: string;
	threshold?: number;
	derived?: boolean;
}

export interface ICheckItem {
	parent: string;
	code: string;
	result: string | number | ICheckResultArray[] | ICheckItemNonStandard;
	newResult: string;
	components: [];
	group: string;
	comment: string;
	error: object;
	params: ICheckItemParams;
}

const getKontoCheckItemStatusInternal = (citem: ICheckItem): ItemStatus => {
	// if is results empty or there is error -> UNVERIFIED
	if (!citem.result || citem.error) return ItemStatus.UNVERIFIED;

	if (typeof citem.result === 'string') {
		if (citem.result === 'verified') return ItemStatus.VERIFIED;
		else return ItemStatus.UNVERIFIED;
	} else if (typeof citem.result === 'object') {
		// DoD is that we don't have string we get object
		// in that case value is verified automatically
		return ItemStatus.VERIFIED;
	}

	if (Object.keys(citem.result).length > 0) return ItemStatus.VERIFIED;
	else return ItemStatus.UNVERIFIED;
};

const SPECIFIC_UNVERIFIED_CODES = ['EMPLOYMENT_STATUS', 'EMPLOYER'];
export const getKontoCheckItemStatus = (citem: ICheckItem): ItemStatus => {
	const status = getKontoCheckItemStatusInternal(citem);
	if (
		status === ItemStatus.UNVERIFIED &&
		SPECIFIC_UNVERIFIED_CODES.includes(citem.code) &&
		citem.params &&
		citem.params.derived === false
	)
		return ItemStatus.USER_CHANGED;
	else return status;
};

export const getInOutFlowItemStatus = (citem: ICheckItem): ItemStatus => {
	if (citem.newResult) return ItemStatus.USER_CHANGED;
	// if its zero it defined as result is false so check if result iz zero
	if (citem.result === 0) return ItemStatus.VERIFIED;
	// if is results empty or there is error -> UNVERIFIED
	if (!citem.result || citem.error) return ItemStatus.UNVERIFIED;
	else return ItemStatus.VERIFIED;
};

const CODES_RESPONSE_REPORTS_PARAMS = {
	EMPLOYMENT_STATUS: 'employmentStatus',
	EMPLOYER: 'employers',
	EMPLOYMENT_NUMBER: 'numberOfEmployers',
	MAXFIN: 'maxDebt',
	OPTIM_DATE: 'optimalPaymentDate',
	MAX_RENT: 'rent',
	DSTI: 'ratio',
	LTVR: 'ratio',
	LIMIT_ADVANCED: 'limit',
	HISTORY: 'numberOfDays',
	BALANCE_AT_OPTIM_DATE: 'avgBalance',
	LIMIT: 'limit',
	EMPLOYER_DURATION: 'employers',
	BALANCEV2: 'balance'
};

export const getCodesResultParams = (citem: ICheckItem, t: Function): string => {
	const paramName = CODES_RESPONSE_REPORTS_PARAMS[citem.code as keyof typeof CODES_RESPONSE_REPORTS_PARAMS];

	if (paramName && typeof citem.result === 'object') {
		// @ts-ignore
		const paramResult = citem.result[paramName];
		return getValueStringFromObjectParams(paramResult, citem.code, t);
	}
	return '';
};

export const getCodesResultValue = (citem: ICheckItem): number => {
	return getValueFromType(citem);
};

const getValueStringFromObjectParams = (
	item: number | object | Array<any> | string,
	code: string,
	t: Function
): string => {
	if (item === null || item === undefined) return '';

	if (Array.isArray(item)) {
		switch (code) {
			case KONTOCHECK.EMPLOYMENT_STATUS:
				const valueArray = item.map((item) => `${t(`criteria-result.${item}`)}`);
				return valueArray.join(', ');
			case KONTOCHECK.EMPLOYER_DURATION:
				const employments = item.map((employ) => {
					const { name, duration, more_then } = employ;
					const suffix = more_then ? '+' : '';
					const months = t('criteria-result.months');
					return `${name}: ${duration}${suffix} ${months}`;
				});

				return (
					<div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
						{employments.map((employment) => (
							<span>{employment}</span>
						))}
					</div>
				) as any;
			default:
				return item.join(', ');
		}
	}

	if (typeof item === 'number') {
		switch (code) {
			case KONTOCHECK.MAXFIN:
				return `EUR ${CurrencyFormatter.formatValueToCurrencyString(item.toFixed(2))}`;
			case KONTOCHECK.OPTIM_DATE:
				return `${item}. ${t('criteria-result.Third of the month')}`;
			case KONTOCHECK.DSTI:
				return `${Number((item * 100).toFixed(2))}%`;
			case KONTOCHECK.LTVR:
				return `${Number((item * 100).toFixed(2))}%`;
			case KONTOCHECK.LIMIT_ADVANCED:
				return `EUR ${CurrencyFormatter.formatValueToCurrencyString(item.toFixed(2))}`;
			case KONTOCHECK.LIMIT:
				return `EUR ${CurrencyFormatter.formatValueToCurrencyString(item.toFixed(2))}`;
			case KONTOCHECK.HISTORY:
				return `${item} ${t('criteria-result.days')}`;
			case KONTOCHECK.BALANCE_AT_OPTIM_DATE:
				return `EUR ${CurrencyFormatter.formatValueToCurrencyString(item.toFixed(2))}`;
			case KONTOCHECK.MAX_RENT:
				return `EUR ${CurrencyFormatter.formatValueToCurrencyString(item.toFixed(2))}`;
			case KONTOCHECK.BALANCEV2:
				return `EUR ${CurrencyFormatter.formatValueToCurrencyString(item.toFixed(2))}`;
			default:
				return item.toString();
		}
	}

	if (code === KONTOCHECK.OPTIM_DATE && item === 'Null') {
		return '';
	}

	return item.toString();
};
