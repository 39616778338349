import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Persona } from '../../types/Persona';
import { RootState } from '../store';

type PersonaToValidateProps = 'firstName' | 'lastName' | 'dateOfBirth';

type ValidationType<T> = {
	[Property in keyof T as `isValid${Capitalize<string & Property>}`]: boolean;
};

type PersonaValidationType = Persona & ValidationType<Pick<Persona, PersonaToValidateProps>>;

type State = PersonaValidationType & { [k: string]: any };

export type PersonaInformation = {
	firstName: string;
	lastName: string;
	country: string;
	dateOfBirth: string;
};

const initialState: State = {
	...new Persona(),
	isValidFirstName: false,
	isValidLastName: false,
	isValidDateOfBirth: false,
	isFirstNamePreFilled: false,
	isLastNamePreFilled: false,
	isCountryPreFilled: false,
	isDOBPreFilled: false
};

const personaSlice = createSlice({
	name: 'persona',
	initialState,
	reducers: {
		updatePersonaInformation(state, action: PayloadAction<PersonaInformation>) {
			const { firstName, lastName, country, dateOfBirth } = action.payload;
			state.firstName = firstName;
			state.lastName = lastName;
			state.country = country;
			state.residence.country = country;
			state.dateOfBirth = dateOfBirth;
		},
		setPersonaInfo(state, action) {
			return {
				...state,
				...action.payload,
				isValidLastName: !!action.payload?.lastName,
				isValidDateOfBirth: !!action.payload?.dateOfBirth,
				isValidFirstName: !!action.payload?.firstName,
				isFirstNamePreFilled: !!action.payload?.firstName,
				isLastNamePreFilled: !!action.payload?.lastName,
				isCountryPreFilled: !!action.payload?.residence?.country,
				isDOBPreFilled: !!action.payload?.dateOfBirth
			};
		},
		setPhoneNumber(state, action: PayloadAction<string>) {
			state.contacts.phoneNumber = action.payload;
		},
		setPersonaFirstName(state, action) {
			state.firstName = action.payload;
		},
		setIsValidPersonaFirstName(state, action) {
			state.isValidFirstName = action.payload;
		},
		setPersonaLastName(state, action) {
			state.lastName = action.payload;
		},
		setIsValidPersonaLastName(state, action) {
			state.isValidLastName = action.payload;
		},
		setPersonaCountry(state, action) {
			state.residence.country = action.payload;
		},
		setPersonaDateOfBirth(state, action) {
			state.dateOfBirth = action.payload;
		},
		setIsValidPersonaDateOfBirth(state, action) {
			state.isValidDateOfBirth = action.payload;
		},
		setPersonaEmail(state, action) {
			state.contacts.email = action.payload;
		},
		setPersonaCollectingDataConsent(state, action) {
			state.consentCollectingData = action.payload;
		},
		setPersonaForwardingDataConsent(state, action) {
			state.consentForwardingData = action.payload;
		},
		setPersonaEmployerStatus(state, action) {
			state.employerStatus = action.payload;
		},
		setPersonaCurrentEmployer(state, action) {
			state.currentEmployer = action.payload;
		},
		setCanEditData(state, action) {
			state.canEditData = action.payload;
		},
		setUserAssessmentArray(state, action) {
			if (!state.userAssessment?.user) {
				state.userAssessment = {
					user: []
				};
			}
			let item = action.payload;
			let index = state.userAssessment.user.findIndex((it) => it.code === item.code);
			if (index === -1) {
				state.userAssessment.user.push(item);
			} else {
				state.userAssessment.user[index] = item;
			}
		},
		setUserAssessment(state, action) {
			state.userAssessment = action.payload;
		}
	}
});

export const personaActions = personaSlice.actions;

export const { setPersonaInfo, setPhoneNumber, updatePersonaInformation } = personaActions;

// selectors
export const selectPersona = (state: RootState) => state.persona;
export const selectPersonaEmail = (state: RootState) => state.persona.contacts.email;
export const selectPersonaPhoneNumber = (state: RootState): string | null => state.persona.contacts.phoneNumber;
export const selectConsentCollectingData = (state: RootState) => state.persona.consentCollectingData;
export const selectConsetForwardingData = (state: RootState) => state.persona.consentForwardingData;

export const selectPersonaInformation = (state: RootState): PersonaInformation => {
	return {
		firstName: state.persona.firstName,
		lastName: state.persona.lastName,
		dateOfBirth: state.persona.dateOfBirth,
		country: state.persona.country
	};
};

export default personaSlice;
